import { type Certificate } from '@astral-private/crypto';
import { getFullName } from '@utils/string/getFullname';
import { DateService } from '@services/DateService';
import { type DateString, type EmployeeInfoForVerifications } from '@types';
import { checkWithBoolean } from '@tsUtils';

type BlockTime = {
  /**
   * дата начала блокировки
   */
  start: Date;
  /**
   * дата окончания блокировки
   */
  end?: Date;
};

const DEPRECATED_ISSUERS: Record<string, BlockTime[]> = {
  '4029017981': [
    {
      start: new Date('2024-07-24T00:00:00.000Z'),
      end: new Date('2024-10-15T00:00:00.000Z'),
    },
  ],
};

const checkIsInDeprecated = (
  inn?: string | null,
  notBefore?: DateString | Date | null,
) => {
  if (!notBefore || !inn || !DEPRECATED_ISSUERS[inn]) {
    return false;
  }

  const notBeforeDate = new Date(notBefore);

  //т.к. случай через чур уникальный, оставляем единственную проверку на то, что дата выпуска сертификата меньше чем дата окончания блокировки
  //в случае появления схожих кейсов потребуется усложнять проверки
  return DEPRECATED_ISSUERS[inn].some(
    ({ end }) => checkWithBoolean(end) && notBeforeDate < end,
  );
};

export const checkIsCertificateDisabled = (
  initialCert: Certificate,
  initialInfoForVerification: EmployeeInfoForVerifications | undefined,
) => {
  // приводим фио к нижнему регистру, чтобы сделать сравнение нечувствительным к регистру
  const certificate = {
    ...initialCert,
    commonName: initialCert.subject.commonName?.toLowerCase(),
    surname: initialCert.subject.surname?.toLowerCase(),
    name: initialCert.subject.name?.toLowerCase(),
  };

  const infoForVerification = {
    ...initialInfoForVerification,
    FirstName: initialInfoForVerification?.FirstName?.toLowerCase(),
    MiddleName: initialInfoForVerification?.MiddleName?.toLowerCase(),
    LastName: initialInfoForVerification?.LastName?.toLowerCase(),
    Tenant: {
      ...initialInfoForVerification?.Tenant,
      FirstName: initialInfoForVerification?.Tenant?.FirstName?.toLowerCase(),
      MiddleName: initialInfoForVerification?.Tenant?.MiddleName?.toLowerCase(),
      LastName: initialInfoForVerification?.Tenant?.LastName?.toLowerCase(),
    },
  };

  const isCertNotActive =
    checkIsInDeprecated(certificate.issuer?.inn, certificate.notBefore) ||
    (certificate.notAfter &&
      Boolean(
        DateService.differenceInDays(
          new Date(certificate.notAfter),
          Date.now(),
        ) < 0,
      ));

  if (isCertNotActive) {
    return true;
  }

  const regExp = /[^\d]/g;
  const formattedSnils = infoForVerification?.Snils?.replace(regExp, '');

  // если есть ИННЛЕ, то ЮЛ
  if (certificate.subject.innLe) {
    // ЮЛ
    // если не совпадают ИНН и ОГРН сертификата и тената то блочим серт
    return (
      certificate.subject.innLe !== infoForVerification?.Tenant?.InnLe ||
      certificate.subject.ogrn !== infoForVerification?.Tenant.Ogrn
    );
  }

  // Если есть ОГРНИП то ИП
  if (certificate.subject.ogrnip) {
    // ИП
    if (
      !infoForVerification?.Tenant?.FirstName ||
      !infoForVerification?.Tenant.LastName
    ) {
      return true;
    }

    // сверяем ФИО, СНИЛСЫ, ОГРНИПы и ИННы, при несовпадении блочим серт
    return (
      certificate.commonName !==
        getFullName(
          infoForVerification?.Tenant.FirstName,
          infoForVerification?.Tenant?.MiddleName,
          infoForVerification?.Tenant.LastName,
        ) ||
      certificate.surname !== infoForVerification?.LastName ||
      certificate.name !==
        `${infoForVerification?.FirstName} ${infoForVerification?.MiddleName}` ||
      certificate.subject.snils !== formattedSnils ||
      certificate.subject.inn !== infoForVerification?.Tenant.Inn ||
      certificate.subject.inn !== infoForVerification?.Inn ||
      certificate.subject.ogrnip !== infoForVerification?.Tenant.OgrnIp
    );
  }

  // если инн 12ти символьный и нет ОГРНИПа то ФЛ
  if (certificate.subject.inn?.length === 12 && !certificate.subject.ogrnip) {
    // ФЛ
    // Сверяем ФИО, СНИЛСы и ИННы
    return (
      certificate.subject.snils !== formattedSnils ||
      certificate.subject.inn !== infoForVerification?.Inn ||
      certificate.commonName !==
        getFullName(
          infoForVerification?.FirstName,
          infoForVerification?.MiddleName,
          infoForVerification?.LastName,
        )
    );
  }

  return true;
};
