import { styled } from '../../../styled';

export const DoubleSideContainerWrapper = styled.div`
  overflow: auto;
  max-height: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;

  ${({ theme }) => theme.breakpoints.up('md')} {
    grid-template-rows: initial;
    overflow: hidden;
    grid-template-columns: 1fr auto;
  }
`;
