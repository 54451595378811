import { Tooltip, type TooltipProps } from '../Tooltip';

export type TooltipSwitcherProps = TooltipProps & {
  isActive: boolean;
  children: JSX.Element;
};

/**
 * компонент переключателя тултипа,
 * если тултип нужен только при определенных обстоятельствах
 */
export const TooltipSwitcher = ({
  isActive,
  children,
  ...props
}: TooltipSwitcherProps) => {
  /**
   * проверка чилдрена на disabled,
   * т.к. disabled кнопка может иметь pointer-events: none,
   * следовательно, эвент ховера возникать не будет и тултип не сможет отрабатывать как требуется,
   * и даже будет кидать варнинги о том что его так нельзя использовать.
   */
  const isChildrenDisabled =
    children.props.disabled || children.props.isDisabled;

  return isActive ? (
    <Tooltip {...props}>
      {isChildrenDisabled ? <div>{children}</div> : children}
    </Tooltip>
  ) : (
    children
  );
};
