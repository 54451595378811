import { styled } from '../styled';
import { Dialog } from '../Dialog';
import { Form } from '../Form';

export const FormWrapper = styled(Form)`
  overflow: auto;
`;

export const DialogWrapper = styled(Dialog, {
  shouldForwardProp: (prop) => prop !== '$hasForm',
})<{ $hasForm?: boolean }>`
  .MuiPaper-root {
    overflow-y: ${({ $hasForm }) => ($hasForm ? 'auto' : 'visible')};
  }

  .MuiDialogTitle-root {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
