import { FormTextField, type FormTextFieldProps } from '@astral/ui';
import { classnames } from '@utils/string/classnames';
import { METRIKA_CLASSES } from '@constants/metrikaClasses';

type FormInputProps<TFieldValues extends object> =
  FormTextFieldProps<TFieldValues> & {
    hidePersonalData?: boolean;
  };

export const FormInput = <TFieldValues extends object>({
  hidePersonalData,
  ...props
}: FormInputProps<TFieldValues>) => (
  <FormTextField
    {...props}
    inputProps={{
      ...props.inputProps,
      className: classnames(props.inputProps?.className, {
        [METRIKA_CLASSES.hideInputValue]: hidePersonalData,
      }),
    }}
  />
);
