import { lodashDebounce } from '../lodash/debounce';

export const throttle = <TFunc = (...args: unknown[]) => unknown>(
  func: TFunc,
  wait: number,
) => {
  return lodashDebounce(func as (...args: unknown[]) => unknown, {
    waitMs: wait,
    timing: 'trailing',
  }).call as TFunc;
};
