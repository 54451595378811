import { type RefObject } from 'react';

import { useThrottledValue } from '../useThrottledValue';
import { useResizeObserver } from '../useResizeObserver';

type UseSizeWatcherOptions = {
  /**
   * колбэк получения нужных размеров
   * @example () => window.innerHeight
   */
  sizeCb: () => number;
  /**
   * реф на ноду за которой нужно следить
   */
  ref: RefObject<HTMLElement>;
};

/**
 * хук для слежения за размерами выбранного рефа
 */
export const useSizeWatcher = ({ ref, sizeCb }: UseSizeWatcherOptions) => {
  const [size, handleResize] = useThrottledValue({ sizeCb });

  useResizeObserver({ sizeCb: handleResize, ref });

  return size;
};
