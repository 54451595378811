import { LoaderService } from '../LoaderService';

/**
 * сервис для загрузки сторонних скриптов, например телеграм, ютуб, и т.д.
 */
export class ScriptLoader<T> extends LoaderService<T> {
  // адрес для загрузки скрипта
  private src: string;

  // метод проверки, по которой определяем что скрипт успешно загрузился
  private flag: () => T;

  constructor(src: string, flag: () => T) {
    super();
    this.src = src;
    this.flag = flag;
  }

  createPromise = () =>
    // создаем новый промис
    new Promise<T>((resolve) => {
      // создаем тег скрипта
      const script = document.createElement('script');

      // выставляем необходимые атрибуты
      script.async = true;
      script.defer = true;
      script.src = this.src;

      // метод колбэка загрузки
      const onLoad = () => {
        // т.к. послу загрузки скрипта, этому скрипту надо еще немного времени на инициализацию
        // запускаем таймер
        const timer = window.setInterval(() => {
          // в условии запускаем проверку, и если проверка успешна
          if (this.flag()) {
            // останавливаем таймер
            window.clearInterval(timer);
            // резолвим промис с результатом
            resolve(this.flag());
            // удаляем листенер
            script.removeEventListener('load', onLoad);
          }
        }, 10);
      };

      // добавляем листенер на загрузку скрипта
      script.addEventListener('load', onLoad);
      // аппендим тег в боди страницы, чтобы запустить загрузку
      document.body.appendChild(script);
    });
}
