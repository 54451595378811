import { createPhoneMask } from '../utils/string/createPhoneMask';

type Contact = {
  link: string;
  label: string;
  value: string;
};

type ContactTypes = 'phone' | 'email' | 'telegram';

const PHONE_NUMBER = '88006008155';
const EMAIL = 'help@ikedo.ru';
const TELEGRAM = 'help_ikedo_bot';

export const techSupportContacts: Record<ContactTypes, Contact> = {
  phone: {
    value: PHONE_NUMBER,
    label: createPhoneMask(PHONE_NUMBER),
    link: `tel:${PHONE_NUMBER}`,
  },
  email: {
    link: `mailto:${EMAIL}`,
    label: EMAIL,
    value: EMAIL,
  },
  telegram: {
    link: `https://t.me/${TELEGRAM}`,
    label: `@${TELEGRAM}`,
    value: TELEGRAM,
  },
};
