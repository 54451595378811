import { Box } from '../../Box';
import { AlertModalBetweenTemplate } from '../AlertModalBetweenTemplate';
import { AlertModalGroupTemplate } from '../AlertModalGroupTemplate';

import { type AlertModalFooterProps } from './types';

export const AlertModalDialogFooter = (props: AlertModalFooterProps) => {
  const { footerTemplate, ...templateProps } = props;

  switch (footerTemplate) {
    case 'group':
      return <AlertModalGroupTemplate {...templateProps} />;
    case 'between':
      return <AlertModalBetweenTemplate {...templateProps} />;
    default:
      // если не нужен footer - делаем отступ
      return <Box paddingBottom={4} data-test="AlertEmptyTemplatePadding" />;
  }
};
