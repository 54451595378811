import { observer } from 'mobx-react-lite';

import { AlertModal } from '../AlertModal';
import { Grid } from '../Grid';
import { Typography } from '../Typography';
import { Button } from '../Button';
import { FormTextarea } from '../Form';

import { type UseLogicParams, useLogic } from './useLogic';

type ConfirmWithCommentModalProps = {
  commentLabel: string;
  commentPlaceholder: string;
} & UseLogicParams;

export const ConfirmWithCommentModal = observer(
  (props: ConfirmWithCommentModalProps) => {
    const {
      handleTransitionEnd,
      handleSubmit,
      inputRef,
      handleCancelClick,
      cancelText,
      description,
      title,
      submitText,
      isActive,
      control,
      submitButtonColor,
    } = useLogic(props);

    return (
      <AlertModal
        title={title}
        open={isActive}
        onClose={handleCancelClick}
        onTransitionEnd={handleTransitionEnd}
      >
        <Grid spacing={6} component="form" onSubmit={handleSubmit}>
          <Typography>{description}</Typography>
          <FormTextarea
            control={control}
            name="comment"
            inputProps={{
              ref: inputRef,
            }}
            label={props.commentLabel}
            placeholder={props.commentPlaceholder}
          />
          <Grid
            columns="auto auto"
            justifyItems="flex-end"
            justifyContent="flex-end"
            spacing={2}
          >
            <Button onClick={handleCancelClick} variant="text">
              {cancelText}
            </Button>
            <Button type="submit" color={submitButtonColor}>
              {submitText}
            </Button>
          </Grid>
        </Grid>
      </AlertModal>
    );
  },
);
