import { styled } from '../../styled';

export const StickyButtonWrapper = styled('tr', {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<{
  isActive: boolean;
}>`
  position: absolute;
  right: ${({ theme }) => theme.spacing(8)};
  bottom: ${({ theme }) => theme.spacing(8)};
  transition:
    opacity 0.3s linear,
    visibility 0.3s linear;
  opacity: ${({ isActive }) => (isActive ? '1' : '0')};
  visibility: ${({ isActive }) => (isActive ? 'visible' : 'hidden')};
`;
