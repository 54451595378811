import { styled } from '../styled';

export const ActionsStackWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'gap' && prop !== 'padding',
})<{ gap?: number; padding?: number[] }>`
  display: grid;
  grid-auto-flow: column;
  grid-gap: ${({ theme, gap = 1 }) => theme.spacing(gap)};
  align-items: center;
  justify-content: flex-start;

  padding: ${({ theme, padding }) =>
    padding ? theme.spacing(padding[0], padding[1], padding[2]) : ''};
`;
