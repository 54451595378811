import { styled } from '../styled';

export const DashedSeparator = styled.div`
  flex: 1;

  height: 4px;
  margin-right: ${({ theme }) => theme.spacing(1)};

  border-bottom: 1px dashed ${({ theme }) => theme.palette.grey[400]};
`;
