import { COMMENT_MAX_LENGTH, FORM_ERROR_MESSAGE } from '@constants';
import { useDebounce } from 'react-use';
import { type ChangeEvent, useEffect, useState } from 'react';

type UseLogicParams = {
  placeholder?: string;
  value?: string;
  onSetValue?: (value: string) => void;
};

export const useLogic = ({ onSetValue, value = '' }: UseLogicParams) => {
  const [innerValue, setInnerValue] = useState(value);
  const [isError, setIsError] = useState(false);

  const errorText = FORM_ERROR_MESSAGE.MAX_LENGTH(COMMENT_MAX_LENGTH);
  const searchRule = (newValue?: string) => {
    return newValue ? newValue.length > COMMENT_MAX_LENGTH : false;
  };

  const handlerSearch = (e: ChangeEvent<HTMLInputElement>) => {
    if (onSetValue) {
      setIsError(searchRule(e.target.value));
      setInnerValue(e.target.value);
    }
  };
  const helperText = isError && errorText;

  useDebounce(
    () => {
      if (!isError) {
        onSetValue?.(innerValue);
      }
    },
    250,
    [innerValue],
  );

  useEffect(() => {
    setInnerValue((current) => {
      if (current !== value) {
        return value;
      }

      return current;
    });
  }, [value]);

  return {
    handlerSearch,
    helperText,
    isError,
    innerValue,
  };
};
