import { styled } from '../../../styled';

export const FormWrapperElement = styled('div', {
  shouldForwardProp: (prop) => prop !== 'width',
})<{ width: string }>`
  overflow-y: auto;

  width: ${({ width }) => width};
  height: 100%;
  padding-right: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(5)};
`;
