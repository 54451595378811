/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IncreaseNumberOfLicensesDto } from '../models/IncreaseNumberOfLicensesDto';
import type { LicenseInfoDto } from '../models/LicenseInfoDto';
import type { SetLicenseInfoDto } from '../models/SetLicenseInfoDto';
import type { UpdateTenantLicencesEndedDto } from '../models/UpdateTenantLicencesEndedDto';

import type { CancelablePromise } from '@common/data/api-core/CancelablePromise';
import type { BaseHttpRequest } from '@common/data/api-core/BaseHttpRequest';

export class TenantLicensesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns any
   * @throws ApiError
   */
  public tenantLicensesIncreaseTenantNumberOfLicenses({
    tenantId,
    requestBody,
  }: {
    tenantId: string;
    requestBody: IncreaseNumberOfLicensesDto;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v2/tenantmgmt/TenantLicenses/{tenantId}/NumberOfLicenses',
      path: {
        tenantId: tenantId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public tenantLicensesUpdateLicencesEnded({
    tenantId,
    requestBody,
  }: {
    tenantId: string;
    requestBody: UpdateTenantLicencesEndedDto;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v2/tenantmgmt/TenantLicenses/{tenantId}/LicencesEnded',
      path: {
        tenantId: tenantId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public tenantLicensesSetLicenseInfo({
    tenantId,
    requestBody,
  }: {
    tenantId: string;
    requestBody: SetLicenseInfoDto;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/tenantmgmt/TenantLicenses/{tenantId}/LicenceInfo',
      path: {
        tenantId: tenantId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns LicenseInfoDto
   * @throws ApiError
   */
  public tenantLicensesGetLicences({
    tenantId,
  }: {
    tenantId: string;
  }): CancelablePromise<LicenseInfoDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/tenantmgmt/TenantLicenses/{tenantId}/LicenceInfo',
      path: {
        tenantId: tenantId,
      },
    });
  }
}
