import { Spinner, type SpinnerSizes, type SpinnerType } from '../Spinner';
import { Typography } from '../Typography';

import { ContentLoaderWrapper } from './styles';

type ContentLoaderProps = {
  /**
   * размер спиннера
   * @default xm
   */
  size?: SpinnerSizes;
  /**
   * тип спиннера
   * @default pale
   */
  type?: SpinnerType;
  title?: string;
};

export const ContentLoader = ({
  size = 'xm',
  title,
  type = 'pale',
}: ContentLoaderProps) => (
  <ContentLoaderWrapper>
    <Spinner size={size} type={type} />
    {title && (
      <Typography variant="h4" color="textSecondary">
        {title}
      </Typography>
    )}
  </ContentLoaderWrapper>
);
