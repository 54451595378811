import { MINUTES_PER_HOUR } from '../../constants/minutesPerHour';
import { type DateType } from '../types';

/**
 * добавление к дате смещения по часовому поясу пользователя
 */
export const resetHoursToTimezoneOffset = (date: DateType) => {
  const localDate = new Date(date);

  return new Date(
    localDate.setUTCHours(
      localDate.getUTCHours() -
        localDate.getTimezoneOffset() / MINUTES_PER_HOUR,
    ),
  );
};
