import { forwardRef } from 'react';
import { classnames } from '@utils/string/classnames/classnames';

import { type TypographyProps } from '../Typography';
import { METRIKA_CLASSES } from '../../constants/metrikaClasses';

import { TypographyWrapper } from './styles';

export const Typography = forwardRef<HTMLSpanElement, TypographyProps>(
  ({ hidePersonalData, ...props }, ref) => (
    <TypographyWrapper
      {...props}
      ref={ref}
      className={classnames(props.className, {
        [METRIKA_CLASSES.blurContent]: hidePersonalData,
      })}
    />
  ),
);
