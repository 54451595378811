import { Suspense } from 'react';

import {
  ContentLoader,
  DashboardLayout,
  type WithJSXChildren,
} from '@dashboard/shared';

export const AuthLayout = ({ children }: WithJSXChildren) => (
  <DashboardLayout>
    <DashboardLayout.Main>
      <Suspense fallback={<ContentLoader />}>{children}</Suspense>
    </DashboardLayout.Main>
  </DashboardLayout>
);
