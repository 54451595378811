import { type ApiError, HTTP_ERRORS } from '@services/http/ApiError';
import { notify } from '@components/Notification';

const errorStatusExceptionList = [
  HTTP_ERRORS.UNAUTHORIZED_HTTP_CODE,
  HTTP_ERRORS.UNAVAILABLE_LEGAL_REASONS,
];

export const errorHandler = <T extends ApiError | Error | unknown>(
  error: T,
) => {
  if (errorStatusExceptionList.includes((error as ApiError).status) || !error) {
    return;
  }

  notify.error((error as unknown as Error).message);
};
