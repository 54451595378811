import { styled } from '../styled';

export const TechSupportBlockWrapper = styled.div`
  ${({ theme }) => theme.breakpoints.down('md')} {
    display: none;
  }
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing(4)};
  grid-template-columns: 72px 1fr;
  align-items: center;

  padding: ${({ theme }) => theme.spacing(3)};

  background-color: ${({ theme }) => theme.palette.background.element};
  border-radius: ${({ theme }) => theme.shape.small};

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: ${({ theme }) => theme.spacing(6)};
  }
`;

export const TechSupportBlockSide = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing(1)};
`;

export const TechSupportLinkWrapper = styled.a`
  color: inherit;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;
