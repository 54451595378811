import Cookies from 'js-cookie';

import { type JwtTokens } from '@dashboard/shared';

import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from './constants';

const setCookie = (key: string, token: string, expiresIn: number) => {
  Cookies.set(key, token, {
    sameSite: 'Lax',
    expires: new Date(+new Date() + expiresIn),
  });
};

export type UpdateTokensOptions = JwtTokens & {
  expires?: number;
};

const ONE_HOUR = 60 * 60 * 1000;
const ONE_MINUTE = 60 * 1000;

export const updateTokens = ({
  AccessToken,
  RefreshToken,
  expires = ONE_MINUTE,
}: UpdateTokensOptions): void => {
  setCookie(ACCESS_TOKEN_KEY, AccessToken, expires);
  setCookie(REFRESH_TOKEN_KEY, RefreshToken, ONE_HOUR);
};
