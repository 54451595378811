import axios, {
  type AxiosError,
  type AxiosInstance,
  type AxiosRequestConfig,
  type AxiosResponse,
} from 'axios';

export type InterceptorsConfigHandler = (
  config: AxiosRequestConfig,
) => AxiosRequestConfig | Promise<AxiosRequestConfig>;

export type InterceptorsResponseHandler = (
  config: AxiosResponse,
) => (AxiosResponse | Promise<AxiosResponse>) | undefined;

export type InterceptorsErrorHandler = (
  error: AxiosError,
) => Promise<AxiosError | undefined | AxiosResponse>;

type EjectInterceptorsOptions = {
  axiosInterceptor: number;
  instanceInterceptor: number;
};

export class HttpInterceptor {
  constructor(private readonly instance: AxiosInstance) {}

  ejectRequestInterceptors({
    axiosInterceptor,
    instanceInterceptor,
  }: EjectInterceptorsOptions) {
    axios.interceptors.request.eject(axiosInterceptor);
    this.instance.interceptors.request.eject(instanceInterceptor);
  }

  ejectResponseInterceptors({
    axiosInterceptor,
    instanceInterceptor,
  }: EjectInterceptorsOptions) {
    axios.interceptors.response.eject(axiosInterceptor);
    this.instance.interceptors.response.eject(instanceInterceptor);
  }

  onRequest(
    configHandler: InterceptorsConfigHandler,
    errorHandler: InterceptorsErrorHandler,
  ) {
    const axiosInterceptor = axios.interceptors.request.use(
      //@ts-ignore TODO: KADRY-3248
      configHandler,
      errorHandler,
    );
    const instanceInterceptor = this.instance.interceptors.request.use(
      //@ts-ignore TODO: KADRY-3248
      configHandler,
      errorHandler,
    );

    return { axiosInterceptor, instanceInterceptor };
  }

  onResponse(
    responseHandler: InterceptorsResponseHandler,
    errorHandler: InterceptorsErrorHandler,
  ) {
    const axiosInterceptor = axios.interceptors.response.use(
      //@ts-ignore TODO: KADRY-3248
      responseHandler,
      errorHandler,
    );
    const instanceInterceptor = this.instance.interceptors.response.use(
      //@ts-ignore TODO: KADRY-3248
      responseHandler,
      errorHandler,
    );

    return { axiosInterceptor, instanceInterceptor };
  }
}
