import { type ChangeEvent } from 'react';
import { makeAutoObservable, toJS } from 'mobx';
import { type Metrika } from '@services';

const DEFAULT_MIN_SIZE = 10;

/**
 * массив размеров колонок
 */
export type TableResizerCols = number[];

type TableResizerSlidersParams = {
  /**
   * колбэк на завершение изменения ширины,
   * нужен для уменьшения колличества рендеров
   */
  onUpdate: (cols: TableResizerCols) => void;
  cols: TableResizerCols;
  /**
   * минимальный размер колонки
   */
  minSize: number;
  /**
   * флаг, говорящий о том, что последнияя колонка action, и для нее не требуется ресайз
   */
  isLastTagAction: boolean;
};

/**
 * стор для работы со слайдерами,
 * позволяет уменьшить количество рендеров реакта
 */
export class TableResizerSliders {
  private cols: TableResizerCols;

  private minSize: number;

  private onUpdate: (cols: TableResizerCols) => void;

  /**
   * флаг, говорящий о том, что последнияя колонка action, и для нее не требуется ресайз
   */
  private isLastTagAction: boolean;

  constructor({
    cols,
    onUpdate,
    minSize,
    isLastTagAction,
  }: TableResizerSlidersParams) {
    this.onUpdate = onUpdate;
    this.minSize = minSize;
    this.cols = cols;
    this.isLastTagAction = isLastTagAction;
    makeAutoObservable(this);
  }

  /**
   * сущность, где каждый элемент - последовательное суммирование предыдущих из входного массива,
   * требуется для корректного позиционирования слайдеров
   */
  public get sliders() {
    let sum = 0;
    const lastIndex = this.cols.length - (this.isLastTagAction ? 2 : 1);

    return this.cols.slice(0, lastIndex).map((item) => {
      sum += item;

      return sum;
    });
  }

  /**
   * обработчик эвента передвижения слайдера
   */
  public resizeHandler = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    // конвертируем строковое значение в число
    const value = parseFloat(e?.currentTarget?.value);

    // проверяем что данные валидны
    if (index + 1 === this.cols.length || isNaN(value)) {
      return;
    }

    const currentLeft = this.sliders[index];
    const currentRight = this.cols[index + 1];

    // вычисляем новые значения
    const difference = value - currentLeft;
    const newLeft = this.cols[index] + difference;
    const newRight = currentRight - difference;

    // проверяем вычесленные значения, на то что они не выходит за рамки минимально возможного
    if (newLeft < this.minSize || newRight < this.minSize) {
      return;
    }

    // заменяем размеры, чтобы позиция слайдера изменилась
    this.cols[index] = newLeft;
    this.cols[index + 1] = newRight;
  };

  /**
   * обработчик реакции на mouseUp
   */
  public mouseUpHandler = () => {
    // округляем значения, чтобы починить js багу с числами
    const data = toJS(this.cols).map((value) => Math.floor(value * 10) / 10);

    // эмитим апдейт
    this.onUpdate(data);
    // заменяем локальные значения, чтобы синхронизировать с округленными
    this.cols = data;
  };
}

type TableResizerStoreParams = {
  /**
   * массив размеров колонок, используемый по умолчанию
   */
  cols: TableResizerCols;
  /**
   * минимальный размер колонки
   */
  minSize?: number;
  /**
   * ключ, по которому проверяется ранее измененные значения из localStorage
   */
  key: string;
  /**
   * флаг, говорящий о том, что последнияя колонка action, и для нее не требуется ресайз
   */
  isLastTagAction: boolean;
  metrika: Metrika | null;
};

/**
 * стор для изменения размеров колонок таблицы
 */
export class TableResizerStore {
  /**
   * реактивный массив колонок, по которому строятся стили в таблице
   */
  public cols: TableResizerCols;

  /**
   * ключ, по которому проверяется ранее измененные значения из localStorage
   */
  private key: string;

  /**
   * стор для слайдеров
   */
  public sliders: TableResizerSliders;

  private metrika: Metrika | null = null;

  constructor({
    cols,
    minSize = DEFAULT_MIN_SIZE,
    key,
    isLastTagAction,
    metrika,
  }: TableResizerStoreParams) {
    this.metrika = metrika;
    this.key = key;

    const value = this.getSavedCols(cols);

    this.cols = value;

    this.sliders = new TableResizerSliders({
      cols: value,
      onUpdate: this.onUpdate,
      minSize,
      isLastTagAction,
    });

    makeAutoObservable(this);
  }

  /**
   * ключ для localStorage с префиксом
   */
  private get prefixedKey() {
    return `tableWidths_${this.key}`;
  }

  /**
   * метод для получения актуальных размеров
   */
  private getSavedCols = (defaultCols: TableResizerCols): TableResizerCols => {
    const stringData = localStorage.getItem(this.prefixedKey);

    if (!stringData) {
      return defaultCols;
    }

    const isHaveDiffBetweenColumnsCount =
      stringData.length !== defaultCols.length;

    if (isHaveDiffBetweenColumnsCount) {
      return defaultCols;
    }

    return JSON.parse(stringData) as unknown as TableResizerCols;
  };

  /**
   * метод для обновления размеров колонок
   */
  private onUpdate = (cols: TableResizerCols) => {
    this.metrika?.track('TableColumnResize');
    localStorage.setItem(this.prefixedKey, JSON.stringify(cols));
    this.cols = cols;
  };
}

export const createTableResizerStore = (params: TableResizerStoreParams) =>
  new TableResizerStore(params);
