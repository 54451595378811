import { Grid as AstralGrid, styled } from '@astral/ui';

export type GridWrapperProps = {
  height?: string;
  alignItems?: string;
  alignContent?: string;
  isFlex?: boolean;
  justifyContent?: string;
  justifyItems?: string;
  columns?: number | string;
  rows?: number | string;
  isSingleSmDown?: boolean;
};

export const GridWrapper = styled(AstralGrid, {
  shouldForwardProp: (prop) =>
    // TODO: убрать проверку на rows и columns когда в ките появится поддержка строк https://track.astral.ru/soft/browse/UIKIT-837
    !prop.match(
      /height|alignItems|isFlex|justifyContent|rows|columns|isSingleSmDown|alignContent|justifyItems/,
    ),
})<GridWrapperProps>`
  display: ${({ isFlex, columns, isSingleSmDown, rows }) =>
    (isFlex && 'flex') || ((columns || isSingleSmDown || rows) && 'grid')};
  grid-template-columns: ${({ columns }) =>
    (typeof columns === 'string' && columns) ||
    (columns && `repeat(${columns}, 1fr)`)};
  grid-template-rows: ${({ rows }) =>
    (typeof rows === 'string' && rows) || (rows && `repeat(${rows}, 1fr)`)};
  align-items: ${({ alignItems }) => alignItems};
  align-content: ${({ alignContent }) => alignContent};
  justify-content: ${({ justifyContent }) => justifyContent};
  justify-items: ${({ justifyItems }) => justifyItems};

  height: ${({ height }) => height};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid-template-columns: ${({ isSingleSmDown }) =>
      isSingleSmDown ? '1fr' : ''};
  }
`;
