import { type Metrika } from '@services';
import { createContext } from 'react';
import { type WithJSXChildren } from '@types';

export const MetrikaContext = createContext<{
  metrika: Metrika | null;
}>({ metrika: null });

type MetrikaContextProviderProps = WithJSXChildren & {
  metrika: Metrika;
};

export const MetrikaContextProvider = ({
  metrika,
  children,
}: MetrikaContextProviderProps) => (
  <MetrikaContext.Provider value={{ metrika }}>
    {children}
  </MetrikaContext.Provider>
);
