import { makeAutoObservable } from 'mobx';

type NavigateHandlerParams = {
  search?: string;
  replace?: boolean;
};
type NavigateHandler = (
  path: string | number,
  params?: NavigateHandlerParams,
) => void;

type RouterParams = {
  navigate: NavigateHandler;
};

type SetSearchParams = (
  params: URLSearchParams,
  options?: { replace?: boolean },
) => void;

/**
 * адаптер для роутера, позволяет отвязать логику приложения от реакт флоу,
 * и использовать его в бизнес логике
 */
export class RouterService {
  private navigateHandler?: NavigateHandler;

  private currentPathname: string;

  public searchParams = new URLSearchParams();

  public setSearchParams: SetSearchParams = () => {};

  constructor() {
    this.currentPathname = globalThis.location?.pathname ?? '';
    makeAutoObservable(this);
  }

  public get pathname() {
    return this.currentPathname;
  }

  public updatePathname = (pathname: string) => {
    this.currentPathname = pathname;
  };

  public navigate: NavigateHandler = (path, params) => {
    this.navigateHandler?.(path, params);
  };

  public setParams = (params: RouterParams) => {
    this.navigateHandler = params.navigate;
  };

  public updateSearchParams(
    params: URLSearchParams,
    setParams: SetSearchParams,
  ) {
    this.searchParams = params;
    this.setSearchParams = setParams;
  }

  public removeSearchParam = (param: string) => {
    this.searchParams.delete(param);
    this.setSearchParams(this.searchParams);
  };
}

export const routerService = new RouterService();
