import { styled } from '@common/shared/components';

export const Wrapper = styled.div`
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;

  width: 100vw;
  height: var(--viewport-height, 100vh);
  max-height: var(--viewport-height, 100vh);

  background-color: ${({ theme }) => theme.palette.common.white};
`;
