import { makeAutoObservable } from 'mobx';

export type ToggleStoreParams = {
  /**
   * флаг указывающий на изначальное состояния стора
   */
  isInitialActive?: boolean;
  /**
   * коллбэк вызываемый при включении
   */
  onTurnOn?: () => void;
  /**
   * коллбэк вызываемый при выключении
   */
  onTurnOff?: () => void;
};

/**
 * стор для работы с переключаемым значением,
 * хранит стейт активности, и предоставляет методы для переключения
 */
export class ToggleStore {
  public isActive: boolean;

  constructor(private readonly params?: ToggleStoreParams) {
    this.isActive = params?.isInitialActive ?? false;
    makeAutoObservable(this);
  }

  /**
   * метод включения актвиности
   */
  public turnOn = () => {
    this.isActive = true;
    this.params?.onTurnOn?.();
  };

  /**
   * метод выключения активности
   */
  public turnOff = () => {
    this.isActive = false;
    this.params?.onTurnOff?.();
  };

  public toggle = () => {
    this.isActive = !this.isActive;

    if (this.isActive) {
      this.params?.onTurnOn?.();
    } else {
      this.params?.onTurnOff?.();
    }
  };
}

export const createToggleStore = (params?: ToggleStoreParams) =>
  new ToggleStore(params);
