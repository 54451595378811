import type { ApiRequestOptions } from './ApiRequestOptions';
import type { CancelablePromise } from './CancelablePromise';
import type { OpenAPIConfig } from './OpenAPI';

export class BaseHttpRequest {
  constructor(public readonly config: OpenAPIConfig) {}

  public request<TData, TRequestBody>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    options: ApiRequestOptions<TRequestBody>,
  ): CancelablePromise<TData> {
    throw new Error('Not Implemented');
  }
}
