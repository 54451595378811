import { Button as UiButton } from '@astral/ui';

import { styled } from '../styled';

export const ButtonWrapper = styled(UiButton, {
  shouldForwardProp: (prop) => !prop.match(/isLoading|isDisabled/),
})`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: auto;
    min-height: 48px;
    padding: ${({ theme }) => theme.spacing(3.5, 4)};

    line-height: 20px;
    white-space: initial;
  }
`;
