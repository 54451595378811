export const getSelectedDefaultValue = (
  multiple: boolean,
  value?: unknown,
): string | boolean | (string | object)[] => {
  return multiple && typeof value === 'string' && value
    ? value.split(', ')
    : multiple && !value
      ? []
      : !multiple && value === undefined
        ? ''
        : typeof value === 'boolean'
          ? (value as boolean)
          : (value as string);
};
