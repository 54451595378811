import { styled } from '../styled';
import { OverflowTypography } from '../OverflowTypography';

export const UnderLineTypography = styled(OverflowTypography, {
  shouldForwardProp: (prop) => prop !== 'underline',
})<{ underline?: boolean }>`
  :hover {
    ${({ underline }) =>
      underline && 'text-decoration: underline; cursor: pointer; '}
  }
`;
