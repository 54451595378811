import { FORM_ERROR_MESSAGE } from '@constants/form/errorText';
import { MAP_OF_TEXT_LENGTH } from '@constants/form/fields';

import { max, min, optional, string } from '../schema/schema';

type CreateTextFieldOptions = {
  message?: string;
  minLength?: number;
  minMessage?: string;
  maxLength?: number;
  maxMessage?: string;
  exclude?: string[];
  excludeMessage?: string;
};

const { REQUIRED, MIN_LENGTH, MAX_LENGTH } = FORM_ERROR_MESSAGE;
const { sm } = MAP_OF_TEXT_LENGTH;

//TODO: рефакторинг KADRY-5570
export const createTextField = (
  required = true,
  options: CreateTextFieldOptions = {},
) => {
  const {
    message = REQUIRED,
    minLength = 0,
    maxLength = sm,
    maxMessage,
    minMessage,
    excludeMessage,
    exclude,
  } = options;
  const baseTestFiledValidation = string(
    min(minLength, { getMessage: () => minMessage || MIN_LENGTH(minLength) }),
    max(maxLength, { getMessage: () => maxMessage || MAX_LENGTH(maxLength) }),
    (value, ctx) => {
      if (value && exclude && exclude.length > 0 && exclude.includes(value)) {
        return ctx.createError({
          message: excludeMessage ?? FORM_ERROR_MESSAGE.DIFFERENT,
          code: 'have-to-be-excluded',
        });
      }

      return;
    },
  ).define({ requiredErrorMessage: message });

  return required ? baseTestFiledValidation : optional(baseTestFiledValidation);
};
