import { type DocumentTemplateTagType } from '../types';

export const DOCUMENT_TEMPLATE_TAG_TYPES: Record<
  Lowercase<DocumentTemplateTagType>,
  DocumentTemplateTagType
> = {
  system: 'System',
  user: 'User',
};

export const documentTemplateTagTypesI18n: Record<
  DocumentTemplateTagType,
  string
> = {
  System: 'Системный',
  User: 'Пользовательский',
};

export const documentTemplateTagTypes = [
  {
    name: documentTemplateTagTypesI18n.User,
    id: DOCUMENT_TEMPLATE_TAG_TYPES.user,
  },
  {
    name: documentTemplateTagTypesI18n.System,
    id: DOCUMENT_TEMPLATE_TAG_TYPES.system,
  },
];
