import { makeAutoObservable } from 'mobx';
import { objDifference } from '@utils/object/objDifference';
import {
  type SearchParamValidator,
  buildObjFromUrlSearchParamsAndInitialValue,
} from '@utils/object/buildObjFromUrlSearchParamsAndInitialValue';
import { removeEmptyFieldFormObject } from '@utils/object/removeEmptyFieldFormObject';
import { objectKeys } from '@utils/object/objectKeys';

/**
 * Метод для применения измененного searchParams к url браузера
 */
type SetSearchParams = (params: URLSearchParams) => void;

/**
 * набор типичных фильтров таблиц
 */
export type TypicalFilterParams<TOrderBy = string> = {
  /**
   * текст поиска
   */
  findText?: string;
  /**
   * направление сортировки
   */
  isDesc?: boolean;
  /**
   * ключ сортировки
   */
  orderBy?: TOrderBy;
};

export type FiltersOptions<T extends TypicalFilterParams> = {
  /**
   * DI для текущего searchParams
   */
  searchParams: URLSearchParams;
  /**
   * Метод для применения измененного searchParams к url браузера
   */
  setSearchParams: SetSearchParams;
  /**
   * Инит значения фильтров
   */
  initialFilters: T;
  /**
   * Валидатор квери строки
   */
  validator: SearchParamValidator<T>;
  /**
   * Сет фильтров, которые следует для счет и отображения в UI.
   * Например "isDesc", является атрибутом обозначающим направление сортировки,
   * должен попадать в квери параметры,
   * но для пользователя не должен быть виден в счетчике примененных фильтров
   */
  countableFilters?: Set<string>;
  /**
   * Список полей фильтров, которые не должны быть сброшены,
   * при вызове сброса из UI.
   * @example ["findText", "isDesc", "orderBy"]
   */
  nonResetableFields?: string[];
};

/**
 * Стор для работы с фильтрами
 */
export class Filters<T extends TypicalFilterParams> {
  /**
   * Примененные параметры фильтров
   */
  internalFilters: Partial<T>;

  /**
   * Инит значения фильтров
   */
  private initialFilters: T;

  /**
   * DI для текущего searchParams
   */
  private searchParams: URLSearchParams;

  /**
   * Метод для применения измененного searchParams к url браузера
   */
  private setSearchParams: SetSearchParams;

  /**
   * Валидатор квери строки
   */
  private validator: SearchParamValidator<T>;

  /**
   * Сет фильтров, которые следует для счет и отображения в UI.
   */
  private countableFilters?: Set<string>;

  /**
   * Список полей фильтров, которые не должны быть сброшены,
   * при вызове сброса из UI.
   * @example ["findText", "isDesc", "orderBy"]
   */
  private nonResetableFields?: string[];

  constructor({
    searchParams,
    setSearchParams,
    initialFilters,
    validator,
    countableFilters,
    nonResetableFields,
  }: FiltersOptions<T>) {
    this.searchParams = searchParams;
    this.setSearchParams = setSearchParams;
    this.initialFilters = initialFilters;
    this.validator = validator;
    this.countableFilters = countableFilters;
    this.nonResetableFields = nonResetableFields;

    this.internalFilters = removeEmptyFieldFormObject(
      buildObjFromUrlSearchParamsAndInitialValue(
        this.searchParams,
        this.initialFilters,
        this.validator,
      ),
    ) as unknown as T;

    makeAutoObservable(this);
  }

  /**
   * метод для обновления урла соответсвенно текущему значению фильтра
   */
  private updateUrl = () => {
    [...this.searchParams.keys()].forEach((key) => {
      this.searchParams.delete(key);
    });

    objectKeys(this.internalFilters).forEach((key) => {
      const value = this.internalFilters[key];

      // если значение пустое
      if (
        value !== undefined &&
        value !== '' &&
        value !== null &&
        value !== false
      ) {
        this.searchParams.set(String(key), JSON.stringify(value));
      }
    });

    // устанавливаем урл с обновленным searchParams
    this.setSearchParams(this.searchParams);
  };

  /**
   * метод сброса фильтров, предполагается использование в UI
   */
  public reset = () => {
    // создаем объект, для накопления фильтров, которые не должны быть сброшены
    const nonResetableData: Partial<T> = {};

    // перебираем полученный набор не сбрасываемых фильтров
    this.nonResetableFields?.forEach((key) => {
      // сохраняя значения из актуальных фильтров
      nonResetableData[key as keyof T] = this.internalFilters[key as keyof T];
    });

    // собираем новый набор фильтров из текущих, инит значений, и не сбрасываемых,
    // важно соблюдать порядок наложения
    this.internalFilters = removeEmptyFieldFormObject({
      ...this.initialFilters,
      ...nonResetableData,
    });
  };

  /**
   * метод для применения новых фильтров
   */
  public apply = (dataFilters: Partial<T>) => {
    // собираем новые фильтры наложением переданного набора, на существующие
    this.internalFilters = removeEmptyFieldFormObject({
      ...this.internalFilters,
      ...dataFilters,
    }) as unknown as T;
  };

  /**
   * параметр обозначающий количество примененных фильтров,
   * относительно переданного списка разрешенных к счету
   */
  public get filtersCount() {
    return Object.keys(this.internalFilters)
      .map((key) => this.countableFilters?.has(key))
      .filter(Boolean).length;
  }

  /**
   * вычисляемое поле, указывающее на то, что квери параметры и фильтры не синхронизированны
   */
  private get isUrlHasDifferenceWithFilters() {
    // создаем объект фильтров из квери параметров
    const urlFilters = buildObjFromUrlSearchParamsAndInitialValue(
      this.searchParams,
      {} as T,
      this.validator,
    );

    // вычисляем разницу между параметрами из строки и в основном поле
    const difference = objDifference(urlFilters, this.internalFilters);

    return Object.keys(difference).length > 0;
  }

  /**
   * компьютед значение фильтров
   */
  public get filters() {
    if (this.isUrlHasDifferenceWithFilters) {
      this.updateUrl();
    }

    return this.internalFilters;
  }

  /**
   * метод для установки значения текста поиска в фильтры
   */
  public setFindText = (value: string) => {
    this.internalFilters = removeEmptyFieldFormObject({
      ...this.internalFilters,
      findText: value,
    }) as unknown as T;

    this.updateUrl();
  };

  /**
   * текст поля поиска
   */
  public get findText() {
    return this.internalFilters.findText;
  }

  /**
   * флаг обозначающий направление сортировки
   */
  public get isDesc() {
    return this.internalFilters.isDesc;
  }

  public get orderBy() {
    return this.internalFilters.orderBy;
  }

  /**
   * метод переключения направления сортировки
   */
  public setIsDesc = (value: boolean) => {
    this.internalFilters = { ...this.internalFilters, isDesc: value };
    this.updateUrl();
  };

  /**
   * метод установки значения для ключа сортировки
   */
  public setOrderBy = (value: string) => {
    this.internalFilters = { ...this.internalFilters, orderBy: value };
    this.updateUrl();
  };
}
