import { useForm } from '@hooks/useForm';
import { createSchema, email, string } from '@schemas/schema';

import { type FormCellCommonProps, type FormCellToggleProps } from '../types';
import { CellInner } from '../CellInner';
import { FormInput } from '../../FormInput';
import { createSubmitHandler } from '../utils';

type CellTextInnerProps = FormCellCommonProps & FormCellToggleProps;

const schema = createSchema({
  value: string(email()),
});

/**
 * реализация внутренней части для емейла
 */
export const CellEmailInner = ({
  onSubmit,
  value,
  isLoading,
  onSuccess,
  onCancel,
}: CellTextInnerProps) => {
  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm({
    defaultValues: { value: value! },
    validateSchema: schema,
    onSubmit: createSubmitHandler({ onSuccess, onSubmit }),
  });

  return (
    <CellInner
      onSubmit={handleSubmit}
      isLoading={isLoading}
      onCancel={onCancel}
      isDirty={isDirty}
    >
      <FormInput
        fullWidth
        type="email"
        inputProps={{
          inputMode: 'email',
        }}
        size="small"
        aria-label="email"
        hiddenLabel
        control={control}
        name="value"
      />
    </CellInner>
  );
};
