import { forwardRef } from 'react';

import { styled } from '../styled';
import {
  MuiTable,
  MuiTableContainer,
  type MuiTableContainerProps,
} from '../external';
import { TableRow, type TableRowProps } from '../TableRow';

import { type TableResizerCols } from './TableResizer';
import { VIRTUOSO_SCROLLER_CLASSNAME } from './constants';

type TableContainerProps = MuiTableContainerProps & {
  width: number;
  columns: TableResizerCols;
  hasCheckBox: boolean;
};

export const TableContainer = styled(
  forwardRef<HTMLDivElement, TableContainerProps>((props, ref) => (
    <MuiTableContainer component="div" ref={ref} {...props} />
  )),
  {
    shouldForwardProp: (prop) => !prop.match(/width|columns|hasCheckBox/),
  },
)`
  --table-width: ${({ width }) => (width ? `${width}px` : '100%')};
  --table-checkbox-width: ${({ hasCheckBox }) => (hasCheckBox ? '55px' : '')};
  position: relative;

  width: 100%;
  height: 100%;

  border-bottom: 2px solid ${({ theme }) => theme.palette.grey[300]};
  ${({ columns }) =>
    columns.map(
      (width, index) => `--table-col-width-${index}: ${width / 100}; `,
    )}

  .${VIRTUOSO_SCROLLER_CLASSNAME} {
    overflow-x: hidden;
  }
`;

export const TableInner = styled(MuiTable, {
  shouldForwardProp: (prop) => prop !== 'loading',
})<{ component?: string }>`
  width: 100%;
  min-width: 750px;

  .MuiCheckbox-root {
    display: flex;
    align-items: center;
    justify-content: center;

    .MuiSvgIcon-root {
      width: 14px;
      height: 14px;
    }
  }

  .MuiTableRow-root {
    height: 44px;
    padding: 0;
  }
`;

export const PlaceholderWrapper = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const TableRowWrapper = styled((props: TableRowProps) => (
  <TableRow {...props} hover tabIndex={-1} />
))`
  width: 100%;
`;
