import { Element } from './styles';
import { type UseFetchMoreParams, useFetchMore } from './hooks/useFetchMore';

type FetchMoreElementProps = UseFetchMoreParams;

export const FetchMoreElement = (props: FetchMoreElementProps) => {
  const { ref } = useFetchMore(props);

  return <Element ref={ref} />;
};
