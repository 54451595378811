import { styled } from '../styled';

export const ContentLoaderWrapper = styled('div')`
  display: grid;
  gap: ${({ theme }) => theme.spacing(8)};
  place-items: center center;
  justify-content: center;

  margin: auto;
  padding: ${({ theme }) => theme.spacing(4)};

  text-align: center;
`;
