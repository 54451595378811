export const getShortFullName = (fullname: string | null) => {
  const arr = fullname?.split(' ') || [];

  const innerName = arr[1]?.split('')[0] || '';
  const innerMiddlename = arr[2]?.split('')[0] || '';
  const innerLastname = arr[0] || '';

  return `${innerLastname + ' '}${innerName ? innerName + '.' : ''}${
    innerMiddlename ? innerMiddlename + '.' : ''
  }`;
};
