import { makeAutoObservable, reaction } from 'mobx';
import { getCookieTimer, setCookieTimer } from '@utils/cookiedTime';

import {
  type SecondsTimer,
  createSecondsTimer,
} from '../../../../stores/SecondsTimer';

import { TIME_TO_WAIT } from './constants';

export type PhoneCodeInputStoreParams = {
  /**
   *  айди
   */
  id?: string;

  /**
   * номер телефона
   */
  phone: string | number;

  /**
   * функция повторной отправки кода СМС
   */
  onResend: () => void;
};

/**
 * стор предоставляющий тикающий текст и методы работы с ним
 */
export class PhoneCodeInputStore {
  /**
   * айди заявки
   */
  private readonly id: string | undefined;

  /**
   * номер телефона, на который отправляется смс
   */
  private phone: string | number;

  /**
   * функция повторной отправки кода подтверждения
   */
  private readonly onResend: () => void;

  /**
   * флаг того, что таймер уже вышел и нужно запрашивать новый код подтверждения
   */
  public isTimerFinished: boolean = false;

  constructor(params: PhoneCodeInputStoreParams) {
    this.id = params.id;
    this.phone = params.phone;
    this.onResend = params.onResend;
    /**
     * при инициализации проверяем наличие факта вышедшего таймера по конкретной заявке
     */
    this.isTimerFinished = Boolean(localStorage.getItem(this.cookieKey));
    makeAutoObservable(this);
    this.reactionOnTimerFinished();
  }

  /**
   * реакция на выход таймера с записью в LocalStorage
   */
  private reactionOnTimerFinished() {
    reaction(
      () => !this.secondsTimer.isActive,
      () => {
        this.isTimerFinished = true;
        localStorage.setItem(this.cookieKey, String(true));
      },
    );
  }

  get cookieKey() {
    return `${this.id}_${this.phone?.toString()}`;
  }

  public toTimerDate = (date: Date) => new Date(+date + TIME_TO_WAIT);

  get targetDate() {
    const dateString = getCookieTimer(this.cookieKey);

    if (typeof dateString === 'string') {
      return new Date(dateString);
    }

    const date = this.toTimerDate(new Date());

    setCookieTimer({ date, id: this.cookieKey });

    return date;
  }

  public secondsTimer: SecondsTimer = createSecondsTimer({
    targetDate: this.targetDate,
  });

  get isRealTimerActive() {
    return !this.isTimerFinished && this.secondsTimer.isActive;
  }

  public handleResendClick = () => {
    this.onResend();
    localStorage.removeItem(this.cookieKey);

    const date = this.toTimerDate(new Date());

    this.secondsTimer.resetTimer(date);
    setCookieTimer({ date, id: this.cookieKey });
    this.isTimerFinished = false;
  };

  /**
   * метод для очистки памяти, предполагается использование на unmount
   */
  public destroy() {
    this.reactionOnTimerFinished();
  }
}

export const createPhoneCodeInputStore = (params: PhoneCodeInputStoreParams) =>
  new PhoneCodeInputStore(params);
