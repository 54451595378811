/**
 * абстрактный синглтон сервис,
 * задачей которого является асинхронная загрузка чего то,
 * и при этом обеспечить эту загрузку только 1 раз,
 * и далее отдавать зарезолвеленный промис с результатои
 * */
export abstract class LoaderService<T> {
  /**
   * хранилище результата, изначально null
   */
  protected loadPromise: Promise<T> | null = null;

  /**
   * метод в котором надо создать промис загрузки
   */
  protected abstract createPromise: () => Promise<T>;

  /**
   * публичный метод получения промиса, если еще не вызывался,
   * тогда запустится выполнение промиса
   */
  public load = (): Promise<T> => {
    // если промиса нет, создаем
    if (!this.loadPromise) {
      this.loadPromise = this.createPromise();
    }

    // возвращаем промис
    return this.loadPromise;
  };
}
