import { makeAutoObservable, runInAction } from 'mobx';
import { DateService, MSECONDS_PER_SECOND } from '@services/DateService';

const toText = (date: Date) => DateService.toShortTimeReadableByLocal(date);

const getDifference = (date: Date) => Math.max(+date - +new Date(), 0);

export type SecondsTimerOptions = {
  /**
   * дата, до которой будет идти отсчет времени
   */
  targetDate: Date;
  /**
   * флаг изначальной активности таймера
   * @default true
   */
  isInitialTimerActive?: boolean;
};

/**
 * стор предоставляющий тикающий текст и методы работы с ним
 */
export class SecondsTimer {
  /**
   * опорная дата, относительно которой ведется отсчет
   */
  private baseDate: Date;

  /**
   * айдишка нативного таймера
   */
  private timer = 0;

  /**
   * флаг активности таймера
   */
  public isActive: boolean;

  /**
   * время в текстовом формате ММ:СС
   */
  public textTime: string;

  constructor({
    targetDate,
    isInitialTimerActive = true,
  }: SecondsTimerOptions) {
    this.baseDate = targetDate;
    this.isActive = isInitialTimerActive;
    this.textTime = toText(new Date(getDifference(targetDate)));
    this.run();
    makeAutoObservable(this);
  }

  /**
   * метод сброса таймера с приведением к выбранной дате
   */
  public resetTimer = (date: Date) => {
    this.baseDate = date;
    this.run();
  };

  /**
   * метод обновляющий текст времени
   */
  private tick = (ms: number) => {
    this.textTime = toText(new Date(ms));
  };

  /**
   * метод запускающий процесс таймера
   */
  private run = () => {
    const difference = getDifference(this.baseDate);

    if (difference > 0) {
      runInAction(() => {
        this.tick(difference);

        this.timer = setTimeout(
          this.run,
          MSECONDS_PER_SECOND,
        ) as unknown as number;

        this.isActive = true;
      });
    } else {
      runInAction(() => {
        this.isActive = false;
      });
    }
  };
}

export const createSecondsTimer = (params: SecondsTimerOptions) =>
  new SecondsTimer(params);
