import {
  type TooltipProps as TooltipPropsUI,
  Tooltip as TooltipUI,
} from '@astral/ui';

export type TooltipProps = Omit<TooltipPropsUI, 'ref'>;

export const Tooltip = ({ children, ...restProps }: TooltipProps) => (
  <TooltipUI css={undefined} {...restProps}>
    {children}
  </TooltipUI>
);
