import { type FullSizeNotificationParams } from '../../types';

// утилитка для создания настроек нотификации из перегруженных опций
export const getOverloadedNotificationOptions = (
  props: string | FullSizeNotificationParams,
) => {
  const isString = typeof props === 'string';
  const title = isString ? props : props.title;
  const imgAlt = typeof title === 'string' ? title : '';
  const options = isString ? {} : props;

  return { title, imgAlt, ...options };
};
