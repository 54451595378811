/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddTenantTokenDto } from '../models/AddTenantTokenDto';
import type { AddTenantTokenResponseDto } from '../models/AddTenantTokenResponseDto';
import type { TenantTokenDto } from '../models/TenantTokenDto';

import type { CancelablePromise } from '@common/data/api-core/CancelablePromise';
import type { BaseHttpRequest } from '@common/data/api-core/BaseHttpRequest';

export class TenantTokensService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Метод получения токенов тенанта
   * @returns TenantTokenDto
   * @throws ApiError
   */
  public tenantTokensGetTenantTokens({
    tenantId,
    employeeworkplaceid,
    kedoGatewayTokenType,
  }: {
    /** Идентификатор тенанта для запроса **/
    tenantId?: string;
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении. Требуется только для Web приложения **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
  }): CancelablePromise<Array<TenantTokenDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/identity/TenantTokens',
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
      query: {
        tenantId: tenantId,
      },
    });
  }

  /**
   * Метод для создания токена тенанта
   * @returns AddTenantTokenResponseDto
   * @throws ApiError
   */
  public tenantTokensAddTenantToken({
    requestBody,
    employeeworkplaceid,
    kedoGatewayTokenType,
  }: {
    /** Информация о создаваемом токене **/
    requestBody: AddTenantTokenDto;
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении. Требуется только для Web приложения **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
  }): CancelablePromise<AddTenantTokenResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/identity/TenantTokens',
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Метод для отзыва токена тенанта
   * @returns any
   * @throws ApiError
   */
  public tenantTokensRevokeTenantToken({
    tenantTokenId,
    employeeworkplaceid,
    kedoGatewayTokenType,
  }: {
    /** ID отзываемого токена **/
    tenantTokenId: string;
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении. Требуется только для Web приложения **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v2/identity/TenantTokens/{tenantTokenId}',
      path: {
        tenantTokenId: tenantTokenId,
      },
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
    });
  }
}
