import type { ApiRequestOptions } from './ApiRequestOptions';
import { BaseHttpRequest } from './BaseHttpRequest';
import type { CancelablePromise } from './CancelablePromise';
import type { OpenAPIConfig } from './OpenAPI';
import { request } from './request';

export class AxiosHttpRequest extends BaseHttpRequest {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(config: OpenAPIConfig) {
    super(config);
  }

  /**
   * Request method
   * @param options The request options from the service
   * @returns CancelablePromise<T>
   * @throws ApiError
   */
  public override request<TData, TRequestBody>(
    options: ApiRequestOptions<TRequestBody>,
  ): CancelablePromise<TData> {
    return request(this.config, options);
  }
}
