import { type GetBaseUrlQuery } from '@common/data/api-core';
import { MicroserviceRepository } from '@common/data';
import {
  type ApiError,
  type CacheService,
  type TenantId,
  type TypicalFilterParams,
  cacheService,
  convertBase64ToFileBlob,
} from '@shared';

import { AdministrativeClient } from '../../sources/client/administrative';
import { type BaseApiUrlConfig, configFetcher } from '../configFetcher';

import {
  organizationDetailsKey,
  tenantDataKey,
  tenantsCsv,
  tenantsKey,
} from './constants';
import {
  type TenantsRepositoryDTO,
  type UpdateAffiliateInfoParams,
  type UpdateTenantDocNotificationExecutorParams,
  type UpdateTenantIdentificationTypeParams,
} from './types';

type TenantsRepositoryParams = {
  getConfigQuery: GetBaseUrlQuery<BaseApiUrlConfig>;
  cacheService: CacheService<ApiError>;
};

export class TenantsRepository extends MicroserviceRepository<
  AdministrativeClient,
  BaseApiUrlConfig
> {
  constructor(private readonly params: TenantsRepositoryParams) {
    super({
      getConfigQuery: params.getConfigQuery,
      microserviceClient: AdministrativeClient,
    });
  }

  public getTenantsQuery = (params?: TypicalFilterParams) =>
    this.params.cacheService.createInfiniteQuery(
      [tenantsKey, params],
      (infinityParams): Promise<TenantsRepositoryDTO.TenantData[]> =>
        this.client.tenants.tenantsGetTenantsList({
          ...infinityParams,
          ...params,
        }),
      { isBackground: true },
    );

  public getTenantDataQuery = (tenantId: TenantId) =>
    this.params.cacheService.createQuery(
      [tenantDataKey(tenantId)],
      (): Promise<TenantsRepositoryDTO.TenantData> =>
        this.client.tenants.tenantsGetTenantById({
          tenantId,
        }),
    );

  public invalidateTenant = (tenantId: TenantId) => {
    this.params.cacheService.invalidate([
      tenantsKey,
      tenantsCsv,
      tenantDataKey(tenantId),
    ]);
  };

  public getUpdateTenantIdentificationTypeQuery = () =>
    this.params.cacheService.createMutation(
      ({
        tenantId,
        IdentificationType,
      }: UpdateTenantIdentificationTypeParams): Promise<unknown> =>
        this.client.tenants
          .tenantsUpdateTenantIdentificationType({
            tenantId,
            requestBody: { IdentificationType },
          })
          .then((r: boolean) => {
            this.invalidateTenant(tenantId);

            return r;
          }),
    );

  public getAddTenantQuery = () =>
    this.params.cacheService.createMutation(
      (params: TenantsRepositoryDTO.AddData) =>
        this.client.tenants
          .tenantsAddTenant({
            requestBody: params,
          })
          .then((tenantId: TenantId) => {
            this.invalidateTenant(tenantId);

            return tenantId;
          }),
    );

  public getOrganisationDetailsQuery = (inn: string) =>
    this.params.cacheService.createQuery([organizationDetailsKey(inn)], () =>
      this.client.externalDirectoriesInfo.externalDirectoriesInfoGetOrganizationDetails(
        { inn },
      ),
    );

  public getTenantsCsvQuery = (fileName: string) =>
    this.params.cacheService.createQuery([tenantsCsv], () =>
      this.client.tenants
        .tenantsExportTenants({})
        .then((res: string) => convertBase64ToFileBlob(res, fileName)),
    );

  public getUpdateTenantDocNotification = () =>
    this.params.cacheService.createMutation(
      ({ tenantId, isActive }: UpdateTenantDocNotificationExecutorParams) =>
        this.client.tenants
          .tenantsUpdateTenantDocNotification({
            tenantId,
            requestBody: {
              IsDisabled: !isActive,
            },
          })
          .then((res: boolean) => {
            this.invalidateTenant(tenantId);

            return res;
          }),
    );

  public getUpdateAffiliateInfoQuery = () =>
    this.params.cacheService.createMutation(
      ({
        tenantId,
        affiliateInfo,
      }: UpdateAffiliateInfoParams): Promise<unknown> =>
        this.client.tenants
          .tenantsUpdateAffilate({
            tenantId,
            requestBody: affiliateInfo,
          })
          .then((r: boolean) => {
            this.invalidateTenant(tenantId);

            return r;
          }),
    );
}

export const tenantsRepository = new TenantsRepository({
  getConfigQuery: configFetcher.config.create,
  cacheService,
});
