import {
  FormAutocomplete as UIFormAutocomplete,
  type FormAutocompleteProps as UIFormAutocompleteProps,
  useBackdropStackToggle,
} from '@astral/ui';
import { classnames } from '@utils';
import { METRIKA_CLASSES } from '@constants';
import { useMemo } from 'react';

import { Spinner } from '../../Spinner';

import { AutocompleteWrapper } from './styles';

export type BaseFormAutocompleteProps<
  TFieldValues extends object,
  TOption,
  TMultiple extends boolean,
  TDisableClearable extends boolean,
  TFreeSolo extends boolean,
> = Omit<
  UIFormAutocompleteProps<
    TFieldValues,
    TOption,
    TMultiple,
    TDisableClearable,
    TFreeSolo
  >,
  'onOpen' | 'onClose'
> & {
  hidePersonalData?: boolean;
  hideListboxPersonalData?: boolean;
  returnValueFormat?: (value: TOption) => string;
};

export const BaseFormAutocomplete = <
  TFieldValues extends object,
  TOption,
  TMultiple extends boolean,
  TDisableClearable extends boolean,
  TFreeSolo extends boolean,
>({
  hidePersonalData,
  hideListboxPersonalData,
  returnValueFormat,
  options,
  ...props
}: BaseFormAutocompleteProps<
  TFieldValues,
  TOption,
  TMultiple,
  TDisableClearable,
  TFreeSolo
>) => {
  const { handleClose, handleOpen } = useBackdropStackToggle();

  const formatedOptions = useMemo(
    () =>
      returnValueFormat
        ? options.map((option) => returnValueFormat(option) as TOption)
        : options,
    [options],
  );

  return (
    <AutocompleteWrapper
      $disabled={Boolean(props.disabled)}
      className={classnames({
        [METRIKA_CLASSES.hideInputValue]: hidePersonalData,
      })}
    >
      <UIFormAutocomplete<
        TFieldValues,
        TOption,
        TMultiple,
        TDisableClearable,
        TFreeSolo
      >
        {...props}
        options={formatedOptions}
        loadingText={<Spinner size="xm" type="pale" />}
        data-test={props.name}
        onOpen={handleOpen}
        onClose={handleClose}
        ListboxProps={{
          ...props.ListboxProps,
          className: classnames(
            {
              [METRIKA_CLASSES.blurContent]: hideListboxPersonalData,
            },
            props.ListboxProps?.className,
          ),
        }}
      />
    </AutocompleteWrapper>
  );
};
