import { makeAutoObservable, when } from 'mobx';

import { type CacheableQuery } from '../CacheService';
import { MSECONDS_PER_MINUTE } from '../DateService/constants/msecondsPerMinute';
import { TimeUpdater } from '../TimeUpdater';

const DEFAULT_TIME_TO_UPDATE = MSECONDS_PER_MINUTE;

export type ConfigStoreRequiredData = {
  VERSION: string;
  DISABLE_VERSION_POLLING?: boolean;
};

/**
 * Стор ответственный за работу с конфигом приложения и обновлением его по времени и по активности пользователя
 */
export class ConfigService<TData extends ConfigStoreRequiredData> {
  private initVersion?: string;

  private isInited = false;

  constructor(private readonly _query: CacheableQuery<TData>) {
    makeAutoObservable(this);
    this.setConfigByGlobal();
  }

  private setConfigByGlobal = () => {
    const globalConfig = (
      globalThis as unknown as {
        __ENV__: TData | undefined;
      }
    ).__ENV__;

    if (globalConfig) {
      this._query.forceUpdate(globalConfig);
    }
  };

  public get data(): TData {
    // Ожидается что в html.index находится конфиг со стартовыми значениями,
    // следовательно, данные конфига должны быть всегда.
    return this._query.data!;
  }

  public init = (onNewVersion?: () => void) => {
    if (this.isInited) {
      return;
    }

    const updater = new TimeUpdater({
      update: this._query.invalidate,
      timeToUpdate: DEFAULT_TIME_TO_UPDATE,
    });

    this._query
      .async()
      .then(({ VERSION, DISABLE_VERSION_POLLING }: ConfigStoreRequiredData) => {
        this.initVersion = VERSION;

        if (DISABLE_VERSION_POLLING) {
          updater.destroy();
        } else {
          when(
            () => this.hasDifferentVersion,
            () => {
              updater.destroy();
              onNewVersion?.();
            },
          );
        }
      });

    this.isInited = true;
  };

  private get hasDifferentVersion() {
    if (!this.initVersion || !this._query.data?.VERSION) {
      return false;
    }

    return this.initVersion !== this._query.data?.VERSION;
  }

  public get isLoading() {
    return this._query.isLoading;
  }

  public get isSuccess() {
    return Boolean(this.initVersion);
  }

  /**
   * асинхроныый метод получения данных конфига
   */
  public async = () => this._query.async();
}
