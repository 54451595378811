import {
  type PropsWithChildren,
  type ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

const ComponentPortalContext = createContext<{
  setContent: (content: ReactNode) => void;
  content: ReactNode;
}>({
  setContent: () => {},
  content: null,
});

export const ComponentPortalContextProvider = ({
  children,
}: PropsWithChildren) => {
  const [content, setContent] = useState<ReactNode>(null);

  return (
    <ComponentPortalContext.Provider value={{ content, setContent }}>
      {children}
    </ComponentPortalContext.Provider>
  );
};

type ComponentPortalTargetProps = PropsWithChildren & {
  isNotActive?: boolean;
};

export const ComponentPortalSource = ({
  isNotActive,
  children,
}: ComponentPortalTargetProps) => {
  const { setContent } = useContext(ComponentPortalContext);

  useEffect(() => {
    if (isNotActive) {
      return;
    }

    setContent(children);
  }, [isNotActive, children]);

  return null;
};

export const ComponentPortalPlacement = () => {
  const { content } = useContext(ComponentPortalContext);

  return content;
};
