export const lockClasses = {
  simple: 'is-blocked',
  ios: 'is-blocked-touch',
};

export class PageLocker {
  /**
   * позиция скрола, при блокировании
   */
  #initialScrollPosition = 0;

  /**
   * список заблокированных элементов
   */
  #retainers: Set<String> = new Set();

  /**
   * флаг заблокированности
   */
  #isLocked = false;

  /**
   * дом нода, с которой проводятся операции блокирования
   */
  #target = globalThis.document?.documentElement;

  /**
   * флаг обозначающий принадлежность к айфонам
   */
  public isIOS: boolean;

  /**
   * вычисляемый цсс класс в зависимости от устройства пользователя
   */
  #lockedClass: string;

  constructor() {
    // проверяем и запоминаем устройство пользователя
    this.isIOS = Boolean(navigator.platform.match(/iPhone|iPod|iPad/));
    // вычисляем нужный цсс класс
    this.#lockedClass = this.isIOS ? lockClasses.ios : lockClasses.simple;
  }

  /**
   * метод для блокировки основного скрола страницы
   */
  public lock = (id: string): void => {
    // запоминаем айдишку компонента, заблокировавшего страницу
    this.#retainers.add(id);

    // если страница еще не заблокирована
    if (!this.#isLocked) {
      // если у пользователя айфон
      if (this.isIOS) {
        // запоминаем позицию скрола
        this.#initialScrollPosition = window.pageYOffset;
        // добавляем дом ноде хак имитирующий позицию скрола
        this.#target.style.top = -this.#initialScrollPosition + 'px';
      }

      // добавляем класс блокировки
      this.#target.classList.add(this.#lockedClass);
      //очищаем флаг блокировки
      this.#isLocked = true;
    }
  };

  /**
   * метод разблокировки основного скрола страницы
   */
  public unlock = (id: string): void => {
    // удаляем элемент из заблокированных
    this.#retainers.delete(id);

    // если в стеке пусто, но флаг заблокированности включен
    if (!Boolean(this.#retainers.size) && this.#isLocked) {
      // убираем цсс класс
      this.#target.classList.remove(this.#lockedClass);

      // если у пользователя айфон
      if (this.isIOS) {
        // обнуляем хак имитации скрола
        this.#target.style.top = '';
        // выставляем скрол в ожидаемую позицию
        this.#target.scrollTo(0, this.#initialScrollPosition);
      }

      // выключаем флаг заблокированности
      this.#isLocked = false;
    }
  };
}

/**
 * сервис отвечающий за блокировку основного скрола страницы,
 * предполагается использование при открытии кастомных модалок, попапов и т.п.
 */
export const pageLocker = new PageLocker();
