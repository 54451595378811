/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '@common/data/api-core/CancelablePromise';
import type { BaseHttpRequest } from '@common/data/api-core/BaseHttpRequest';

export class UsersService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Получение телефона/email пользователя по его Id
   * @returns string телефона/email пользователя
   * @throws ApiError
   */
  public usersGetLogin({
    userId,
    employeeworkplaceid,
    kedoGatewayTokenType,
  }: {
    /** Id пользователя **/
    userId?: string;
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении. Требуется только для Web приложения **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
  }): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/identity/Users/Logins',
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
      query: {
        userId: userId,
      },
    });
  }
}
