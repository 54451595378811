import { styled } from '../../styled';
import { RouterLink } from '../../RouterLink';

export const MenuItemWrapper = styled.div`
  position: relative;

  overflow: hidden;
`;

export const MenuLinkIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  min-width: 24px;
`;

export const MenuLink = styled(RouterLink, {
  shouldForwardProp: (prop) => prop !== 'isOpen' && prop !== 'active',
})<{
  isOpen: boolean;
  active: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  max-width: ${({ theme, isOpen }) => (isOpen ? '100%' : theme.spacing(10))};
  height: 40px;
  padding: ${({ theme }) => theme.spacing(2)};

  color: ${({ theme, active }) =>
    active ? theme.palette.primary[800] : theme.palette.grey[900]};
  text-decoration: none;

  background: ${({ theme, active }) => active && theme.palette.grey[100]};
  border-radius: 4px;

  transition:
    color 0.25s linear,
    background-color 0.25s linear;

  svg {
    fill: currentColor;
  }

  &:hover {
    color: ${({ theme }) => theme.palette.primary[800]};

    background: ${({ theme }) => theme.palette.grey[100]};
  }
`;
