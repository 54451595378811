import { type MouseEvent } from 'react';
import { classnames } from '@utils/string/classnames';

import { METRIKA_CLASSES } from '../../../constants/metrikaClasses';
import { OverflowTypography } from '../../OverflowTypography';
import { type TableColumns } from '../types';
import { ACTION_CELL_NAME } from '../constants';

import { TableCellInner, TableCellWrapper } from './styles';

export type CellProps<
  TData extends {},
  TSortBy extends string | number | symbol = keyof TData,
> = {
  row: TData;
  cell: TableColumns<TData, TSortBy>;
  onClick?: (row: TData) => void;
  checkRowClickable?: (row: TData) => boolean;
  isLast?: boolean;
  disabled?: boolean;
  index?: number;
};

export function Cell<
  TData extends {},
  TSortBy extends string | number | symbol = keyof TData,
>({
  row,
  cell,
  onClick,
  checkRowClickable = () => Boolean(onClick),
  isLast,
  disabled,
  index,
}: CellProps<TData, TSortBy>) {
  // eslint-disable-next-line testing-library/render-result-naming-convention
  const RenderCell = cell.renderCell;

  if (cell.field === ACTION_CELL_NAME && !RenderCell) {
    console.warn(`
    [table cell]: table cell must have renderCell props
    `);
  }

  const isActionCell = cell.field === ACTION_CELL_NAME;

  const cellValue =
    cell.field !== ACTION_CELL_NAME ? row[cell.field] || '' : '';
  let formatedValue = cell.format ? cell.format(row) : cellValue;

  if (!formatedValue && cell.empty) {
    formatedValue = cell.empty ?? '—';
  }

  const isClickable = !isActionCell && checkRowClickable(row);

  const handlerClick = isClickable
    ? (event: MouseEvent) => {
        if (isActionCell) {
          event.stopPropagation();

          return;
        }

        onClick?.(row);
      }
    : undefined;

  return (
    <TableCellWrapper
      align="left"
      pointer={isClickable}
      onClick={handlerClick}
      disabled={disabled}
      index={index}
    >
      {RenderCell ? (
        (isLast && isActionCell && (
          <TableCellInner
            isAlignRight
            className={classnames({
              [METRIKA_CLASSES.blurContent]: cell.hidePersonalData,
            })}
          >
            {RenderCell(row)}
          </TableCellInner>
        )) ||
        RenderCell(row)
      ) : (
        <OverflowTypography hidePersonalData={cell.hidePersonalData}>
          {formatedValue as string}
        </OverflowTypography>
      )}
    </TableCellWrapper>
  );
}
