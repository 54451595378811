import { type PropsWithChildren } from 'react';

import {
  DoubleSideContainer,
  PageContent,
  PageHeader,
  PageLeft,
  PageRight,
} from './components';
import { PageContainer, SecondStretchedPageContainer } from './styles';

type PageProps = {
  isSecondElementStretched?: boolean;
  fullWidth?: boolean;
};

const Page = ({
  isSecondElementStretched,
  ...props
}: PropsWithChildren<PageProps>) =>
  isSecondElementStretched ? (
    <SecondStretchedPageContainer {...props} />
  ) : (
    <PageContainer {...props} />
  );

Page.header = PageHeader;
Page.content = PageContent;
Page.right = PageRight;
Page.left = PageLeft;
Page.doubleSideContainer = DoubleSideContainer;

export { Page };
