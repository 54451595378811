import { ToggleStore, uuid } from '@common/shared';
import { makeAutoObservable } from 'mobx';

import { type FullSizeNotificationParams, type NotificationId } from './types';

const DEFAULT_TIMEOUT = 2000;

export class FullSizeNotificationItem {
  public showed = new ToggleStore();

  public active = new ToggleStore();

  public nextId = uuid();

  constructor(
    public readonly props: FullSizeNotificationParams,
    public readonly id: NotificationId,
    public readonly _onOpened: (id: NotificationId) => void,
    public readonly _onRemove: (id: NotificationId) => void,
    public readonly _onClose: (id: NotificationId) => void,
  ) {}

  public onOpened = () => {
    this._onOpened(this.id);
  };

  public onClose = () => {
    this._onClose(this.id);
  };

  public onRemove = () => {
    this._onRemove(this.id);
  };
}

class FullSizeNotificationQueue {
  /**
   * предыдущий, предполагается наличие только в состоянии закрываемой анимации
   */
  private prev: FullSizeNotificationItem | null = null;

  /**
   * текущий
   */
  private current: FullSizeNotificationItem | null = null;

  /**
   * последний добавленный
   */
  private lastAdded: FullSizeNotificationItem | null = null;

  /**
   * очередь
   */
  private queue = new Map<NotificationId, FullSizeNotificationItem>();

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * добавление нотификации
   */
  public add = (props: FullSizeNotificationParams) => {
    // создаем элемент обогащенный внутренними флагами
    const detailedItem = new FullSizeNotificationItem(
      props,
      // у последнего добавленного,
      // берем id для нового элемента, либо передаем новое значение
      this.lastAdded?.nextId ?? uuid(),
      this.opened,
      this.remove,
      this.close,
    );

    this.lastAdded = detailedItem;
    this.queue.set(detailedItem.id, detailedItem);

    if (!this.current) {
      this.open(detailedItem.id);
    }
  };

  /**
   * эвент запуска открытия нотификации
   */
  private open = (id: NotificationId) => {
    // достаем элемент из очереди
    const newCurrent = this.queue.get(id) ?? null;

    // отмечаем его активным
    newCurrent?.active.turnOn();
    // говорим очереди о том что этот элемент пошел в работу
    this.current = newCurrent;

    globalThis.setTimeout(
      () => this.close(id),
      this.current?.props.delay ?? DEFAULT_TIMEOUT,
    );
  };

  /**
   * эвент начала закрытия нотификации,
   * вызов приводит к запуску анимации закрытия
   */
  private close = (id: NotificationId) => {
    // сверяем его с вызываемой айдишкой
    if (!this.current || id !== this.current.id) {
      // если не совпало, значит вызов пришел из таймера,
      // но пользователь уже закрыл нотификацию,
      // и мы просто игнорируем вызов, возвращая стейт без изменений
      return;
    }

    // если отсутствует флаг показа, следовательно,
    // пользователь пытается закрыть нотификацию,
    // быстрее, чем она проявилась,
    // это может привести к пропуску вызова запланированного колбэка
    if (!this.current.showed.isActive) {
      this.current?.props.onEntered?.();
    }

    // выключаем флаг активности,
    // чтобы запустилась анимация закрытия
    this.current.active.turnOff();
    // перемещаем элемент из текущего в прошлые
    this.prev = this.current;
    this.current = null;
  };

  /**
   * эвент вычищения информации об отработавшей нотификации
   */
  private remove = (id: NotificationId) => {
    this.queue.delete(id);

    // если последний добавленный элемент совпадает с удаляемым,
    // значит мы дошли до конца очереди, и надо все подчистить
    if (this.lastAdded?.id === id) {
      this.queue.clear();
      this.current = null;
      this.prev = null;
      this.lastAdded = null;
    }
  };

  /**
   * эвент запускаемый когда нотификация проявилась из анимации,
   * и можно запустить фоновые изменения компонента
   */
  private opened = (id: NotificationId) => {
    // достаем элемент из очереди
    const item = this.queue.get(id);

    // отмечаем элемент как показанный
    item?.showed.turnOn();
    // и пытаемся вызвать у него колбэк
    item?.props.onEntered?.();
  };

  public get notifications(): [
    FullSizeNotificationItem | null,
    FullSizeNotificationItem | null,
  ] {
    return [this.prev, this.current];
  }
}

export const fullSizeNotificationsQueue = new FullSizeNotificationQueue();
