import { styled } from '../../styled';
import { TextField } from '../../TextField';

export const Container = styled('section')<{ $hasFileButton?: boolean }>`
  display: grid;
  grid-template-columns: ${({ $hasFileButton }) =>
    $hasFileButton ? 'auto 1fr' : '1fr'};
  grid-column-gap: ${({ theme }) => theme.spacing(3)};
  align-items: center;
  padding: ${({ theme }) => theme.spacing(2, 4)};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: ${({ theme }) => theme.spacing(2, 6)};
  }
`;

export const Form = styled('form')`
  display: grid;
  grid-template-columns: 1fr auto;
  width: 100%;
  grid-column-gap: ${({ theme }) => theme.spacing(3)};
  align-items: center;
`;

export const Input = styled(TextField)`
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  .MuiFormHelperText-root {
    display: none;
  }
`;
