import { styled } from '../styled';
import { Typography } from '../Typography';

export const Container = styled('div')`
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing(1)}
    ${({ theme }) => theme.spacing(4)};
  grid-template-areas: 'title input' 'text input';
  grid-template-columns: 1fr auto;
  grid-template-rows: auto 1fr;
`;

export const Title = styled(Typography)`
  grid-area: title;
`;

export const Description = styled(Typography)`
  grid-area: text;
`;

export const InputContainer = styled('div')`
  grid-area: input;
  align-self: center;
`;
