import { resetHoursToTimezoneOffset } from '../resetHoursToTimezoneOffset';

/**
 * утилита создания даты не зависящей от часового пояса, в котором находится рантайм
 */
export const buildUTCDate = (
  year: number,
  month = 1,
  day = 1,
  hour = 0,
  minute = 0,
  second = 0,
) => {
  return resetHoursToTimezoneOffset(
    new Date(year, month - 1, day, hour, minute, second),
  );
};
