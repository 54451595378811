export const convertBase64ToFileBlob = async (
  base64String: string,
  type: string,
) => {
  try {
    const tblob = await fetch(`data:${type};base64,${base64String}`);

    return await tblob.blob();
  } catch (e) {
    return null;
  }
};
