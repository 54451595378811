import { type GenderType } from '../types';

type GenderOptions = { name: string; id: GenderType };

export const genderOptions: GenderOptions[] = [
  { name: 'Мужской', id: 'Male' },
  { name: 'Женский', id: 'Female' },
];

export const genderOptionsI18n = {
  Male: genderOptions[0].name,
  Female: genderOptions[1].name,
};
