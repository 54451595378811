import { TableSortLabel as MuiTableSortLabel } from '../../TableSortLabel';
import { type OnRequestSortCb, type SortDirection } from '../types';
import { SortIcon } from '../../Icons';

type TableSortLabelProps = {
  order: SortDirection;
  orderBy?: string | number | symbol;
  onRequestSort: OnRequestSortCb;
  field: string;
  sortableFieldName?: string | number | symbol;
};

export const TableSortLabel = ({
  order,
  onRequestSort,
  orderBy,
  field,
  sortableFieldName,
}: TableSortLabelProps) => {
  const isActive =
    Boolean(orderBy) && (orderBy === sortableFieldName || orderBy === field);

  return (
    <MuiTableSortLabel
      active={isActive}
      IconComponent={() => <SortIcon sort={isActive && order} />}
      onClick={(e) => onRequestSort(e, sortableFieldName || field)}
    />
  );
};
