import { styled } from '../styled';

export const Wrapper = styled('article')<{ $isSmall?: boolean }>`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto auto;
  grid-gap: ${({ theme }) => theme.spacing(2)};

  width: 100%;
  max-width: 100%;
  padding: ${({ theme, $isSmall }) => theme.spacing($isSmall ? 1 : 3, 3)};

  background-color: ${({ theme }) => theme.palette.background.elementHover};
  border-radius: 3px;
`;
