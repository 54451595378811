import { styled } from '../../../styled';

type PageLeftWrapperProps = {
  padding?: number[];
};

export const PageLeftWrapper = styled('section', {
  shouldForwardProp: (prop) => prop !== 'padding',
})<PageLeftWrapperProps>`
  overflow: hidden;

  padding: ${({ theme, padding }) =>
    padding ? theme.spacing(padding[0], padding[1]) : ''};

  border-right: 1px solid ${({ theme }) => theme.palette.grey[300]};
`;
