export const downloadFileByLink = (fileUrl: string, filename: string) => {
  const tempLink = document.createElement('a');

  tempLink.href = fileUrl;
  tempLink.setAttribute('download', filename);
  tempLink.setAttribute('target', '_blank');
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
};
