import { Tag } from '../Tag';
import { styled } from '../styled';

import { PDFDocument } from './PdfDocument';
import { type PdfReaderVariant } from './types';

export const PDFDocumentStyled = styled(PDFDocument)<{
  $variant: PdfReaderVariant;
}>`
  overflow: auto;

  height: 100%;
  padding: ${({ theme, $variant }) =>
    $variant === 'default' ? theme.spacing(6, 6, 4) : ''};

  border-bottom: ${({ $variant }) => ($variant === 'default' ? 1 : 0)}px solid
    ${({ theme }) => theme.palette.grey[300]};

  & > * {
    user-select: auto;
  }

  & .react-pdf__Page__canvas {
    user-select: auto;

    margin: 0 auto
      ${({ theme, $variant }) => theme.spacing($variant === 'default' ? 5 : 0)};

    border-radius: ${({ theme }) => theme.spacing(1)};
    box-shadow: ${({ theme }) => theme.elevation[200]};
  }

  & .react-pdf__Page {
    position: relative;
  }

  .react-pdf__message {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
  }

  & .react-pdf__Page__annotations,
  & .react-pdf__Page__textContent {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    max-width: 100%;
    max-height: 100%;
  }
`;

export const PageWrapper = styled('div')`
  position: relative;
`;

export const TagWrapper = styled(Tag)`
  position: absolute;
  z-index: 1;
  top: ${({ theme }) => theme.spacing(3)};
  right: ${({ theme }) => theme.spacing(4)};

  box-shadow: ${({ theme }) => theme.elevation[100]};
`;

export const PDFDocumentGrid = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;

  height: 100%;
`;
