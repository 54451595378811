/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrganizationDetailsDto } from '../models/OrganizationDetailsDto';

import type { CancelablePromise } from '@common/data/api-core/CancelablePromise';
import type { BaseHttpRequest } from '@common/data/api-core/BaseHttpRequest';

export class ExternalDirectoriesInfoService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Автозаполнение реквизитов по ИНН
   * @returns OrganizationDetailsDto OrganizationDetailsDto
   * @throws ApiError
   */
  public externalDirectoriesInfoGetOrganizationDetails({
    inn,
    employeeworkplaceid,
    kedoGatewayTokenType,
  }: {
    inn: string;
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении. Требуется только для Web приложения **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
  }): CancelablePromise<OrganizationDetailsDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/administrative/ExternalDirectoriesInfo/OrganizationDetails/{inn}',
      path: {
        inn: inn,
      },
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
    });
  }
}
