import { useEffect } from 'react';
import { metaTitleService } from '@services/MetaTitleService';

type UseMetaTitleOptions = {
  title?: string;
  order?: number;
  description?: string;
};

export const useMetaTitleService = ({
  title,
  order,
  description = '',
}: UseMetaTitleOptions) =>
  useEffect(() => {
    if (title) {
      metaTitleService.addSubtitle(title, order);
    }

    if (description) {
      metaTitleService.setDescription(description);
    }

    return () => {
      if (title) {
        metaTitleService.removeSubTitle(title);
      }

      if (description) {
        metaTitleService.cleanDescription();
      }
    };
  }, [title, description]);
