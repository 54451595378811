import { Children, type PropsWithChildren, type ReactNode, memo } from 'react';

import { ErrorBoundary } from '../ErrorBoundary';
import { Spinner, type SpinnerSizes } from '../Spinner';

import { ContentViewerInner, ContentViewerWrapper } from './styles';

const Wrapper = ({ children }: PropsWithChildren<{}>) => (
  <ContentViewerWrapper>
    <ContentViewerInner>{children}</ContentViewerInner>
  </ContentViewerWrapper>
);

export type ContentViewerProps = {
  isLoading: boolean;
  loadingContent?: ReactNode;
  noData?: ReactNode;
  isError?: boolean;
  errorContent?: ReactNode;
  alwaysShowChildren?: ReactNode;
  spinnerSize?: SpinnerSizes;
};

export const ContentViewer = memo(
  ({
    isLoading,
    noData,
    loadingContent,
    children,
    isError,
    errorContent = 'Что-то сломалось',
    spinnerSize = 'xm',
  }: PropsWithChildren<ContentViewerProps>) => {
    const isNoData = !Boolean(Children.count(children));

    if (isLoading) {
      return (
        <Wrapper>
          {loadingContent || <Spinner type="pale" size={spinnerSize} />}
        </Wrapper>
      );
    }

    if (!isLoading && isError && errorContent) {
      return <Wrapper>{errorContent}</Wrapper>;
    }

    if (!isLoading && isNoData && noData) {
      return <Wrapper>{noData}</Wrapper>;
    }

    if (!isLoading && !isNoData && !isError) {
      return <ErrorBoundary>{children}</ErrorBoundary>;
    }

    return null;
  },
);
