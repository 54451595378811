import {
  type FieldValues,
  type RegisterOptions,
  type UseControllerProps,
  useController,
} from 'react-hook-form';
import { useBackdropStackToggle } from '@astral/ui';

import { type SelectProps } from '../../Select/types';
import { Select } from '../../Select';

export type FormSelectProps<FormValues extends FieldValues> = {
  rules?: RegisterOptions;
  validate?: RegisterOptions['validate'];
  disabled?: boolean;
  forceDisabled?: boolean;
} & Omit<SelectProps, 'name'> &
  Omit<UseControllerProps<FormValues>, 'rules' | 'disabled'>;

export function FormSelect<FormValues extends FieldValues>({
  disabled,
  ...props
}: FormSelectProps<FormValues>): JSX.Element {
  const { handleClose, handleOpen } = useBackdropStackToggle();
  const {
    field: { onChange, value },
    fieldState,
  } = useController(props);
  const errorMessages = fieldState.error?.message;
  const hasError = Boolean(errorMessages);

  const handleChange: SelectProps['onChange'] = (e) => {
    onChange(e);
    props.onChange?.(e);
  };

  return (
    <Select
      {...props}
      readonly={disabled}
      onOpen={handleOpen}
      onClose={handleClose}
      value={value}
      defaultValue={value}
      onChange={handleChange}
      error={hasError}
      helperText={(hasError && errorMessages) || ''}
    />
  );
}
