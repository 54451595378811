import { type Primitive } from '@tsUtils';

import { type ConfigService } from '../ConfigService';

const MAX_TEXT_LENGTH = 30;

type ErrorTrackingOptions = {
  tags?: Record<string, Primitive>;
  extra?: Record<string, unknown>;
  contexts?: Record<string, Record<string, unknown>>;
};

type CaptureFn = (exception: unknown, options: ErrorTrackingOptions) => string;

const FB_FN = () => '';

export type ErrorMonitoringServiceRequiredConfigData = {
  SENTRY_DSN?: string;
  VERSION: string;
};

export class ErrorMonitoringService<
  TConfig extends ErrorMonitoringServiceRequiredConfigData,
> {
  private promise: null | Promise<CaptureFn> = null;

  constructor(private readonly _configService: ConfigService<TConfig>) {}

  private getSentry = () => import('@sentry/browser');

  private initService = () => {
    this._configService
      .async()
      .then(
        ({ VERSION, SENTRY_DSN }: { SENTRY_DSN?: string; VERSION: string }) => {
          if (!SENTRY_DSN) {
            return;
          }

          this.getSentry().then(
            ({ init, BrowserTracing, replayIntegration }) => {
              init({
                dsn: SENTRY_DSN,
                release: VERSION,
                dist: 'production',
                integrations: [new BrowserTracing(), replayIntegration()],
                replaysSessionSampleRate: 0.1,
                replaysOnErrorSampleRate: 1.0,
                tracesSampleRate: 1.0,
                beforeBreadcrumb(breadcrumb, hint) {
                  if (breadcrumb?.message?.match(/yandex/)) {
                    return null;
                  }

                  if (breadcrumb?.category?.startsWith('ui')) {
                    const node = hint?.event.target;

                    let text =
                      node?.innerText ||
                      node?.ariaLabel ||
                      node?.placeholder ||
                      [...node?.labels]?.[0]?.innerText;

                    if (text.length > MAX_TEXT_LENGTH) {
                      text = `${text.substring(0, MAX_TEXT_LENGTH)}...`;
                    }

                    breadcrumb.message = `${node?.tagName.toLowerCase()}: ${text}`;
                  }

                  return breadcrumb;
                },
              });
            },
          );
        },
      );
  };

  public init = () => {
    this.initService();
  };

  public capture = (exception: unknown, options?: ErrorTrackingOptions) => {
    if (!this.promise) {
      this.promise = this._configService
        .async()
        .then((data) =>
          data?.SENTRY_DSN
            ? this.getSentry().then(({ captureException }) => captureException)
            : FB_FN,
        )
        .catch((e) => {
          console.error(e);

          return FB_FN;
        });
    }

    this.promise?.then((capture) => capture(exception, options || {}));
  };
}
