import {
  type FormEvent,
  type KeyboardEventHandler,
  type PropsWithChildren,
} from 'react';

import { FormWrapper } from './FormWrapper';
import { FormFooter } from './FormFooter';
import { FormElement } from './styles';

export type FormProps = {
  onSubmit: () => void;
  onKeyDown?: KeyboardEventHandler<HTMLFormElement>;
  id?: string;
};

const Form = ({
  children,
  onSubmit,
  ...props
}: PropsWithChildren<FormProps>) => {
  const handlerSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    onSubmit();
  };

  return (
    <FormElement
      onKeyDown={(e) => {
        e.stopPropagation();
      }}
      onSubmit={handlerSubmit}
      {...props}
    >
      {children}
    </FormElement>
  );
};

Form.wrapper = FormWrapper;
Form.footer = FormFooter;

export { Form };
