import { type PropsWithChildren } from 'react';

import { GeneralWrapper } from './styles';
import { type PageContentProps } from './types';

const PageContent = ({
  withTableContent,
  children,
  withoutPadding,
}: PropsWithChildren<PageContentProps>) =>
  withTableContent ? (
    <>{children}</>
  ) : (
    <GeneralWrapper withoutPadding={withoutPadding}>{children}</GeneralWrapper>
  );

export { PageContent };
