import { type AvatarProps as MuiAvatarProps } from '@mui/material';
import { type WithoutEmotionSpecific } from '@astral/ui';
import { type ForwardedRef, forwardRef, useMemo } from 'react';
import { ONLY_TEXT_AND_NUMBERS } from '@regexp';
import { checkWithBoolean } from '@tsUtils';

import { Skeleton } from '../Skeleton';

import { AvatarSizes } from './enums';
import { AvatarWrapper } from './styles';

export type AvatarProps = Omit<
  WithoutEmotionSpecific<MuiAvatarProps>,
  'variant' | 'src'
> & {
  name: string;
  height?: number;
  width?: number;
  size?: keyof typeof AvatarSizes;
  fontSize?: keyof typeof AvatarSizes;
  isLoading?: boolean;
  /**
   * флаг говорящий о том, что загрузка уже не идет, но аватар не доступен
   */
  isUnavailable?: boolean;
  variant?: 'circular' | 'rounded';
  src?: string | null;
  isPrimaryColor?: boolean;
  isLight?: boolean;
};

export const Avatar = forwardRef(
  (
    {
      name,
      size = 'md',
      fontSize,
      isLoading,
      variant = 'circular',
      isUnavailable,
      children,
      src,
      ...props
    }: AvatarProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const sizeValue = useMemo(() => `${AvatarSizes[size]}px`, [size]);
    const normalizedName = useMemo(
      () =>
        name
          ?.replace(ONLY_TEXT_AND_NUMBERS, '')
          ?.split(' ')
          .slice(0, 2)
          .join(' '),
      [name],
    );

    const initials = useMemo(() => {
      return normalizedName
        ?.split(' ')
        .slice(0, 2)
        .filter(checkWithBoolean)
        .map((item) => item[0])
        .join('');
    }, [normalizedName, src]);

    if (isLoading || isUnavailable) {
      return (
        <Skeleton
          variant={variant}
          width={sizeValue}
          height={sizeValue}
          isUnavailable={isUnavailable}
        />
      );
    }

    return (
      <AvatarWrapper
        size={sizeValue}
        ref={ref}
        variant={variant}
        src={src || undefined}
        name={normalizedName}
        initials={initials}
        imgProps={{
          loading: 'lazy',
        }}
        {...props}
      >
        {children || ''}
      </AvatarWrapper>
    );
  },
);
