import { observer } from 'mobx-react-lite';
import { routerService } from '@services/RouterService';
import { useMetaTitleService } from '@hooks/useMetaTitleService';

import { ArrowLOutlineMd } from '../../../Icons';
import { SearchField } from '../../../SearchField';

import {
  PageHeaderActionsBelow,
  PageHeaderActionsUnder,
  PageHeaderBackBtn,
  PageHeaderChildrenWrapper,
  PageHeaderSubtitle,
  PageHeaderTagWrapper,
  PageHeaderTitle,
  PageHeaderWithTagWrapper,
  PageHeaderWrapper,
  PageHeaderactionUnderBelow,
} from './styles';
import { type PageHeaderProps } from './types';
import { useTemplate } from './hooks/useTemplate';

export const PageHeader = observer(
  ({
    children,
    title,
    subtitle,
    searchValue = '',
    hasBack,
    actionsUnder,
    setSearchValue,
    actionsBelow = setSearchValue ? <></> : null,
    searchPlaceholder = 'Найти на странице...',
    template,
    isTitleLoading,
    tag = null,
    actionUnderBelow,
    titleVariant,
    hideTitlePersonalData,
    ...props
  }: PageHeaderProps) => {
    const handlerBack = () =>
      // если в истории браузера это не первая страница, то идем назад по истории, иначе вниз по роутингу
      routerService.navigate(globalThis.history.state.idx ? -1 : '..');

    const calculatedTemplate = useTemplate({
      template,
      title,
      actionsBelow,
      hasBack,
      children,
      tag,
    });

    useMetaTitleService({ title });

    return (
      <PageHeaderWrapper
        template={calculatedTemplate}
        rowGap={Boolean(subtitle) ? 2 : 6}
        {...props}
      >
        {hasBack && (
          <PageHeaderBackBtn variant="light" onClick={handlerBack}>
            <ArrowLOutlineMd />
          </PageHeaderBackBtn>
        )}
        {tag ? (
          <PageHeaderWithTagWrapper>
            <PageHeaderTitle
              text={title}
              isLoading={isTitleLoading}
              variant={titleVariant}
              hidePersonalData={hideTitlePersonalData}
            />
            <PageHeaderTagWrapper>{tag}</PageHeaderTagWrapper>
          </PageHeaderWithTagWrapper>
        ) : (
          <PageHeaderTitle
            text={title}
            isLoading={isTitleLoading}
            variant={titleVariant}
            hidePersonalData={hideTitlePersonalData}
          />
        )}
        {subtitle && <PageHeaderSubtitle>{subtitle}</PageHeaderSubtitle>}
        {actionsUnder && (
          <PageHeaderActionsUnder>{actionsUnder}</PageHeaderActionsUnder>
        )}
        {(actionsBelow || setSearchValue) && (
          <PageHeaderActionsBelow>
            {setSearchValue && (
              <SearchField
                value={searchValue}
                placeholder={searchPlaceholder}
                onSetValue={setSearchValue}
              />
            )}

            {actionsBelow}
          </PageHeaderActionsBelow>
        )}
        {actionUnderBelow && (
          <PageHeaderactionUnderBelow>
            {actionUnderBelow}
          </PageHeaderactionUnderBelow>
        )}
        {children && (
          <PageHeaderChildrenWrapper>{children}</PageHeaderChildrenWrapper>
        )}
      </PageHeaderWrapper>
    );
  },
);
