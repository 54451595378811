import { getExtensionByFileName } from '../getExtensionByFileName';

export const getWebFormatByFileName = (
  fileName: string,
  mapOfExtensions: { [key: string]: string },
): string | null => {
  const ext = getExtensionByFileName(fileName) as
    | keyof typeof mapOfExtensions
    | null;

  if (!ext) {
    return null;
  }

  if (!mapOfExtensions[ext]) {
    return null;
  }

  return mapOfExtensions[ext];
};
