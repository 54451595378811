import { FormTextArea, type FormTextAreaProps } from '@astral/ui';
import { classnames } from '@utils/string/classnames';
import { METRIKA_CLASSES } from '@constants/metrikaClasses';

type FormTextareaProps<TFieldValues extends object> =
  FormTextAreaProps<TFieldValues> & {
    hidePersonalData?: boolean;
  };

export const FormTextarea = <TFieldValues extends object>({
  hidePersonalData,
  ...props
}: FormTextareaProps<TFieldValues>) => (
  <FormTextArea
    {...props}
    inputProps={{
      className: classnames({
        [METRIKA_CLASSES.hideInputValue]: hidePersonalData,
      }),
      ...props.inputProps,
    }}
  />
);
