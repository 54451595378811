import { type FullSizeNotificationItem } from '@services';
import { observer } from 'mobx-react-lite';

import { Placeholder } from '../../Placeholder';
import { NotificationItemWrapper } from '../NotificationItemWrapper/NotificationItemWrapper';

import { TitleWrapper } from './styles';

type NotificationItemProps = {
  item: FullSizeNotificationItem;
};

export const NotificationItem = observer(({ item }: NotificationItemProps) => (
  <NotificationItemWrapper
    isActive={item.active.isActive}
    onEntered={item.onOpened}
    onExited={item.onRemove}
    onClick={item.onClose}
  >
    <Placeholder
      title={<TitleWrapper>{item.props.title}</TitleWrapper>}
      {...item.props}
    />
  </NotificationItemWrapper>
));
