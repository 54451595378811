import { type OverflowedTypographyProps as UIOverflowTypographyProps } from '@astral/ui';
import { forwardRef } from 'react';
import { classnames } from '@utils/string/classnames';

import { METRIKA_CLASSES } from '../../constants/metrikaClasses';
import { type TypographyPropsCustom } from '../Typography';
import { type TooltipProps } from '../Tooltip';

import { OverflowTypographyWrapper } from './styles';

export type OverflowedTypographyProps = TypographyPropsCustom &
  UIOverflowTypographyProps;

export const OverflowTypography = forwardRef<
  HTMLElement,
  OverflowedTypographyProps
>(({ hidePersonalData, ...props }, ref) => (
  <OverflowTypographyWrapper
    {...props}
    ref={ref}
    //@ts-ignore где то проблема с типизацией в emotion
    component={props.component}
    className={classnames(props.className, {
      [METRIKA_CLASSES.blurContent]: hidePersonalData,
    })}
    tooltipProps={{
      ...(props.tooltipProps as TooltipProps),
      className: classnames({
        [METRIKA_CLASSES.blurContent]: hidePersonalData,
      }),
    }}
  />
));
