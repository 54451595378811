import { Button } from '../../Button';
import { DialogActions } from '../../DialogActions';
import { type AlertModalFooterTemplateProps } from '../AlertModalDialogFooter/types';

export const AlertModalGroupTemplate = (
  props: AlertModalFooterTemplateProps,
) => {
  const { confirmButtonProps, cancelButtonProps } = props;

  return (
    <DialogActions data-test="DialogGroupTemplateActions">
      <>
        {cancelButtonProps && (
          <Button variant="text" {...cancelButtonProps}>
            {cancelButtonProps.text}
          </Button>
        )}
        {confirmButtonProps && (
          <Button {...confirmButtonProps}>{confirmButtonProps.text}</Button>
        )}
      </>
    </DialogActions>
  );
};
