type TimeUpdaterParams = {
  /**
   * временной промежуток в мс, раз в который будет вызываться обновление
   */
  timeToUpdate: number;
  /**
   * метод для апдейта
   */
  update: () => void;
  /**
   * флаг, отображающий необходимость запуска update при инициализации
   */
  isFirstUpdateWithInit?: boolean;
  /**
   *  время в мс через которое попытки будут прекращены
   */
  destroyTime?: number;
  /**
   * колбэк на срабатывание автодестроя
   */
  onAutoDestroy?: () => void;
};

/**
 * сущность для поллинга по времени, либо по возвращению пользователя
 */
export class TimeUpdater {
  private timer?: number;

  private autodestroyTimer?: number;

  constructor(private readonly params: TimeUpdaterParams) {
    this.init();
    this.initAutoDestroy();
  }

  private initAutoDestroy = () => {
    if (this.params.destroyTime) {
      const destroyTime = Date.now() + this.params.destroyTime;

      // делаем через интервал, с расчетом на то, что пользователь может переключаться между вкладками,
      // а браузер замедляет время для неактивных вкладок
      this.autodestroyTimer = (globalThis as unknown as Window).setInterval(
        () => {
          const currentTime = Date.now();

          if (destroyTime <= currentTime) {
            this.params.onAutoDestroy?.();
            this.destroy();
          }
        },
        500,
      );
    }
  };

  /**
   * обработчик активации вкладки с приложением
   */
  public handleVisibleState = () => {
    this.params.update();
    this.setTimer();
  };

  /**
   * обработчик деактивации вкладки с приложением
   */
  public handleHiddenState = () => {
    this.clearTimer();
  };

  private onVisibilityChange = () => {
    if (globalThis.document?.visibilityState === 'visible') {
      this.handleVisibleState();
    } else {
      this.handleHiddenState();
    }
  };

  private init = () => {
    if (this.params.isFirstUpdateWithInit) {
      this.params.update();
    }

    this.setTimer();

    globalThis.document?.addEventListener(
      'visibilitychange',
      this.onVisibilityChange,
    );
  };

  private setTimer = () => {
    this.timer = (globalThis as unknown as Window).setInterval(
      this.params.update,
      this.params.timeToUpdate,
    );
  };

  private clearTimer = () => {
    clearInterval(this.timer);
    clearInterval(this.autodestroyTimer);
  };

  public destroy = () => {
    this.clearTimer();

    globalThis.document?.removeEventListener(
      'visibilitychange',
      this.onVisibilityChange,
    );
  };
}
