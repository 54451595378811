import { type ChangeEvent, useCallback, useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useDebounce } from 'react-use';
import { TextField, type TextFieldProps } from '@astral/ui';

import { FilterContext } from '../FilterContext';
import { type Filters } from '../../stores/Filters';

export type FilterTextFieldProps = {
  fieldName: string;
} & Omit<TextFieldProps, 'onChange' | 'defaultValue' | 'value' | 'ref'>;

export const FilterTextField = observer(
  ({ fieldName, ...props }: FilterTextFieldProps) => {
    const { filters, apply } = useContext(FilterContext) as Filters<{
      [key: string]: string;
    }>;

    const [immediateValue, setImmediateValue] = useState(filters[fieldName]);

    const handleSelect: TextFieldProps['onChange'] = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        setImmediateValue(e.target.value);
      },
      [],
    );

    useDebounce(() => apply({ [fieldName]: immediateValue }), 250, [
      immediateValue,
    ]);

    return (
      <TextField
        name={fieldName}
        defaultValue={filters[fieldName]}
        onChange={handleSelect}
        {...props}
      />
    );
  },
);
