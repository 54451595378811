export enum OptionType {
  element,
  group,
}

export type MultipleAutocompleteOptionId = string & {
  _type?: 'MultipleAutocompleteOptionId';
};

export type MultipleAutocompleteOption = {
  id: MultipleAutocompleteOptionId;
  disabled?: boolean;
  title: string;
};

export type GroupedMultipleAutocompleteOption = MultipleAutocompleteOption & {
  subtitle?: string;
  groupId?: string;
  groupTitle?: string;
  intent?: number;
  type?: OptionType;
  children?: GroupedMultipleAutocompleteOption[];
  parent?: GroupedMultipleAutocompleteOption;
};

export type TreelikeMultipleAutocompleteOption = MultipleAutocompleteOption & {
  subtitle?: string;
  children?: TreelikeMultipleAutocompleteOption[];
};

export type TreelikeMultipleAutocompleteRecord = Record<
  MultipleAutocompleteOptionId,
  TreelikeMultipleAutocompleteOption[]
>;

export type TreelikeMultipleAutocompleteData = {
  record: TreelikeMultipleAutocompleteRecord;
  options: TreelikeMultipleAutocompleteOption[];
};
