import { styled } from '../../styled';

export const Wrapper = styled('div')`
  height: 100%;
  position: relative;
  z-index: 0;
`;

export const DropIndicator = styled('div')<{ $isActive?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(6, 8)};
  position: absolute;
  z-index: 1;
  left: 10px;
  top: 10px;
  pointer-events: none;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  border: 2px dashed ${({ theme }) => theme.palette.primary[800]};
  background-color: ${({ theme }) => theme.palette.grey[100]};
  opacity: ${({ $isActive }) => ($isActive ? '0.6' : '0')};
  visibility: ${({ $isActive }) => ($isActive ? 'visible' : 'hidden')};
  transition:
    opacity 0.3s linear,
    visibility 0.3s linear;
  text-align: center;
`;
