import { styled } from '../styled';

export const Connector = styled('div')<{
  $color: 'success' | 'warning' | 'error' | 'grey';
}>`
  width: 2px;
  background-color: ${({ theme, $color }) =>
    theme.palette[$color][$color === 'grey' ? 300 : 800]};
  height: 100%;
  justify-self: center;
  border-radius: 1px;
  display: block;
`;

export const DotIconContainer = styled.div`
  position: relative;
  grid-area: icon;
  justify-self: center;
  padding-top: ${({ theme }) => theme.spacing(0.5)};
  align-self: flex-start;

  & > svg {
    width: 12px;
    height: 12px;
    display: block;
  }

  &:empty:after {
    content: '';
    display: block;
    background-color: ${({ theme }) => theme.palette.grey[300]};
    border-radius: 50%;
    width: 12px;
    height: 12px;
  }
`;

export const TimelineItemWrapper = styled('li')`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas: 'icon content' 'connector content';
  grid-row-gap: ${({ theme }) => theme.spacing(2.5)};
  grid-column-gap: ${({ theme }) => theme.spacing(3.5)};
`;

export const TimelineContent = styled('article')`
  grid-area: content;
`;
