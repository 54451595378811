import { zeroPad } from '@utils/string/zeroPad';
import { MINUTES_PER_HOUR } from '@services/DateService/constants';

/**
 * получаем текстовое смещение таймзоны в формате чч:мм:cc либо -чч:мм:cc
 */
export const getCurrentTimezoneOffset = () => {
  // getTimezoneOffset возвращает отрицательное значение
  const offsetInMinutes = new Date().getTimezoneOffset() * -1;

  // т.к. смещение может быть с неровным колличеством часов, например в индии смещение 5часов и 30минут
  // получаем остаточное значение минут
  const minutes = offsetInMinutes % MINUTES_PER_HOUR;

  // часы без учета минут
  const hours = Math.floor((offsetInMinutes - minutes) / MINUTES_PER_HOUR);

  return `${zeroPad(hours, 2)}:${zeroPad(minutes, 2)}:00`;
};
