import { PaginationWrapper } from './styles';

type TablePaginationProps = {
  count: number;
  page: number;
  loading: boolean;
  onChangePage: (page: number) => void;
};

export function TablePagination({
  count,
  page,
  onChangePage,
  loading,
}: TablePaginationProps) {
  const handleChangePage = (event: unknown, newPage: number) => {
    onChangePage(newPage);
  };

  return (
    <PaginationWrapper
      count={count}
      shape="rounded"
      page={page}
      disabled={loading}
      onChange={handleChangePage}
    />
  );
}
