import { DateService } from '../../../services/DateService';
import { type DateType } from '../../../services/DateService/utils/types';

export const FORM_ERROR_MESSAGE = {
  REQUIRED: 'Обязательное поле',
  EMAIL: 'Некорректный Email',
  PHONE: 'Некорректный телефон',
  SNILS: 'Некорректный СНИЛС',
  INN: 'Некорректный ИНН',
  OGRN: 'Некорректный ОГРН',
  KPP: 'Некорректный КПП',
  NUMBER: 'Поле должно быть числовым',
  INCORRECT: 'Некорректное поле',
  DATE: 'Некорректная дата',
  DATE_MIN: (date: DateType) =>
    `Не может быть меньше ${DateService.toShortReadableByLocal(
      new Date(date),
    )}`,
  DATE_MAX: (date: DateType) =>
    `Не может быть больше ${DateService.toShortReadableByLocal(
      new Date(date),
    )}`,
  ISSUER_SUBDIVISION_CODE: 'Некорректный код подразделения',
  PASSPORT_SERIAL: 'Некорректная серия паспорта',
  PASSPORT_NUMBER: 'Некорректный номер паспорта',
  MIN_LENGTH: (len: number) => `Минимальная длина ${len}`,
  MAX_LENGTH: (len: number) => `Максимальная длина ${len}`,
  MIN: (value: number) => `Минимальное значение ${value}`,
  MAX: (value: number) => `Максимальное значение ${value}`,
  EQUAL: (value: number) => `Значение должно быть равно ${value}`,
  NOT_EQUAL: (value: number) => `Значение должно быть не равно ${value}`,
  SHOULD_BE_CIRILIC: 'Необходимо использовать кириллицу',
  SIGNATORY_REQUIRED: 'Выберите подписанта',
  SIGNATURE_REQUIRED: 'Выберите подпись',
  MAX_SIGNATURE_MEMBERS: 'Количество участников не должно превышать 256',
  DATE_INVALID_FORMAT: 'Некорректная дата',
  WRONG_CODE_TEXT: 'Код подтверждения не верный или просрочен',
  ACCEPTANCE_AGREEMENT: 'Дата указана неверно',
  MIN_NUMBER_OF_ACTIVE_LICENSES:
    'Количество лицензий не может быть меньше количества уже активированных лицензий',
  FILE_REQUIRED: 'Необходимо добавить файл',
  MAX_FILE_NUMBER: (value: number) =>
    `Максимально возможное количество файлов ${value}`,
  GUID: 'Некорректный номер',
  DIFFERENT: 'Значение не должно совпадать',
};
