import { styled } from '../styled';

export const MainWrapper = styled('article')`
  box-sizing: border-box;
  width: 100%;
  height: 100%;

  ${({ theme }) => theme.breakpoints.up('md')} {
    overflow: auto;
    max-height: 100%;
  }
`;

export const MainInner = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing(8)};
  align-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  grid-template-rows: 1fr;
`;

export const FormsFlowASide = styled('aside')`
  padding: ${({ theme }) => theme.spacing(3)};
  position: sticky;
  bottom: 0;
  background-color: ${({ theme }) => theme.palette.background.paper};
  border-top: 1px solid ${({ theme }) => theme.palette.grey[300]};

  ${({ theme }) => theme.breakpoints.up('md')} {
    border: none;
    position: initial;
    width: min-content;
    padding: ${({ theme }) => theme.spacing(6)};
  }

  & > button {
    ${({ theme }) => theme.breakpoints.down('md')} {
      max-width: 100%;
      width: 100%;
      min-width: initial;
    }
  }

  & > button h6 {
    width: max-content;
  }

  & .MuiCollapse-root {
    margin-top: 0;

    transition: margin-top 0.3s ease;
  }

  & .MuiCollapse-root.MuiCollapse-entered,
  & > *:last-of-type:not(button) {
    margin-top: ${({ theme }) => theme.spacing(2)};
  }
`;
