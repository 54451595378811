export const generateBlobUrl = (
  blob: Blob,
): { url: string; revoke: () => void } => {
  const url = URL.createObjectURL(blob);
  /**
   * URL.createObjectURL не собирается сборщиком мусора, поэтому надо руками производить revoke
   * */
  const revoke = () => {
    URL.revokeObjectURL(url);
  };

  return { url, revoke };
};
