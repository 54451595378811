/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DocumentTypeDto } from '../models/DocumentTypeDto';

import type { CancelablePromise } from '@common/data/api-core/CancelablePromise';
import type { BaseHttpRequest } from '@common/data/api-core/BaseHttpRequest';

export class DocumentTypesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Получение списка типов документов
   * @returns DocumentTypeDto Коллекция типов документов
   * @throws ApiError
   */
  public documentTypesGetDocumentTypes({
    findText,
    tenantId,
    offset,
    count,
    employeeworkplaceid,
    kedoGatewayTokenType,
  }: {
    findText?: string | null;
    tenantId?: string | null;
    offset?: number;
    count?: number;
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
  }): CancelablePromise<Array<DocumentTypeDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/templates/DocumentTypes',
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
      query: {
        FindText: findText,
        TenantId: tenantId,
        Offset: offset,
        Count: count,
      },
    });
  }
}
