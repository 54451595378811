import { type ActionTableCell } from './types';

export const ACTION_CELL_NAME: ActionTableCell = '$action';

export const DEFAULT_ROW_HEIGHT = 44;

export const DEFAULT_SELECT_KEY = 'Id';

export const DEFAULT_RESIZER_KEY = 'ResizeId';

export const baseTableI18n = {
  noDataTitle: 'Нет данных',
  loading: 'Загрузка данных...',
};

export const DEFAULT_PLACEHOLDER_SIZE = 'medium';

export const VIRTUOSO_SCROLLER_CLASSNAME = 'virtuosoScroller';
