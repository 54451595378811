import { makeAutoObservable, runInAction, when } from 'mobx';

import {
  type ApiError,
  type AxiosClient,
  type JwtTokens,
  type MutationQuery,
  appPaths,
  axiosClient,
  routerService,
  searchParamsCreator,
  tokenParse,
  transactionOutbox,
} from '@dashboard/shared';
import { identityRepository } from '@dashboard/data';

import {
  type UpdateTokensOptions,
  getAccessToken,
  getRefreshToken,
  updateTokens,
} from './utils';

type Query = MutationQuery<JwtTokens, ApiError, { refreshToken?: string }>;

type AuthStoreParams = {
  query: Query;
  onLogout?: () => void;
  getAccess: () => string | undefined;
  getRefresh: () => string | undefined;
  updateTokens: (data: UpdateTokensOptions) => void;
  axiosClient: AxiosClient;
};

/**
 * @description стор обеспечивающий работу с jwt токентами в рамках дашборда
 * ответственный за хранение состояния логина и за обновление access через рефреш токен
 */
export class AuthStore {
  private params: AuthStoreParams;

  private refreshPromise?: Promise<{}>;

  public isLoggedIn: boolean = false;

  public userData: {} | null = null;

  private requestConfig: {
    axiosInterceptor: number;
    instanceInterceptor: number;
  } | null = null;

  private responseConfig: {
    axiosInterceptor: number;
    instanceInterceptor: number;
  } | null = null;

  constructor(params: AuthStoreParams) {
    this.params = params;
    this.checkData();
    this.setupInterceptors();
    makeAutoObservable(this);
  }

  private get interceptors() {
    return this.params.axiosClient.interceptors;
  }

  private setupInterceptors = () => {
    // перехватчик ответственный за ретрай, при истекших токенах
    this.responseConfig = this.interceptors.onResponse(
      (r) => r,
      (error) => {
        if (error !== null && error.response) {
          const code = (error.response.data as { code: string })?.code;

          if (
            code === 'access_token_error_decode' &&
            this.params.getRefresh()
          ) {
            return this.refresh().then(() =>
              axiosClient.instance({ ...error.config, ...{ retry: true } }),
            );
          }
        }

        return Promise.reject(error);
      },
    );

    // перехватчик ответственный за обогащение запросы заголовками с авторизацией
    // при необходимости, паузит запрос до момента, пока пользователь на залогинится
    this.requestConfig = this.interceptors.onRequest(
      async (config) => {
        if (config?.url?.match('identity/Esa')) {
          return config;
        }

        const hasAccessToken = Boolean(this.params.getAccess());
        const hasRefreshToken = Boolean(this.params.getRefresh());

        if (!hasAccessToken && hasRefreshToken) {
          await this.refresh();
        }

        if (!hasAccessToken && !hasRefreshToken) {
          runInAction(this.setLogout);
          await when(() => this.isLoggedIn);
        }

        if (this.params.getAccess()) {
          runInAction(() => {
            this.isLoggedIn = true;
            this.userData = tokenParse(this.params.getAccess() || '');
          });
        }

        config.headers = config.headers || {};
        config.headers['kedo-gateway-token-type'] = 'AdminApi';
        config.headers.Authorization = `Bearer ${this.params.getAccess()}`;

        return config;
      },
      (r) => {
        throw r;
      },
    );
  };

  private onLogin = (tokens: JwtTokens) => {
    this.params.updateTokens(tokens);
    this.checkData();

    return true;
  };

  /**
   * @description метод с проверкой на залогиненность и соответствующими реакциями
   */
  private checkData = () => {
    const initialUserData = this.params.getAccess();

    this.isLoggedIn = Boolean(initialUserData);

    if (initialUserData) {
      this.userData = tokenParse(initialUserData);
    } else {
      transactionOutbox.subscribe('login', this.onLogin);
    }

    if (!this.isLoggedIn && !routerService.pathname.match(appPaths.auth.base)) {
      const hasRefresh = this.params.getRefresh();

      if (hasRefresh) {
        this.refresh();
      } else {
        this.navigateToAuthPage();
      }
    }
  };

  /**
   * @description метод для редиркта пользователя на форму логина
   */
  private navigateToAuthPage = () => {
    const pathname = routerService.pathname;

    routerService.navigate(appPaths.auth.base, {
      search: searchParamsCreator({
        redirectBack: pathname ? `${pathname}${location.search}` : '',
      }),
    });
  };

  /**
   * @description метод логаута пользователя
   */
  private setLogout = () => {
    this.isLoggedIn = false;
    this.userData = null;
    this.navigateToAuthPage();
    this.params.onLogout?.();
  };

  /**
   * @description метод для обновления токенов
   */
  private refresh = (): Promise<{}> => {
    if (!Boolean(this.refreshPromise)) {
      this.refreshPromise = this.params.query
        .async({
          refreshToken: this.params.getRefresh(),
        })
        .then((res: JwtTokens) => {
          this.params.updateTokens(res);

          return true;
        })
        .catch((e) => {
          runInAction(this.setLogout);

          return Promise.reject(e);
        })
        .finally(() => {
          runInAction(() => {
            this.refreshPromise = undefined;
          });
        });
    }

    return this.refreshPromise!;
  };

  public destroy = () => {
    if (this.requestConfig) {
      this.interceptors.ejectRequestInterceptors(this.requestConfig);
    }

    if (this.responseConfig) {
      this.interceptors.ejectResponseInterceptors(this.responseConfig);
    }
  };
}

export const authStore = new AuthStore({
  query: identityRepository.getRefreshQuery(),
  getAccess: getAccessToken,
  getRefresh: getRefreshToken,
  updateTokens,
  axiosClient,
});
