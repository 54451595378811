import { styled } from '../../styled';
import { TableCell, type TableCellProps } from '../../TableCell';

const getWidthCss = ({ index }: TableCellProps): string =>
  (typeof index === 'number' &&
    `calc((var(--table-width) - var(--table-checkbox-width, 0px)) * var(--table-col-width-${index}))`) ||
  '';

export const TableCellWrapper = styled(
  (props: TableCellProps) => <TableCell {...props} />,
  {
    shouldForwardProp: (prop) => !prop.match(/pointer|disabled|index|width/),
  },
)<{ pointer?: boolean; disabled?: boolean }>`
  width: ${(props) => getWidthCss(props)};
  max-width: ${(props) => getWidthCss(props)};
  min-width: ${(props) => getWidthCss(props)};
  padding: ${({ theme }) => theme.spacing(1, 4)};

  &:first-of-type {
    padding-left: ${({ theme }) => theme.spacing(6)};
  }

  &:last-of-type {
    padding-right: ${({ theme }) => theme.spacing(6)};
  }

  cursor: ${({ pointer }) => (pointer ? 'pointer' : 'default')};

  .MuiTableHead-root & {
    border-bottom: 2px solid ${({ theme }) => theme.palette.grey[300]};
  }

  & .MuiChip-root {
    cursor: ${({ pointer }) => (pointer ? 'pointer' : '')};
  }

  ${({ theme, disabled }) =>
    disabled ? 'color: ' + theme.palette.grey[400] : ''};
`;

export const TableCheckBoxCell = styled(TableCellWrapper)`
  position: relative;

  overflow: hidden;

  width: var(--table-checkbox-width, 0);
  min-width: var(--table-checkbox-width, 0);
  max-width: var(--table-checkbox-width, 0);
  padding: 0 !important;

  &::before {
    display: block;
  }

  .MuiCheckbox-root {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-30%, -50%);
  }
`;

export const TableCellInner = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isAlignRight',
})<{ isAlignRight?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ isAlignRight }) => (isAlignRight ? 'flex-end' : '')};

  height: 100%;
`;
