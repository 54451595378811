import { TextField, styled } from '@astral/ui';

export const SingleAutocompleteTextFieldWrapper = styled(TextField)`
  max-width: 100%;

  .MuiInputBase-root {
    display: flex;
    justify-content: space-between;

    max-width: 100%;
    padding-right: 0;
  }

  .MuiInputBase-input {
    flex-grow: 10;

    width: auto;
  }
`;
