import { IconButton, styled } from '@astral/ui';

export const TreelikeList = styled.ul`
  display: block;

  width: min-content;
  min-width: 100%;
  margin: 0;
  padding: 0;

  list-style: none;
`;

export const TreelikeListItem = styled('li', {
  shouldForwardProp: (prop) => prop !== 'indent',
})<{ indent: number }>`
  --indent: ${({ theme, indent }) => theme.spacing(indent * 5 + 3)};
  --child-line: block;
  --left-border: calc(var(--indent) - 6px);
  position: relative;

  display: block;

  margin: 0;
  padding: 0;

  list-style: none;

  .MuiCollapse-root & {
    --corner-height: 16px;
    --corner-width: 10px;
  }

  .MuiCollapse-root &:first-of-type {
    --corner-height: 16px;
  }

  &:last-of-type {
    --child-line: none;
  }

  &::after {
    pointer-events: none;
    content: '';

    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    transform: translateX(calc(var(--left-border)));

    display: var(--child-line, none);

    width: 0;
    height: 100%;

    border-color: ${({ theme }) => theme.palette.grey[400]};
    border-style: solid;
    border-width: 0 0 1px 1px;
  }
`;

export const TreelikeLabel = styled.label`
  cursor: pointer;

  display: flex;
  flex-grow: 1;
  align-items: center;

  max-width: 100%;
  min-height: 30px;
  padding: ${({ theme }) => theme.spacing(0.5, 3, 0.5, 0)};

  &:nth-child(1) {
    padding-left: ${({ theme }) => theme.spacing(2.5)};
  }

  &::before {
    pointer-events: none;
    content: '';

    position: absolute;
    z-index: 1;
    top: 0;
    left: var(--left-border);

    display: block;

    width: var(--corner-width, 0);
    height: var(--corner-height, 0);

    border-color: ${({ theme }) => theme.palette.grey[400]};
    border-style: solid;
    border-width: 0 0 1px 1px;
    border-radius: 0 0 0 5px;
  }

  .MuiCheckbox-root {
    margin-right: ${({ theme }) => theme.spacing(1)};
  }
`;

export const TreelikeCollapseButton = styled(IconButton)`
  width: 30px;
  max-width: 30px;
  height: 30px;
  max-height: 30px;
  padding: 0;
`;

export const TreelikeItemWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isMatch',
})<{ isMatch: boolean }>`
  padding: 0 0 0 var(--indent);

  background-color: ${({ theme, isMatch }) =>
    isMatch && theme.palette.grey[100]};

  transition: background-color 0.2s linear;

  &:hover {
    background-color: ${({ theme }) => theme.palette.background.elementHover};
  }
`;

export const TreelikeItemInner = styled('div')`
  display: flex;
  align-items: center;
`;
