import { type MouseEvent, type ReactNode, forwardRef } from 'react';
import { debounce } from '@utils/debounce';

import { Badge, type BadgeProps } from '../Badge';
import { type ButtonProps } from '../Button';
import { IconButton } from '../IconButton';
import { CircularProgress } from '../CircularProgress';
import { type TooltipProps } from '../Tooltip';
import { TooltipSwitcher } from '../TooltipSwitcher';

export type ActionButtonProps = Omit<ButtonProps, 'component'> & {
  tooltipContent?: NonNullable<ReactNode>;
  icon?: JSX.Element;
  disableInteractiveInTooltip?: boolean;
  isLoading?: boolean;
  delay?: number;
  tooltipProps?: Partial<TooltipProps>;
  badgeProps?: Partial<BadgeProps>;
  /**
   * если не установлено конкретное значение, компонент будет самостоятельно пытаться вычислить необходимость тултипа
   */
  isTooltipActive?: boolean;
};

const DEFAULT_DELAY = 200;

export const ActionButton = forwardRef<HTMLButtonElement, ActionButtonProps>(
  (
    {
      tooltipContent,
      icon,
      isLoading,
      disabled,
      disableInteractiveInTooltip = true,
      onClick,
      delay = DEFAULT_DELAY,
      tooltipProps,
      badgeProps,
      variant,
      isTooltipActive,
      ...props
    },
    ref,
  ) => {
    const handleClick = debounce(
      (event: MouseEvent<HTMLButtonElement>) => onClick?.(event),
      delay,
    );

    return (
      <Badge
        color={badgeProps?.color || 'error'}
        badgeContent={badgeProps?.badgeContent}
      >
        <TooltipSwitcher
          isActive={isTooltipActive ?? (!disabled && Boolean(tooltipContent))}
          title={tooltipContent}
          disableInteractive={disableInteractiveInTooltip}
          {...tooltipProps}
        >
          <IconButton
            ref={ref}
            color="primary"
            variant={variant || 'text'}
            {...props}
            disabled={disabled}
            onClick={handleClick}
          >
            {isLoading ? <CircularProgress size="small" /> : icon}
          </IconButton>
        </TooltipSwitcher>
      </Badge>
    );
  },
);
