import { MicroserviceRepository } from '@common/data';
import type { GetBaseUrlQuery } from '@common/data/api-core';

import {
  type ApiError,
  type CacheService,
  type InviteAdminData,
  cacheService,
} from '@dashboard/shared';

import { RecruitmentClient } from '../../sources/client/recruitment';
import { type BaseApiUrlConfig, configFetcher } from '../configFetcher';

import { inviteLinkKey } from './constants';
import { type GetAdminLinkQueryParams } from './types';

type RecruitmentRepositoryParams = {
  getConfigQuery: GetBaseUrlQuery<BaseApiUrlConfig>;
  cacheService: CacheService<ApiError>;
};

export class RecruitmentRepository extends MicroserviceRepository<
  RecruitmentClient,
  BaseApiUrlConfig
> {
  private cacheService: CacheService<ApiError>;

  constructor(params: RecruitmentRepositoryParams) {
    super({
      getConfigQuery: params.getConfigQuery,
      microserviceClient: RecruitmentClient,
    });

    this.cacheService = params.cacheService;
  }

  public getInviteAdminQuery = () =>
    this.cacheService.createMutation((params: InviteAdminData) =>
      this.client.recruitment.recruitmentSendLink({
        requestBody: {
          Email: params.Email,
        },
        tenantId: params.TenantId,
      }),
    );

  public getAdminLinkQuery = (params: GetAdminLinkQueryParams) =>
    this.cacheService.createQuery([inviteLinkKey(params.tenantId)], () =>
      this.client.recruitment.recruitmentGetActiveLinkForAutoApprovedForm({
        tenantId: params.tenantId,
      }),
    );
}

export const recruitmentRepository = new RecruitmentRepository({
  cacheService,
  getConfigQuery: configFetcher.config.create,
});
