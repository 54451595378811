export const DOCUMENT_TEMPLATES_KEY = (tenantId?: string) => [
  'document_templates',
  tenantId,
];

export const DOCUMENT_TEMPLATES_HISTORY_KEY = (templateId?: string) => [
  'document_template_history',
  templateId,
];

export const DOCUMENT_TEMPLATE_TAGS_KEY = (templateId?: string) => [
  'document_template_tags',
  templateId,
];

export const documentTemplateDocumentTypesKey = (tenantId?: string) => [
  'document_template_document_types_key',
  tenantId,
];
