import { type PlaceholderImageProps } from '@astral/ui';

const symbolSvgRegexp = /\.svg#main/;

export const PlaceholderImage = (props: PlaceholderImageProps) => {
  const isSvgSymbol = Boolean(props.src?.match(symbolSvgRegexp));

  if (isSvgSymbol) {
    return (
      <svg
        width={props.width}
        height={props.height}
        className={props.className}
      >
        <use
          //необходимо для старого safari
          xlinkHref={props.src}
          href={props.src}
        />
      </svg>
    );
  }

  return (
    <img
      alt={props.alt}
      src={props.src}
      width={props.width}
      height={props.height}
      className={props.className}
    />
  );
};
