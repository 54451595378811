import { styled } from '../../styled';

export const FormElement = styled.form`
  position: relative;

  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
`;
