import { type ConfirmStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { useRef } from 'react';

import { AlertModal } from '../AlertModal';
import { Grid } from '../Grid';
import { Typography } from '../Typography';
import { Button, type ButtonProps } from '../Button';

type ConfirmModalProps = {
  store: ConfirmStore;
  submitColor?: ButtonProps['color'];
};

export const ConfirmModal = observer(
  ({ store, submitColor }: ConfirmModalProps) => {
    const ref = useRef<HTMLButtonElement>(null);

    const handleTransitionEnd = () => {
      if (store.isActive && ref.current) {
        ref.current.focus();
      }
    };

    return (
      <AlertModal
        title={store?.title}
        open={store.isActive}
        onClose={store?.cancel}
        onTransitionEnd={handleTransitionEnd}
      >
        <Grid spacing={6}>
          <Typography>{store?.description}</Typography>
          <Grid
            columns="auto auto"
            justifyItems="flex-end"
            justifyContent="flex-end"
            spacing={2}
          >
            <Button onClick={store?.cancel} variant="text">
              {store?.cancelText}
            </Button>
            <Button
              ref={ref}
              onClick={() => store?.submit()}
              color={submitColor}
            >
              {store?.submitText}
            </Button>
          </Grid>
        </Grid>
      </AlertModal>
    );
  },
);
