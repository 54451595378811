import {
  TableCell as MuiTableCell,
  type TableCellProps as MuiTableCellProps,
} from '@mui/material';

export type TableCellProps = MuiTableCellProps & {
  index?: number;
};

export const TableCell = MuiTableCell;
