/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from '@common/data/api-core/BaseHttpRequest';
import type { OpenAPIConfig } from '@common/data/api-core/OpenAPI';
import { AxiosHttpRequest } from '@common/data/api-core/AxiosHttpRequest';

import { EmployeeWorkplacesService } from './services/EmployeeWorkplacesService';
import { ExternalDirectoriesInfoService } from './services/ExternalDirectoriesInfoService';
import { TenantsService } from './services/TenantsService';
import { TenantSettingsContainersService } from './services/TenantSettingsContainersService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class AdministrativeClient {
  public readonly employeeWorkplaces: EmployeeWorkplacesService;
  public readonly externalDirectoriesInfo: ExternalDirectoriesInfoService;
  public readonly tenants: TenantsService;
  public readonly tenantSettingsContainers: TenantSettingsContainersService;

  public readonly request: BaseHttpRequest;

  constructor(
    config?: Partial<OpenAPIConfig>,
    HttpRequest: HttpRequestConstructor = AxiosHttpRequest,
  ) {
    this.request = new HttpRequest({
      BASE: config?.BASE ?? '',
      VERSION: config?.VERSION ?? '2.0.0',
      WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
      CREDENTIALS: config?.CREDENTIALS ?? 'include',
      TOKEN: config?.TOKEN,
      USERNAME: config?.USERNAME,
      PASSWORD: config?.PASSWORD,
      HEADERS: config?.HEADERS,
      ENCODE_PATH: config?.ENCODE_PATH,
    });

    this.employeeWorkplaces = new EmployeeWorkplacesService(this.request);
    this.externalDirectoriesInfo = new ExternalDirectoriesInfoService(
      this.request,
    );
    this.tenants = new TenantsService(this.request);
    this.tenantSettingsContainers = new TenantSettingsContainersService(
      this.request,
    );
  }
}
