import { styled } from '../styled';

type PageContainerProps = {
  fullWidth?: boolean;
};

export const PageContainer = styled('section', {
  shouldForwardProp: (prop) => !prop.match('fullWidth'),
})<PageContainerProps>`
  grid-column: ${({ fullWidth }) => fullWidth && '1 / span 2'};

  height: 100%;
`;

export const SecondStretchedPageContainer = styled(
  PageContainer,
)<PageContainerProps>`
  display: grid;
  grid-template-rows: auto 1fr auto;
`;
