import {
  type ConfigProviderProps,
  ConfigProvider as UIConfigProvider,
} from '@astral/ui';
import { techSupportContacts } from '@constants/techSupportContacts';
import {
  ErrorIllustration,
  NoResultsIllustration,
  RefreshPageIllustration,
} from '@illustrations';

import { PlaceholderImage } from '../PlaceholderImage';

export const ConfigProvider = (props: ConfigProviderProps) => (
  <UIConfigProvider
    {...props}
    techSup={{
      email: techSupportContacts.email.value,
      phone: techSupportContacts.phone.value,
    }}
    components={{
      placeholder: {
        defaultProps: {
          renderImage: PlaceholderImage,
        },
      },
    }}
    imagesMap={{
      defaultErrorImgSrc: ErrorIllustration,
      noDataImgSrc: NoResultsIllustration,
      outdatedReleaseErrorImgSrc: RefreshPageIllustration,
    }}
  />
);
