import { type RefObject, useEffect, useMemo } from 'react';

type UseResizeObserverOptions = {
  /**
   * колбэк получения нужных размеров
   */
  sizeCb: (entry: ResizeObserverEntry[]) => void;
  /**
   * реф на ноду за которой нужно следить
   */
  ref: RefObject<HTMLElement>;
};

/**
 * абстрактный хук использования ресайз обсервера
 */
export const useResizeObserver = ({
  sizeCb,
  ref,
}: UseResizeObserverOptions) => {
  const resizeObserver = useMemo(() => new ResizeObserver(sizeCb), []);

  useEffect(() => {
    if (ref?.current) {
      const node = ref.current;

      resizeObserver.observe(node);

      return () => resizeObserver.unobserve(node);
    }
  }, [resizeObserver]);
};
