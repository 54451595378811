import Cookie from 'js-cookie';

const COOKIE_KEY = (id: string) => `timer_${id}`;

type SetCookieTimerOptions = {
  /**
   * дата которая будет записана в куки,
   * и эта же дата будет означать момент протухания куки
   */
  date: Date;
  /**
   * айдишка по которой будет записана кука
   */
  id: string;
};

/**
 * метод установки куки с айдишкой таймера и текстом в виде переданной даты
 */
export const setCookieTimer = ({ date, id }: SetCookieTimerOptions) => {
  Cookie.set(COOKIE_KEY(id), date.toISOString(), {
    expires: date,
  });
};

/**
 * метод получения значения таймера из куки по айдишке
 */
export const getCookieTimer = (id: string) => Cookie.get(COOKIE_KEY(id));
