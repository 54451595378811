import { useId } from 'react';

import { SPINNER_ELEMENTS_COUNT, SPINNER_SIZES } from './constants';
import { SpinnerDot, SpinnerWrapper } from './styles';
import { type SpinnerSizes, type SpinnerType } from './types';

type SpinnerProps = {
  size?: SpinnerSizes;
  type?: SpinnerType;
  className?: string;
};

const dots = Array.from(
  { length: SPINNER_ELEMENTS_COUNT },
  (_, index) => index,
);

export const Spinner = ({
  size = 'sm',
  type = 'dark',
  ...props
}: SpinnerProps) => {
  const id = useId();

  return (
    <SpinnerWrapper $size={SPINNER_SIZES[size]} {...props}>
      {dots.map((index) => (
        <SpinnerDot key={id + index} number={index} type={type} />
      ))}
    </SpinnerWrapper>
  );
};
