import { useMemo } from 'react';
import { prepareToSearchable } from '@utils/string/prepareToSearchable';
import { useDebouncedInputValue } from '@hooks/useDebouncedInputValue';

import {
  type GroupedMultipleAutocompleteOption,
  OptionType,
} from '../../../types';

type UseSortedItemsByInputValue = {
  options: GroupedMultipleAutocompleteOption[];
  /**
   * метод отвечающий за кастомный поиск элементов, если передан, то поиска на фронте не будет
   */
  onSearch?: (value: string) => void;
  isAllGroup?: boolean;
};

export const useSortedItemsByInputValue = ({
  options,
  onSearch,
  isAllGroup,
}: UseSortedItemsByInputValue) => {
  const { inputValue, inputRef, clearInputValue, handleInputChange } =
    useDebouncedInputValue({
      transform: onSearch ? (s) => s : prepareToSearchable,
      debounceTime: onSearch ? 200 : 100,
      onSearch,
    });

  const optionsByInput = useMemo(
    () =>
      // если передан onSearch, то считаем что метод поиска передан на бэк
      !onSearch && inputValue
        ? options.filter(({ title, groupTitle }) =>
            prepareToSearchable(title + groupTitle).match(inputValue),
          )
        : options,
    [inputValue, onSearch, options],
  );

  const { groups, items } = useMemo(() => {
    const data: Record<string, GroupedMultipleAutocompleteOption[]> = {};

    optionsByInput.forEach((option) => {
      const id = String(option?.groupId);

      if (!data[id]) {
        data[id] = [];
      }

      data[id].push(option);
    });

    // если передан onSearch, считаем, что сортировка на бэке
    if (onSearch) {
      return { groups: data, items: options };
    }

    const sortedItems: GroupedMultipleAutocompleteOption[] = Object.keys(data)
      .map((key) => data[key].sort((a, b) => (a.title > b.title ? 1 : -1)))
      .flat();

    return { groups: data, items: sortedItems };
  }, [optionsByInput]);

  /**
   * плоский список элементов, отсортированный по группам,
   * чтобы имитировать древовидную структуру
   */
  const sortedByGroupItems = useMemo(() => {
    const flatList: GroupedMultipleAutocompleteOption[] = [];

    Object.keys(groups)
      .sort((a, b) =>
        groups[a][0].groupTitle! > groups[b][0].groupTitle! ? 1 : -1,
      )
      .forEach((key) => {
        const itemsInGroup = groups[key];
        const firstItem = itemsInGroup[0];

        const groupHead: GroupedMultipleAutocompleteOption = {
          id: firstItem.groupId!,
          title: firstItem.groupTitle!,
          type: OptionType.group,
          children: itemsInGroup,
        };

        if (isAllGroup && groupHead.id === '0') {
          flatList.push(groupHead);
        }

        itemsInGroup.forEach((item) => flatList.push(item));
      });

    return flatList;
  }, [groups, items, onSearch]);

  return {
    groups,
    sortedByGroupItems,
    handleInputChange,
    clearInputValue,
    inputRef,
  };
};
