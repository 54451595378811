import { makeAutoObservable } from 'mobx';

export class MetaTitleService {
  private titles: Map<string, number> = new Map();

  public description = '';

  constructor() {
    makeAutoObservable(this);
  }

  public get title() {
    const titles = [...this.titles.keys()].sort(
      (a, b) => this.titles.get(a)! - this.titles.get(b)!,
    );

    return titles.join(' | ');
  }

  public addSubtitle = (subTitle: string, order?: number) => {
    if (!this.titles.has(subTitle)) {
      this.titles.set(subTitle, order ?? this.titles.size);
    }
  };

  public removeSubTitle = (value: string) => {
    this.titles.delete(value);
  };

  public setDescription = (value: string) => {
    this.description = value;
  };

  public cleanDescription = () => {
    this.description = '';
  };
}

export const metaTitleService = new MetaTitleService();
