import { type PropsWithChildren } from 'react';

import { FormFooterWrapper } from './styles';

export type FormFooterProps = {
  showBorder?: boolean;
};

export const FormFooter = ({
  children,
  showBorder = true,
}: PropsWithChildren<FormFooterProps>) => {
  return (
    <FormFooterWrapper showBorder={showBorder}>{children}</FormFooterWrapper>
  );
};
