import { MenuItem } from '../MenuItem';
import { FormControlLabel as UIFormControlLabel } from '../FormControlLabel';
import { styled } from '../styled';

export const GroupMenuItem = styled(MenuItem, {
  shouldForwardProp: (prop) => !prop.match(/intent|selected/),
})<{ intent?: number }>`
  padding: ${({ intent = 0, theme }) => theme.spacing(0, 0, 0, 2 * intent)};
  background-color: ${({ theme, selected }) =>
    selected ? theme.palette.primary[100] : ''};
`;

export const AutocompleteListItemContent = styled(UIFormControlLabel)`
  align-items: flex-start;
  width: 100%;
  margin-left: 0;
  padding: 0;

  & > span {
    margin-left: 0 !important;
  }
`;

export const AutocompleteListItemLabelGroupWrapper = styled('div', {
  shouldForwardProp: (prop) => !prop.match(/width|intent/),
})<{
  width: number;
  intent?: number;
}>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  // 52 это ширина и отступы чекбокса
  width: ${({ width, theme, intent = 0 }) =>
    width ? `calc(${width - 52}px - ${theme.spacing(2 * intent)})` : '100%'};
`;
