/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TenantSettingsContainerDto } from '../models/TenantSettingsContainerDto';
import type { TenantSettingsDto } from '../models/TenantSettingsDto';
import type { UpdateTenantSettingContainerDto } from '../models/UpdateTenantSettingContainerDto';

import type { CancelablePromise } from '@common/data/api-core/CancelablePromise';
import type { BaseHttpRequest } from '@common/data/api-core/BaseHttpRequest';

export class TenantSettingsContainersService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Получение списка контейнеров настроек тенантов
   * @returns TenantSettingsContainerDto
   * @throws ApiError
   */
  public tenantSettingsContainersGetTenantSettingsContainersList({
    offset,
    count = 50,
    employeeworkplaceid,
    kedoGatewayTokenType,
  }: {
    offset?: number;
    count?: number;
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении. Требуется только для Web приложения **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
  }): CancelablePromise<Array<TenantSettingsContainerDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/administrative/TenantSettingsContainers',
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
      query: {
        offset: offset,
        count: count,
      },
    });
  }

  /**
   * Получение настроек тенанта по идентификатору
   * @returns TenantSettingsDto
   * @throws ApiError
   */
  public tenantSettingsContainersGetTenantSettingsById({
    tenantId,
    employeeworkplaceid,
    kedoGatewayTokenType,
  }: {
    /** Идентификатор тенанта **/
    tenantId: string;
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении. Требуется только для Web приложения **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
  }): CancelablePromise<TenantSettingsDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/administrative/TenantSettingsContainers/{tenantId}',
      path: {
        tenantId: tenantId,
      },
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
    });
  }

  /**
   * Обновление настроек тенанта
   * @returns any
   * @throws ApiError
   */
  public tenantSettingsContainersUpdateTenantSettingsContainer({
    tenantId,
    requestBody,
    employeeworkplaceid,
    kedoGatewayTokenType,
  }: {
    /** Идентификатор тенанта **/
    tenantId: string;
    /** Настройки тенанта **/
    requestBody: UpdateTenantSettingContainerDto;
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении. Требуется только для Web приложения **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v2/administrative/TenantSettingsContainers/{tenantId}',
      path: {
        tenantId: tenantId,
      },
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
