import { styled } from '../styled';

import { MessagesList } from './MessagesList';

export const Container = styled('div')`
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
`;

export const MessagesListWrapper = styled(MessagesList)`
  padding: ${({ theme }) => theme.spacing(4)};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: ${({ theme }) => theme.spacing(8)};
  }
`;
