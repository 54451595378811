import { useState } from 'react';

type UseToggleReturn = [boolean, () => void, () => void];

/**
 * @return  [value, handlerOn, handlerOff];
 * */
export function useToggle(initialValue: boolean): UseToggleReturn {
  const [value, setValue] = useState(initialValue);

  const handlerOn = () => {
    setValue(true);
  };
  const handlerOff = () => {
    setValue(false);
  };

  return [value, handlerOn, handlerOff];
}
