import { type Theme } from '../Theme';
import { styled } from '../styled';

import { SPINNER_ELEMENTS_COUNT } from './constants';
import { type SpinnerType } from './types';

type SpinnerWrapperProps = {
  $size: string;
};

export const SpinnerWrapper = styled('div')<SpinnerWrapperProps>`
  --size: ${({ $size }) => $size};
  position: relative;

  min-width: var(--size);
  max-width: var(--size);
  min-height: var(--size);
  max-height: var(--size);
  margin-right: auto;
  margin-left: auto;
`;

type SpinnerDotProps = {
  number: number;
  type: SpinnerType;
};

const getColor = ({ type, theme }: { type: SpinnerType; theme: Theme }) => {
  if (type === 'dark') {
    return theme.palette.primary[900];
  }

  if (type === 'pale') {
    return theme.palette.primary[300];
  }

  return theme.palette.background.element;
};

export const SpinnerDot = styled('div', {
  shouldForwardProp: (prop) => !prop.match(/number|type/),
})<SpinnerDotProps>`
  --dot-number: ${({ number }) => number};
  --dot-size: 22.1%;
  --rotate: calc(var(--dot-number) * ${360 / SPINNER_ELEMENTS_COUNT}deg);
  position: absolute;
  top: calc(39%);
  left: 0;
  transform-origin: calc(var(--size) / 2) 50%;
  transform: rotateZ(var(--rotate));

  width: var(--dot-size);
  height: var(--dot-size);

  &::before {
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    transform: scale(0.1);

    display: block;

    width: 100%;
    height: 100%;

    background-color: ${({ theme, type }) => getColor({ theme, type })};
    border-radius: 50%;
    box-shadow: 0 calc(var(--size) * 0.038) calc(var(--size) * 0.13)
      ${({ theme }) => theme.palette.grey[200]};

    animation: sizeChange 1s ease infinite;
    animation-delay: calc(var(--dot-number) * ${1 / SPINNER_ELEMENTS_COUNT}s);

    @keyframes sizeChange {
      0% {
        transform: scale(0.1);
      }

      45% {
        transform: scale(1);
      }

      55% {
        transform: scale(1);
      }

      95% {
        transform: scale(0.1);
      }
    }
  }
`;
