import { Select as SelectUI, styled } from '@astral/ui';

export const SelectWrapper = styled(SelectUI, {
  shouldForwardProp: (prop) => prop !== 'hasValue',
})<{ hasValue: boolean }>`
  & .MuiSelect-select {
    color: ${({ hasValue, theme }) =>
      hasValue ? null : theme.palette.grey[600]};
  }
`;
