import { styled } from '../../../styled';

import { type PageContentProps } from './types';

export const GeneralWrapper = styled.div<PageContentProps>`
  overflow-y: auto;
  flex: 1;

  height: 100%;
  padding: ${({ theme, withoutPadding }) =>
    withoutPadding ? 0 : theme.spacing(0, 6, 6, 6)};
`;
