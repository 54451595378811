import { TextField, styled } from '@astral/ui';

export const MultipleAutocompleteTextFieldWrapper = styled(TextField)`
  max-width: 100%;

  .MuiButtonBase-root {
    flex-shrink: 1;

    max-width: 30%;

    &:first-of-type:last-of-type {
      max-width: 60%;
    }

    &:not(:first-of-type) {
      margin-left: ${({ theme }) => theme.spacing(2)};
    }
  }

  .MuiInputBase-root {
    display: flex;
    justify-content: space-between;

    max-width: 100%;
    padding-right: 0;
  }

  .MuiInputBase-input {
    flex-grow: 10;

    width: auto;
  }
`;
