import { isDate } from '../isDate';
import { type DateType } from '../types';
import { getCurrentTimezoneOffset } from '../getCurrentTimezoneOffset';

const YEAR_OPTIONS: Intl.DateTimeFormatOptions = {
  year: 'numeric',
};

const MONTH_DAY_OPTIONS: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: '2-digit',
};

const DATE_OPTIONS: Intl.DateTimeFormatOptions = {
  ...MONTH_DAY_OPTIONS,
  ...YEAR_OPTIONS,
};

const TIME_OPTIONS: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
};

const MONTH_DAY_TIME_OPTIONS: Intl.DateTimeFormatOptions = {
  ...MONTH_DAY_OPTIONS,
  ...TIME_OPTIONS,
};

const SHORT_TIME_OPTIONS: Intl.DateTimeFormatOptions = {
  minute: '2-digit',
  second: '2-digit',
};

const UTC_OPTION: Intl.DateTimeFormatOptions = {
  timeZone: 'UTC',
};

const LOCALE = 'ru';

const formatDateToMonthDayTimeViewByLocal = Intl.DateTimeFormat(
  LOCALE,
  MONTH_DAY_TIME_OPTIONS,
).format;

const formatDateToConvenientByLocal = Intl.DateTimeFormat(LOCALE, {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
}).format;

const formatDateToShortTimeViewByLocal = Intl.DateTimeFormat(
  LOCALE,
  SHORT_TIME_OPTIONS,
).format;

const formatDateToShortTimeViewByUTC = Intl.DateTimeFormat(LOCALE, {
  ...SHORT_TIME_OPTIONS,
  ...UTC_OPTION,
}).format;

const formatDateToTimeViewByLocal = Intl.DateTimeFormat(
  LOCALE,
  TIME_OPTIONS,
).format;

const formatDateToMonthDayByUTC = Intl.DateTimeFormat(LOCALE, {
  ...MONTH_DAY_OPTIONS,
  ...UTC_OPTION,
}).format;

const formatDateToTimeViewByUTC = Intl.DateTimeFormat(LOCALE, {
  ...TIME_OPTIONS,
  ...UTC_OPTION,
}).format;

const formatDateToShortViewByLocal = Intl.DateTimeFormat(
  LOCALE,
  DATE_OPTIONS,
).format;

const formatDateToShortViewByUTC = Intl.DateTimeFormat(LOCALE, {
  ...DATE_OPTIONS,
  ...UTC_OPTION,
}).format;

const formatDateToFullViewByLocal = Intl.DateTimeFormat(LOCALE, {
  ...DATE_OPTIONS,
  ...TIME_OPTIONS,
}).format;

const formatDateToFullWithSecondsViewByLocal = Intl.DateTimeFormat(LOCALE, {
  ...DATE_OPTIONS,
  ...TIME_OPTIONS,
  second: '2-digit',
}).format;

const formatDateToFullViewByUTC = Intl.DateTimeFormat(LOCALE, {
  ...DATE_OPTIONS,
  ...TIME_OPTIONS,
  ...UTC_OPTION,
}).format;

export enum FormatVariants {
  /**
   * текстовая дата относительно локальной даты пользователя, в формате mm:ss
   */
  shortTimeLocal,
  /**
   * текстовая дата относительно UTC, в формате mm:ss
   */
  shortTimeUTC,
  /**
   * текстовая дата относительно локальной даты пользователя, в формате hh:mm
   */
  timeLocal,
  /**
   * текстовая дата относительно UTC, в формате hh:mm
   */
  timeUtc,
  /**
   * укороченная дата относительно локальной даты пользователя, без штампа времени, в формате ДД:ММ:ГГГГ
   */
  shortLocal,
  /**
   * укороченная дата относительно UTC, без штампа времени
   */
  shortUTC,
  /**
   * полная текстовая дата относительно локальной даты пользователя, в формате ДД:ММ:ГГГГ ММ:СС
   */
  fullLocal,
  /**
   * полная текстовая дата с штампом времени относительно локальной даты пользователя, в формате ДД:ММ:ГГГГ чч:мм
   */
  fullLocalWithTimeStamp,
  /**
   * полная текстовая дата с указанием секунд
   */
  fullLocalWithSeconds,
  /**
   * полная текстовая дата с штампом времени относительно UTC, в формате ДД:ММ:ГГГГ чч:мм
   */
  fullUTC,
  /**
   * текстовая дата в формате ДД:ММ, чч:мм
   */
  monthDayTimeViewByLocal,
  /**
   * текстовая дата в формате ГГГГ месяц ДД, чч:мм:сс
   */
  convenientByLocal,
  /**
   * текстовая дата в формате ММ.ДД относительно UTC
   */
  monthDayByUTC,
}

const variantMap: Record<FormatVariants, (date?: Date | number) => string> = {
  [FormatVariants.shortTimeLocal]: formatDateToShortTimeViewByLocal,
  [FormatVariants.shortTimeUTC]: formatDateToShortTimeViewByUTC,
  [FormatVariants.timeLocal]: formatDateToTimeViewByLocal,
  [FormatVariants.timeUtc]: formatDateToTimeViewByUTC,
  [FormatVariants.shortLocal]: formatDateToShortViewByLocal,
  [FormatVariants.shortUTC]: formatDateToShortViewByUTC,
  [FormatVariants.fullLocal]: formatDateToFullViewByLocal,
  [FormatVariants.fullLocalWithSeconds]: formatDateToFullWithSecondsViewByLocal,
  [FormatVariants.fullLocalWithTimeStamp]: formatDateToFullViewByLocal,
  [FormatVariants.fullUTC]: formatDateToFullViewByUTC,
  [FormatVariants.monthDayTimeViewByLocal]: formatDateToMonthDayTimeViewByLocal,
  [FormatVariants.convenientByLocal]: formatDateToConvenientByLocal,
  [FormatVariants.monthDayByUTC]: formatDateToMonthDayByUTC,
};

type DateFormat = (date: DateType, variant?: FormatVariants) => string;

const isoDateRegExp = new RegExp(/\dT\d.*[^Z]$/);

const checkISODateWithoutZ = (date: string | number) =>
  typeof date === 'string' && isoDateRegExp.test(date);

const optimizeDateString = (date: string | number): Date =>
  new Date(checkISODateWithoutZ(date) ? `${date}Z` : date);

export const dateFormat: DateFormat = (
  date,
  variant = FormatVariants.shortUTC,
): string => {
  if (!Boolean(date)) {
    return '';
  }

  const optimizedDate = date instanceof Date ? date : optimizeDateString(date);

  if (!isDate(optimizedDate)) {
    return '';
  }

  const res = variantMap[variant]?.(optimizedDate) ?? '';

  if (variant === FormatVariants.fullLocalWithTimeStamp) {
    const timeOffset = getCurrentTimezoneOffset()
      // добавляем плюс
      .replace(/^(?=\d)/, '+')
      // убираем двойные нолики секунд и минут
      .replaceAll(/:00/g, '')
      // убираем нолик часов
      .replace(/0(?=\d)/, '');

    return `${res} (UTC${timeOffset})`;
  }

  return res;
};
