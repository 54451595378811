import { OverflowTypography as UIOverflowTypography, styled } from '@astral/ui';

import { type TypographyPropsCustom } from '../Typography';

export const OverflowTypographyWrapper = styled(UIOverflowTypography, {
  shouldForwardProp: (prop) =>
    !prop.match(/marginTop|marginBottom|textAlign|marginLeft|marginRight/),
})<TypographyPropsCustom>`
  margin: ${({ theme, marginTop }) =>
      (typeof marginTop === 'number' && theme.spacing(marginTop)) || marginTop}
    ${({ theme, marginRight }) =>
      (typeof marginRight === 'number' && theme.spacing(marginRight)) ||
      marginRight}
    ${({ theme, marginBottom }) =>
      (typeof marginBottom === 'number' && theme.spacing(marginBottom)) ||
      marginBottom}
    ${({ theme, marginLeft }) =>
      (typeof marginLeft === 'number' && theme.spacing(marginLeft)) ||
      marginLeft};

  text-align: ${({ textAlign }) => textAlign};
`;
