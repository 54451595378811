import { type PropsWithChildren } from 'react';

import { FormWrapperElement } from './styles';

export type FormWrapperProps = {
  width?: string;
};

export const FormWrapper = ({
  children,
  width = 'auto',
}: PropsWithChildren<FormWrapperProps>) => {
  return <FormWrapperElement width={width}>{children}</FormWrapperElement>;
};
