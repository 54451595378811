import { type DateType } from '../types';
import { FormatVariants, dateFormat } from '../dateFormat';

/**
 * утилита получения даты, меняющей свой вид в зависимости от текущей даты.
 * если у переданной даты не будет совпадать ни год, ни месяц, ни день, то формат ДД:ММ:ГГГГ чч:мм
 * если у переданной даты будет совпадать год с текущей датой, то формат ДД:ММ, чч:мм
 * если у переданный даты будет совпадать и год и месяц и день, то формат чч:мм
 */
export const smartLocalDateFormat = (date: DateType) => {
  const targetDate = new Date(date);
  const currentDate = new Date();

  const isSameYear = targetDate.getFullYear() === currentDate.getFullYear();
  const isSameMonth = targetDate.getMonth() === currentDate.getMonth();
  const isSameDay = targetDate.getDate() === currentDate.getDate();

  if (isSameYear && isSameMonth && isSameDay) {
    return dateFormat(date, FormatVariants.timeLocal);
  }

  if (isSameYear) {
    return dateFormat(date, FormatVariants.monthDayTimeViewByLocal);
  }

  return dateFormat(date, FormatVariants.fullLocal);
};
