import { Tag as KitTag, type TagProps as KitTagProps } from '../../Tag';

export const MultipleAutocompleteTag = ({ label, ...props }: KitTagProps) => (
  <KitTag
    skipFocusWhenDisabled={false}
    //@ts-ignore component валидный атрибут
    component="article"
    variant="light"
    color="grey"
    label={label}
    {...props}
  />
);
