import { objectKeys } from '../../object';

export const searchParamsCreator = (params: Record<string, string>) => {
  const urlParams = new URLSearchParams();

  objectKeys(params).forEach((key) => {
    urlParams.set(key, params[key]);
  });

  return urlParams.toString();
};
