import { forwardRef } from 'react';
import { type NavMenuProps } from '@astral/ui';

import {
  CompanyOutlineMd,
  DocumentsOutlineMd,
  PeopleOutlineMd,
  RouterLink,
  type RouterLinkProps,
  appPaths,
} from '@dashboard/shared';

export const MENU = (pathname: string) => {
  const match = (path: string) => Boolean(pathname.match(path));

  return {
    items: [
      [
        appPaths.tenants.base,
        {
          icon: <CompanyOutlineMd />,
          text: 'Организации',
          active: match(appPaths.tenants.base),
          component: forwardRef<HTMLAnchorElement, RouterLinkProps>(
            (props, ref) => (
              <RouterLink ref={ref} {...props} href={appPaths.tenants.base} />
            ),
          ),
        },
      ],
      [
        appPaths.employees.base,
        {
          icon: <PeopleOutlineMd />,
          text: 'Сотрудники',
          active: match(appPaths.employees.base),
          component: forwardRef<HTMLAnchorElement, RouterLinkProps>(
            (props, ref) => (
              <RouterLink ref={ref} {...props} href={appPaths.employees.base} />
            ),
          ),
        },
      ],
      [
        appPaths.documentTemplates.base,
        {
          icon: <DocumentsOutlineMd />,
          text: 'Шаблоны документов',
          active: match(appPaths.documentTemplates.base),
          component: forwardRef<HTMLAnchorElement, RouterLinkProps>(
            (props, ref) => (
              <RouterLink
                ref={ref}
                {...props}
                href={appPaths.documentTemplates.base}
              />
            ),
          ),
        },
      ],
    ] as NavMenuProps['items'],
  };
};
