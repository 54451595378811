import { getiOSVersion } from '@utils/getiOSVersion';

const iOSVersion = getiOSVersion();

export const getWorkerSrc = () => {
  if (iOSVersion > 0 && iOSVersion < 15.4) {
    const url = new URL('./pdf.worker.legacy.js', import.meta.url);

    return url.href;
  }

  const url = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url);

  return url.href;
};
