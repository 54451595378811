import { Avatar as MuiAvatar } from '@mui/material';
import { stringToColor } from '@utils/stringToColor';
import { ONLY_TEXT_AND_NUMBERS } from '@regexp';

import { styled } from '../styled';

export const AvatarWrapper = styled(MuiAvatar, {
  shouldForwardProp: (prop) =>
    !prop.match(/size|name|isPrimaryColor|initials|isLight/),
})<{
  size: string;
  name?: string;
  isPrimaryColor?: boolean;
  isLight?: boolean;
  initials?: string;
}>`
  z-index: 0;

  width: ${({ size }) => size} !important;
  height: ${({ size }) => size} !important;

  background-color: ${({ name, isPrimaryColor, isLight, theme }) =>
    (isPrimaryColor && isLight && theme.palette.primary[100]) ||
    (isPrimaryColor && theme.palette.primary[600]) ||
    stringToColor(name?.replace(ONLY_TEXT_AND_NUMBERS, '') ?? '')};

  @keyframes fade-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  &::before {
    content: '${({ initials }) => initials}';

    position: absolute;
    z-index: -1;

    font-size: inherit;
    color: ${({ theme, isPrimaryColor, isLight }) =>
      isPrimaryColor && isLight
        ? theme.palette.primary[800]
        : theme.palette.background.paper};
  }

  img {
    animation: fade-in 0.1s linear both;
  }
`;
