import { type EmployeeStatus } from '../types';

type StatusRecord = Record<Uncapitalize<EmployeeStatus>, EmployeeStatus>;

export const EMPLOYEE_STATUS: StatusRecord = Object.freeze({
  notActivated: 'NotActivated',
  notWorks: 'NotWorks',
  noUUES: 'NoUUES',
  works: 'Works',
});

export const EMPLOYEE_STATUS_I18N = Object.freeze({
  [EMPLOYEE_STATUS.notActivated]: 'Не активирован',
  [EMPLOYEE_STATUS.notWorks]: 'Не работает',
  [EMPLOYEE_STATUS.noUUES]: 'Нет УНЭП',
  [EMPLOYEE_STATUS.works]: 'Работает',
});
