// TODO: клон утилиты из кита, заменить на переиспользование, когда она будет экспортиться в ките

const addZero = (value: number, length: number) => {
  return value.toString().padStart(length, '0');
};

/**
 * утилита для генерации строковых чисел с заданным количеством символов, если число символов числа меньше необходимого
 * @example  zeroPad(1, 3) -> '001'
 * @param {number} value число которое надо дополнить нулями
 * @param {number} length число обозначающее необходимую длину строки
 */
export const zeroPad = (value: number, length: number) => {
  if (value < 0) {
    return `-${addZero(Math.abs(value), length)}`;
  }

  return addZero(value, length);
};
