import { type PropsWithChildren, type ReactNode, forwardRef } from 'react';

import {
  CardActions,
  CardContainer,
  CardDescription,
  CardHead,
  CardText,
  CardTitle,
  CardWrapper,
} from './styles';

type DescriptionCardArticleProps = PropsWithChildren<{
  beforeTitle?: ReactNode;
  title: string;
  afterTitle?: ReactNode;
  description?: string;
  isActionable?: boolean;
  actionChildren: ReactNode;
  hidePersonalData?: boolean;
  className?: string;
}>;

export const DescriptionCardArticle = forwardRef<
  HTMLDivElement,
  DescriptionCardArticleProps
>(
  (
    {
      title,
      description,
      children,
      isActionable,
      actionChildren,
      hidePersonalData,
      beforeTitle,
      afterTitle,
      className,
    },
    ref,
  ) => (
    <CardWrapper ref={ref} $isActionable={isActionable} className={className}>
      <CardText>
        <CardHead>
          {beforeTitle}
          <CardTitle
            color="grey"
            colorIntensity={description ? '700' : '900'}
            noWrap={Boolean(description)}
            component="h3"
          >
            {title}
            {description && ':'}
          </CardTitle>
          {afterTitle}
        </CardHead>
        {description && (
          <CardDescription
            color="grey"
            colorIntensity="900"
            hidePersonalData={hidePersonalData}
          >
            {description}
          </CardDescription>
        )}
      </CardText>
      <CardActions>{actionChildren}</CardActions>

      {children && <CardContainer>{children}</CardContainer>}
    </CardWrapper>
  ),
);
