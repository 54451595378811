import { Typography as UITypography } from '@astral/ui';

import { styled } from '../styled';

import { type TypographyPropsCustom } from './types';

export const TypographyWrapper = styled(UITypography, {
  shouldForwardProp: (prop) =>
    !prop.match(/marginTop|marginBottom|textAlign|marginLeft|marginRight/),
})<TypographyPropsCustom>`
  margin: ${({
    theme,
    marginTop = 0,
    marginBottom = 0,
    marginRight = 0,
    marginLeft = 0,
  }) => theme.spacing(marginTop, marginRight, marginBottom, marginLeft)};

  text-align: ${({ textAlign }) => textAlign};
`;
