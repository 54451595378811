import { useCallback, useState } from 'react';
import { throttle } from '@utils/throttle';

const DEFAULT_THROTTLE_TIME = 100;

type UseThrottledSize<T> = {
  /**
   * колбэк получения нужного значения,
   * будет вызван сразу для вычисления, значения по умолчанию
   */
  sizeCb: () => T;
  /**
   * настраиваемое значение времени тротлинга
   */
  throttleTime?: number;
};

/**
 * утилитарный хук запоминающий какое-то вычисляемое значение,
 * и предоставляющей тротлед метод для его обновления
 */
export const useThrottledValue = <T>({
  sizeCb,
  throttleTime = DEFAULT_THROTTLE_TIME,
}: UseThrottledSize<T>): [T, () => void] => {
  const [value, setValue] = useState(sizeCb());

  const handleUpdate = useCallback(
    throttle(() => {
      setValue(sizeCb());
    }, throttleTime),
    [sizeCb],
  );

  return [value, handleUpdate];
};
