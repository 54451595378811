import {
  type ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { lodashDebounce } from '@utils/lodash/debounce';
import { prepareToSearchable } from '@utils/string/prepareToSearchable';

const DEFAULT_DEBOUNCE_TIME = 100;

type UseDebouncedInputValueParams = {
  transform?: (value: string) => string;
  debounceTime?: number;
  onSearch?: (value: string) => void;
  initialInputValue?: string;
};

export const useDebouncedInputValue = ({
  transform = prepareToSearchable,
  debounceTime = DEFAULT_DEBOUNCE_TIME,
  onSearch,
  initialInputValue = '',
}: UseDebouncedInputValueParams) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState(initialInputValue);

  const handleInputChange = useCallback(
    lodashDebounce(
      (e: ChangeEvent<HTMLInputElement>) => {
        const value = transform(e.target.value);

        setInputValue(value);
        onSearch?.(value);
      },
      { waitMs: debounceTime },
    ).call,
    [],
  );

  const clearInputValue = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }

    setInputValue('');
  }, [inputRef]);

  const handleSetInputValue = useCallback((value: string) => {
    if (inputRef.current) {
      inputRef.current.value = value;
    }

    setInputValue(value);
  }, []);

  useEffect(() => {
    // эффект для установки полученного инит значения
    if (initialInputValue && inputRef.current) {
      handleSetInputValue(initialInputValue);
    }

    setInputValue(initialInputValue);
  }, [initialInputValue]);

  return {
    inputRef,
    inputValue,
    handleInputChange,
    clearInputValue,
    setInputValue: handleSetInputValue,
  };
};
