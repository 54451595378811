import { observer } from 'mobx-react-lite';

import { ContentLoader, type WithJSXChildren } from '@dashboard/shared';

import { authStore, configService } from '../../external';
import { CommonLayout } from '../CommonLayout';
import { AuthLayout } from '../AuthLayout';

export const LayoutSwitcher = observer(({ children }: WithJSXChildren) => {
  if (!configService.isSuccess) {
    return <ContentLoader />;
  }

  if (authStore.isLoggedIn) {
    return <CommonLayout>{children}</CommonLayout>;
  }

  return <AuthLayout>{children}</AuthLayout>;
});
