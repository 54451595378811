import { Virtuoso } from '../../Virtuoso';
import { styled } from '../../styled';

const DEFAULT_HEIGHT = 54;

const SMALL_HEIGHT = 32;

const getVirtuosoWrapperHeight = (itemsLength: number, isSmallItem = false) => {
  const itemHeight = isSmallItem ? SMALL_HEIGHT : DEFAULT_HEIGHT;

  if (itemsLength * itemHeight > 340) {
    return '340px';
  }

  return `${itemsLength * itemHeight}px`;
};

export const ListWrapper = styled.ul`
  display: block;

  width: 100%;
  height: 100%;
  min-height: max-content;
  margin: 0;
  padding: 0;

  list-style: none;
`;

export const VirtuosoWrapper = styled(Virtuoso, {
  shouldForwardProp: (prop) =>
    !prop.match(/itemsLength|isAllGroup|isSmallItem/),
})<{ itemsLength: number; isSmallItem?: boolean }>`
  width: 100%;
  height: ${({ itemsLength, isSmallItem }) =>
    getVirtuosoWrapperHeight(itemsLength, isSmallItem)};
  min-height: ${({ itemsLength, isSmallItem }) =>
    getVirtuosoWrapperHeight(itemsLength, isSmallItem)};
`;
