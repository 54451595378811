/**
 * тс утилита, позволяющая нам удостовериться что элемент не фолси,
 * позволяет обойти проблему проверки типов, когда мы полагаемcя на проверку через Boolean
 */
export const checkWithBoolean = <T>(item: T | undefined | null): item is T =>
  Boolean(item);

/* пример проблемы с нативным Boolean
const doSomething = (item?: MyType) => {
  if (Boolean(item)) {
    updateExactlyWithMyType(item); // тут TS будет выдавать ошибку, потому что нативный Boolean не дает защиту типа
  }
};*/
