import {
  type FieldValues,
  type UseControllerProps,
  useController,
} from 'react-hook-form';
import { useBackdropStackToggle } from '@astral/ui';

import {
  GroupedMultipleAutocomplete,
  type GroupedMultipleAutocompleteProps,
} from '../../MultipleAutocomplete';

export type FormGroupedMultipleAutocompleteProps<Values extends FieldValues> =
  Omit<
    GroupedMultipleAutocompleteProps,
    'value' | 'onChange' | 'onOpen' | 'onClose' | 'error' | 'helperText'
  > &
    Omit<UseControllerProps<Values>, 'rules'> & {
      disabled?: boolean;
      isAllGroup?: boolean;
      allGroupName?: string;
      onChange?: (items: string[]) => void;
    };

export function FormGroupedMultipleAutocomplete<Values extends FieldValues>({
  control,
  name,
  defaultValue,
  shouldUnregister,
  onChange,
  ...restProps
}: FormGroupedMultipleAutocompleteProps<Values>) {
  const { handleClose, handleOpen } = useBackdropStackToggle();

  const {
    field: { value, onChange: onChangeForm },
    fieldState,
  } = useController({
    control,
    name,
    defaultValue,
    shouldUnregister,
  });

  const errorMessages = fieldState.error?.message;
  const hasError = Boolean(errorMessages);

  const handleOnChange = (items: string[]) => {
    onChange?.(items);
    onChangeForm(items);
  };

  return (
    <GroupedMultipleAutocomplete
      {...restProps}
      name={name}
      value={value}
      onClose={handleClose}
      onOpen={handleOpen}
      onChange={handleOnChange}
      error={hasError}
      helperText={hasError && errorMessages}
    />
  );
}
