import {
  CodeField as AstralCodeField,
  type CodeFieldProps as AstralCodeFieldProps,
} from '@astral/ui';
import { forwardRef } from 'react';

import { CodeFieldWrapper } from './styles';

export type CodeFieldProps = AstralCodeFieldProps;

export const CodeField = forwardRef<HTMLDivElement, CodeFieldProps>(
  (props, ref) => (
    <CodeFieldWrapper ref={ref}>
      <AstralCodeField {...props} />
    </CodeFieldWrapper>
  ),
);
