import type { Theme } from '@astral/ui';

import { styled } from '../styled';
import { Badge, type BadgeProps } from '../Badge';

const badgeColorToThemeColor = (color: BadgeProps['color'], theme: Theme) => {
  if (color === 'white') {
    return theme.palette.background.paper;
  }

  return theme.palette[color]?.[800];
};

export const Wrapper = styled(Badge)`
  vertical-align: text-top;

  & span.MuiBadge-badge {
    position: static;
    transform: none;

    box-sizing: content-box;
    height: 16px;

    background-color: ${({ color, theme }) =>
      badgeColorToThemeColor(color, theme)};
  }
`;
