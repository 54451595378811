import { makeAutoObservable, when } from 'mobx';

type TransactionBox<TKey = string> = Map<TKey, unknown>;

/**
 * механизм реализующий одноименный паттерн
 */
export class TransactionOutbox<TKey = string> {
  /**
   * ящик транзакций
   */
  private eventBox: TransactionBox<TKey> = new Map();

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * метод создания транзакции
   */
  public push = (key: TKey, value?: unknown) => {
    this.eventBox.set(key, value);
  };

  /**
   * метод подписки на появление транзцакции
   */
  public subscribe = <TValue>(
    key: TKey,
    cb: (value: TValue) => boolean | void,
  ) => {
    return when(
      () => this.eventBox.has(key),
      () => {
        const res = cb(this.eventBox.get(key) as TValue);

        if (res) {
          this.eventBox.delete(key);
        }
      },
    );
  };

  /**
   * метод подтверждения обработанности транзакции
   */
  public complete = (name: TKey) => {
    this.eventBox.delete(name);
  };
}
