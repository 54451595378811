const DEFAULT_TIME_TO_DESTROY = 100;

type Item = () => void;

type LazyDestroyerParams = {
  timeToDestroy: number;
};

/**
 * сущность помогающая для решения проблемы со StrictMode реакта,
 * когда мы в конструкторе бизнес логики создаем подписки, и хотим отписываться от них на анмаунт приложения,
 * но в дев режиме получаем несвоевременный вызов анмаунта
 */
export class LazyDestroyer {
  private readonly timeToDestroy: number;

  private itemsToDestroy: Set<Item> = new Set();

  private timer?: number = undefined;

  constructor(params?: LazyDestroyerParams) {
    this.timeToDestroy = params?.timeToDestroy ?? DEFAULT_TIME_TO_DESTROY;
  }

  public init = () => {
    if (this.timer) {
      (globalThis as unknown as Window).clearTimeout(this.timer);
      this.timer = undefined;
    }
  };

  public push = (item: Item) => {
    this.itemsToDestroy.add(item);
  };

  private destroyAll = () => {
    this.itemsToDestroy.forEach((value) => value());
  };

  public destroy = () => {
    this.timer = (globalThis as unknown as Window).setTimeout(
      this.destroyAll,
      this.timeToDestroy,
    );
  };
}

export const createLazyDestroyer = () => new LazyDestroyer();
