import { observer } from 'mobx-react-lite';
import { fullSizeNotificationsQueue } from '@services';

import { NotificationItem } from './NotificationItem';

export const FullSizeNotificationContainer = observer(() => (
  <>
    {fullSizeNotificationsQueue.notifications.map((item) =>
      item ? <NotificationItem key={item.id} item={item} /> : null,
    )}
  </>
));
