import { styled } from '../../styled';
import { MuiTableContainer } from '../../external';
import { type TableResizerCols } from '../TableResizer';
import { VIRTUOSO_SCROLLER_CLASSNAME } from '../constants';

type TableContainerWrapperProps = {
  columns: TableResizerCols;
  hasCheckBox: boolean;
  component?: string;
  isCheckboxGapSmall?: boolean;
};

export const TableContainerWrapper = styled(MuiTableContainer, {
  shouldForwardProp: (prop) =>
    !prop.match(/width|columns|hasCheckBox|isCheckboxGapSmall/),
})<TableContainerWrapperProps>`
  --table-width: 100%;
  --table-checkbox-width: ${({ hasCheckBox, isCheckboxGapSmall }) =>
    (hasCheckBox && isCheckboxGapSmall && '30px') ||
    (hasCheckBox && '55px') ||
    ''};
  position: relative;
  overflow-x: auto;

  width: 100%;
  height: 100%;

  border-bottom: 2px solid ${({ theme }) => theme.palette.grey[300]};
  ${({ columns }) =>
    columns.map(
      (width, index) => `--table-col-width-${index}: ${width / 100}; `,
    )}

  .${VIRTUOSO_SCROLLER_CLASSNAME} {
    overflow-x: hidden;
    min-width: 700px !important;
  }
`;
