import { createContext, useContext } from 'react';
import { checkMediaQuery } from '@utils';
import { MOBILE_MEDIA_QUERY, TABLET_MEDIA_QUERY } from '@constants';

type MediaQueryContextData = {
  isMobile: boolean;
  isTablet: boolean;
};

export const MediaQueryContext = createContext<MediaQueryContextData>({
  isMobile: checkMediaQuery(MOBILE_MEDIA_QUERY),
  isTablet: checkMediaQuery(TABLET_MEDIA_QUERY),
});

export const useMediaQueryContext = () => {
  return useContext(MediaQueryContext);
};
