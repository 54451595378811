import {
  type HTMLAttributes,
  type MouseEvent,
  type ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';

import { ListItemIcon } from '../ListItemIcon';
import { Checkbox } from '../Checkbox';
import {
  OverflowTypography,
  type OverflowedTypographyProps,
} from '../OverflowTypography';

import {
  AutocompleteListItemContent,
  AutocompleteListItemLabelGroupWrapper,
  GroupMenuItem,
} from './styles';

type AutocompleteListItemProps = Omit<
  HTMLAttributes<HTMLLIElement>,
  'title'
> & {
  title: ReactNode;
  subtitle?: ReactNode;
  checked?: boolean;
  withoutCheckbox?: boolean;
  isGroup?: boolean;
  disabled?: boolean;
  intent?: number;
  hidePersonalData?: boolean;
};

type AutocompleteListItemLabelGroupProps = {
  width: number | null;
  title: ReactNode;
  titleProps?: Omit<OverflowedTypographyProps, 'component'> & {
    component: 'div' | 'h5' | 'h6';
  };
  subtitle: ReactNode;
  subtitleProps: Omit<OverflowedTypographyProps, 'component'> & {
    component: 'div' | 'h5' | 'h6';
  };
  intent?: number;
  hidePersonalData?: boolean;
};

const AutocompleteListItemLabelGroup = ({
  width,
  title,
  subtitle,
  titleProps,
  subtitleProps,
  intent,
  hidePersonalData = false,
}: AutocompleteListItemLabelGroupProps) => (
  <AutocompleteListItemLabelGroupWrapper width={Number(width)} intent={intent}>
    <OverflowTypography {...titleProps} hidePersonalData={hidePersonalData}>
      {title}
    </OverflowTypography>
    {subtitle && (
      <OverflowTypography
        color="grey"
        colorIntensity="600"
        {...subtitleProps}
        hidePersonalData={hidePersonalData}
      >
        {subtitle}
      </OverflowTypography>
    )}
  </AutocompleteListItemLabelGroupWrapper>
);

type AutocompleteListItemLabelCheckboxProps = {
  checked?: boolean;
};

const AutocompleteListItemLabelCheckbox = ({
  checked,
}: AutocompleteListItemLabelCheckboxProps) => {
  if (checked === undefined) {
    return null;
  }

  return (
    <ListItemIcon>
      <Checkbox checked={checked} />
    </ListItemIcon>
  );
};

type AutocompleteListItemControlProps = {
  checked?: boolean;
  withoutCheckbox?: boolean;
};

export const AutocompleteListItemControl = ({
  checked,
  withoutCheckbox,
}: AutocompleteListItemControlProps) => {
  return withoutCheckbox ? null : (
    <AutocompleteListItemLabelCheckbox checked={checked} />
  );
};

export const AutocompleteListItem = ({
  title,
  subtitle,
  checked,
  withoutCheckbox = false,
  isGroup,
  disabled = false,
  intent,
  hidePersonalData,
  ...restProps
}: AutocompleteListItemProps) => {
  const handleClick = (event: MouseEvent<HTMLLIElement>) => {
    if (!restProps.onClick) {
      return;
    }

    event.preventDefault();
    restProps.onClick(event);
  };
  const [width, setWidth] = useState<number | null>(null);
  const mainRef = useRef(null);

  useEffect(() => {
    // @ts-ignore
    setWidth(mainRef?.current?.clientWidth);
  }, [mainRef]);

  return (
    <GroupMenuItem
      ref={mainRef}
      intent={intent}
      {...restProps}
      onClick={handleClick}
      disabled={disabled}
      selected={checked}
    >
      <AutocompleteListItemContent
        control={
          <AutocompleteListItemControl
            checked={checked}
            withoutCheckbox={withoutCheckbox}
          />
        }
        label={
          <AutocompleteListItemLabelGroup
            width={width}
            intent={intent}
            title={title}
            titleProps={{
              component: isGroup ? 'h5' : 'div',
              variant: isGroup ? 'h6' : 'ui',
            }}
            subtitle={subtitle}
            hidePersonalData={hidePersonalData}
            subtitleProps={{
              component: 'div',
              variant: 'ui',
            }}
          />
        }
        labelPlacement="end"
      />
    </GroupMenuItem>
  );
};
