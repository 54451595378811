import { useId } from 'react';
import { observer } from 'mobx-react-lite';

import { type TableResizerSliders } from './store';
import { InputSlider } from './styles';

type TableResizerProps = {
  sliders: TableResizerSliders;
};

export const TableResizer = observer(
  ({
    sliders: { sliders, resizeHandler, mouseUpHandler },
  }: TableResizerProps) => {
    const id = useId();

    return (
      <div>
        {sliders.map((size, index) => (
          <InputSlider
            key={`${id}_${index}`}
            type="range"
            step="0.1"
            min={0}
            max={100}
            onChange={(e) => resizeHandler(e, index)}
            onMouseUp={mouseUpHandler}
            value={size}
          />
        ))}
      </div>
    );
  },
);
