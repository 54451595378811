import { styled } from '../../styled';

export const ListContainer = styled('div')<{ $filesCount?: number }>`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  container-type: size;
`;

export const List = styled('ul')`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  list-style: none;
  max-width: 1024px;
  width: 100%;
  margin: 0 auto auto;
  padding: 0;
`;
