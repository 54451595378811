import { styled } from '../../styled';

const SLIDER_HEIGHT = 25;

export const InputSlider = styled('input')`
  pointer-events: none;

  position: absolute;
  z-index: ${({ theme }) => theme.zIndex.fab};
  top: 22px;
  left: var(--table-checkbox-width, 0);

  width: calc(var(--table-width) - var(--table-checkbox-width, 0px));
  min-width: calc(var(--table-width) - var(--table-checkbox-width, 0px));
  max-width: calc(var(--table-width) - var(--table-checkbox-width, 0px));
  height: 0;
  margin: 0;

  appearance: none;
  opacity: 0;
  outline: none;

  transition: opacity 0.2s;

  &::-webkit-slider-thumb {
    pointer-events: all;
    cursor: col-resize;

    transform-origin: top;

    width: 10px;
    height: ${SLIDER_HEIGHT}px;

    color: ${({ theme }) => theme.palette.background.default};
    color: ${({ theme }) => theme.palette.grey[200]};

    appearance: none;
    appearance: none;
    background: linear-gradient(
      to right,
      transparent 25%,
      currentColor 25%,
      currentColor 75%,
      transparent 75%
    );

    transition:
      transform 0.1s ease-out,
      color 0.2s linear;

    &:hover {
      color: ${({ theme }) => theme.palette.grey[500]};
    }

    &:active {
      transform: scaleX(0.4)
        scaleY(calc(var(--table-resizer-height) / ${SLIDER_HEIGHT} - 1));

      color: ${({ theme }) => theme.palette.primary.dark};
    }
  }

  &::-moz-range-thumb {
    pointer-events: all;
    cursor: col-resize;

    width: 10px;
    height: ${SLIDER_HEIGHT}px;

    color: ${({ theme }) => theme.palette.grey[200]};

    appearance: none;
    background: linear-gradient(
      to right,
      transparent 25%,
      currentColor 25%,
      currentColor 75%,
      transparent 75%
    );
    border: none;

    transition:
      transform 0.1s ease-out,
      color 0.2s linear;

    &:hover {
      color: ${({ theme }) => theme.palette.grey[500]};
    }

    &:active {
      transform: scaleX(0.4)
        scaleY(calc(var(--table-resizer-height) / ${SLIDER_HEIGHT} - 1));

      color: ${({ theme }) => theme.palette.primary.dark};
    }
  }

  .MuiTableContainer-root:has(thead:hover) & {
    opacity: 1;
  }

  &:hover,
  &:active {
    opacity: 1;
  }
`;
