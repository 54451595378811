import { MSECONDS_PER_DAY } from '../../constants/msecondsPerDay';
import { type DateType } from '../types';

export const getDifferenceInDays = (
  dateOne: DateType,
  dateTwo: DateType,
): number => {
  const dateStart = new Date(dateOne);
  const dateEnd = new Date(dateTwo);

  dateStart.setUTCHours(0, 0, 0, 0);
  dateEnd.setUTCHours(0, 0, 0, 0);

  const differenceInMSec = +dateStart - +dateEnd;

  return Math.floor(differenceInMSec / MSECONDS_PER_DAY);
};
