import { type Certificate, type CryptoStore } from '@astral-private/crypto';
import { makeAutoObservable, runInAction } from 'mobx';

/**
 * сервис для ленивой загрузки CryptoStore,
 * позволяет прилично сократить размер бандла
 */
class CryptoService {
  private internalStore: Promise<CryptoStore> | null = null;

  public store: CryptoStore | null = null;

  public isLoading = true;

  constructor() {
    makeAutoObservable(this);
  }

  public importStore = (): Promise<CryptoStore> => {
    if (!this.internalStore) {
      this.internalStore = import('@astral-private/crypto').then((data) => {
        const store = data.createCryptoStore();

        runInAction(() => {
          this.store = store;
          this.isLoading = false;
        });

        return store;
      });
    }

    return this.internalStore;
  };

  public get init() {
    return this.importStore().then((cryptoStore) =>
      cryptoStore.checkWorkspace().then(() => cryptoStore.getCertificateList()),
    );
  }
}

const cryptoService = new CryptoService();

export {
  type Certificate,
  type CryptoStore,
  type CryptoService,
  cryptoService,
};
