import { type ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { observer } from 'mobx-react-lite';
import { metaTitleService } from '@services/MetaTitleService';

export const MetaTitleContainer = observer((): ReactNode => {
  return createPortal(
    <>
      {metaTitleService.title ? <title>{metaTitleService.title}</title> : null}
      {metaTitleService.description ? (
        <meta name="description" content={metaTitleService.description} />
      ) : null}
    </>,
    globalThis.document?.head,
  );
});
