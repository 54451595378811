import { forwardRef } from 'react';
import { Link, type LinkProps } from 'react-router-dom';

export type RouterLinkProps = Omit<LinkProps, 'to' | 'href'> & {
  href: string;
  replace?: boolean;
};

export const RouterLink = forwardRef<HTMLAnchorElement, RouterLinkProps>(
  (props, ref) => <Link ref={ref} {...props} to={props.href} />,
);
