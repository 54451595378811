/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddEmployeeWorkplaceDto } from '../models/AddEmployeeWorkplaceDto';
import type { EmployeeWorkplaceDto } from '../models/EmployeeWorkplaceDto';
import type { EmployeeWorkplaceShortDto } from '../models/EmployeeWorkplaceShortDto';
import type { GetEmployeeWorkplaceListForEmployeeOrderBy } from '../models/GetEmployeeWorkplaceListForEmployeeOrderBy';
import type { Unit } from '../models/Unit';

import type { CancelablePromise } from '@common/data/api-core/CancelablePromise';
import type { BaseHttpRequest } from '@common/data/api-core/BaseHttpRequest';

export class EmployeeWorkplacesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Получение списка рабочих мест сотрудников
   * @returns EmployeeWorkplaceDto
   * @throws ApiError
   */
  public employeeWorkplacesGetEmployeeWorkplace({
    offset,
    count = 50,
    subdivisionId,
    employeeworkplaceid,
    kedoGatewayTokenType,
  }: {
    offset?: number;
    count?: number;
    subdivisionId?: string | null;
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении. Требуется только для Web приложения **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
  }): CancelablePromise<Array<EmployeeWorkplaceDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/administrative/Employees/EmployeeWorkplaces',
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
      query: {
        offset: offset,
        count: count,
        subdivisionId: subdivisionId,
      },
    });
  }

  /**
   * Получение списка рабочих мест сотрудника с фильтром по тенанту
   * @returns EmployeeWorkplaceShortDto
   * @throws ApiError
   */
  public employeeWorkplacesGetEmployeeWorkplaceForEmployee({
    employeeId,
    findText,
    orderBy,
    isDesc,
    employeeworkplaceid,
    kedoGatewayTokenType,
  }: {
    /** Идентификатор сотрудника **/
    employeeId: string;
    findText?: string | null;
    orderBy?: GetEmployeeWorkplaceListForEmployeeOrderBy | null;
    isDesc?: boolean | null;
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении. Требуется только для Web приложения **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
  }): CancelablePromise<Array<EmployeeWorkplaceShortDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/administrative/Employees/{employeeId}/EmployeeWorkplaces',
      path: {
        employeeId: employeeId,
      },
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
      query: {
        FindText: findText,
        OrderBy: orderBy,
        IsDesc: isDesc,
      },
    });
  }

  /**
   * Добавление нового рабочего места для сотрудника
   * @returns string
   * @throws ApiError
   */
  public employeeWorkplacesAddEmployeeWorkplace({
    employeeId,
    requestBody,
    employeeworkplaceid,
    kedoGatewayTokenType,
  }: {
    employeeId: string;
    requestBody: AddEmployeeWorkplaceDto;
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении. Требуется только для Web приложения **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
  }): CancelablePromise<string | null> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/administrative/Employees/{employeeId}/EmployeeWorkplaces',
      path: {
        employeeId: employeeId,
      },
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Установка даты окончания рабочего места
   * @returns boolean
   * @throws ApiError
   */
  public employeeWorkplacesSetDateWorkEnded({
    employeeWorkplaceId,
    workEnded,
    employeeworkplaceid,
    kedoGatewayTokenType,
  }: {
    employeeWorkplaceId: string;
    workEnded?: string | null;
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении. Требуется только для Web приложения **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
  }): CancelablePromise<boolean> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/administrative/Employees/EmployeeWorkplaces/{employeeWorkplaceId}/SetDateWorkEnded',
      path: {
        employeeWorkplaceId: employeeWorkplaceId,
      },
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
      query: {
        workEnded: workEnded,
      },
    });
  }

  /**
   * Установка основного рабочего места
   * @returns boolean
   * @throws ApiError
   */
  public employeeWorkplacesSetIsMain({
    employeeWorkplaceId,
    isMain,
    employeeworkplaceid,
    kedoGatewayTokenType,
  }: {
    employeeWorkplaceId: string;
    isMain?: boolean;
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении. Требуется только для Web приложения **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
  }): CancelablePromise<boolean> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/administrative/Employees/EmployeeWorkplaces/{employeeWorkplaceId}/SetIsMain',
      path: {
        employeeWorkplaceId: employeeWorkplaceId,
      },
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
      query: {
        isMain: isMain,
      },
    });
  }

  /**
   * @returns Unit
   * @throws ApiError
   */
  public employeeWorkplacesDeleteEmployeeWorkplace({
    employeeWorkplaceId,
    employeeworkplaceid,
    kedoGatewayTokenType,
  }: {
    employeeWorkplaceId: string;
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении. Требуется только для Web приложения **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
  }): CancelablePromise<Unit> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v2/administrative/Employees/EmployeeWorkplaces/{employeeWorkplaceId}',
      path: {
        employeeWorkplaceId: employeeWorkplaceId,
      },
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
    });
  }
}
