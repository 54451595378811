import {
  type PropsWithChildren,
  Suspense,
  createContext,
  lazy,
  useContext,
} from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '@hooks/useStore';
import { createToggleStore } from '@stores';

import { MenuOnOutlineMd } from '../../../Icons';
import { MediaQueryContext } from '../../../MediaQueryContext';

import { Container, Inner, ToggleButton } from './styles';

const SwipeableDrawer = lazy(() => import('../../../SwipeableDrawer'));

export const PageRightContext = createContext<() => void>(() => {});

export const PageRight = observer(({ children }: PropsWithChildren<{}>) => {
  const { isTablet } = useContext(MediaQueryContext);
  const store = useStore(createToggleStore);

  return (
    <Container>
      {isTablet && (
        <ToggleButton
          onClick={store.toggle}
          selected={store.isActive}
          variant="light"
          icon={<MenuOnOutlineMd />}
        />
      )}
      {isTablet ? (
        <Suspense>
          <SwipeableDrawer
            open={store.isActive}
            drawerBleedingTitle=""
            onClose={store.turnOff}
            onOpen={store.turnOff}
            anchor="bottom"
            disableSwipeToOpen
            drawerBleedingHeight={20}
          >
            <PageRightContext.Provider value={store.turnOff}>
              <Inner>{children}</Inner>
            </PageRightContext.Provider>
          </SwipeableDrawer>
        </Suspense>
      ) : (
        <Inner>{children}</Inner>
      )}
    </Container>
  );
});
