import { type PropsWithChildren } from 'react';

import { PageLeftWrapper } from './styles';

type PageLeftProps = {
  padding?: number[];
};

export const PageLeft = ({
  children,
  padding,
}: PropsWithChildren<PageLeftProps>) => (
  <PageLeftWrapper padding={padding}>{children}</PageLeftWrapper>
);
