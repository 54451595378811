import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { routerService } from '@services/RouterService';
import { useLocation } from '@hooks/useLocation';

export const RouterAdapter = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [params, setParams] = useSearchParams();

  useEffect(() => {
    routerService.setParams({
      navigate: (path, _params) =>
        typeof path === 'string'
          ? navigate({ pathname: path, ..._params })
          : navigate(path),
    });
  }, []);

  useEffect(() => {
    routerService.updateSearchParams(params, setParams);
  }, [params, setParams]);

  useEffect(() => {
    routerService.updatePathname(pathname);
  }, [pathname]);

  return null;
};
