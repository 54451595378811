import { type TextFieldProps } from '@astral/ui';
import { type ChangeEvent, type RefObject } from 'react';
import { classnames } from '@utils/string/classnames';
import { METRIKA_CLASSES } from '@constants/metrikaClasses';

import { MULTIPLE_AUTOCOMPLETE_TAGS_COUNT } from '../constants';
import { MultipleAutocompleteEllipsisTag } from '../MultipleAutocompleteEllipsisTag';
import { MultipleAutocompleteLastTwoTags } from '../MultipleAutocompleteLastTwoTags';
import {
  type MultipleAutocompleteOption,
  type MultipleAutocompleteOptionId,
} from '../types';
import { EndAutocompleteAdornment } from '../../EndAutocompleteAdornment';

import { MultipleAutocompleteTextFieldWrapper } from './styles';

export type ExternalMultipleAutocompleteTextFieldProps = Pick<
  TextFieldProps,
  'label' | 'placeholder' | 'name' | 'error' | 'helperText'
>;

type MultipleAutocompleteTextFieldProps =
  ExternalMultipleAutocompleteTextFieldProps & {
    disabled?: boolean;
    openPopper: () => void;
    closePopper: () => void;
    inputRef: RefObject<HTMLInputElement>;
    onInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
    isActive: boolean;
    onClearClick: () => void;
    selectedIds: Set<MultipleAutocompleteOptionId>;
    options: MultipleAutocompleteOption[];
    selected?: MultipleAutocompleteOptionId[];
    onDelete: (item: MultipleAutocompleteOption) => void;
    hidePersonalData?: boolean;
    isCleanAllDisabled?: boolean;
  };

export const MultipleAutocompleteTextField = ({
  disabled,
  openPopper,
  closePopper,
  inputRef,
  onInputChange,
  isActive,
  onClearClick,
  selected,
  selectedIds,
  options,
  onDelete,
  hidePersonalData = false,
  isCleanAllDisabled,
  ...inputProps
}: MultipleAutocompleteTextFieldProps) => (
  <MultipleAutocompleteTextFieldWrapper
    hiddenLabel={!inputProps.label}
    {...inputProps}
    disabled={disabled}
    placeholder={!selected?.length ? inputProps.placeholder : undefined}
    onFocus={openPopper}
    fullWidth
    defaultValue={''}
    onChange={onInputChange}
    inputProps={{
      className: classnames({
        [METRIKA_CLASSES.hideInputValue]: hidePersonalData,
      }),
      ref: inputRef,
      autoComplete: 'off',
    }}
    InputProps={{
      startAdornment: selected?.length ? (
        <>
          {selectedIds.size > MULTIPLE_AUTOCOMPLETE_TAGS_COUNT && (
            <MultipleAutocompleteEllipsisTag
              options={options}
              selected={selected}
              size={selectedIds.size}
              hidePersonalData={hidePersonalData}
            />
          )}
          <MultipleAutocompleteLastTwoTags
            disabled={disabled}
            options={options}
            selected={selected}
            onDelete={onDelete}
            hidePersonalData={hidePersonalData}
          />
        </>
      ) : undefined,
      endAdornment: (
        <EndAutocompleteAdornment
          hasCrossBtn={Boolean(selected?.length) && !isCleanAllDisabled}
          onCrossClick={onClearClick}
          openPopper={openPopper}
          closePopper={closePopper}
          isActive={isActive}
          disabled={disabled}
        />
      ),
    }}
  />
);
