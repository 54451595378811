import { type FormControlLabelProps } from './types';
import { StyledFormControlLabel } from './styled';

export const FormControlLabel = (props: FormControlLabelProps) => (
  <StyledFormControlLabel
    componentsProps={{
      typography: {
        variant: 'caption',
      },
    }}
    {...props}
  />
);
