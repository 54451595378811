import { forwardRef } from 'react';
import { type ButtonProps as UiButtonProps } from '@astral/ui';

import { type RouterLinkProps } from '../RouterLink';

import { ButtonWrapper } from './styles';

export type ButtonProps = UiButtonProps & {
  target?: HTMLAnchorElement['target'];
  href?: RouterLinkProps['href'];
  replace?: boolean;
  download?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ isLoading, isDisabled, ...props }: ButtonProps, ref) => (
    <ButtonWrapper
      disabled={isDisabled}
      loading={isLoading}
      {...props}
      ref={ref}
    />
  ),
);
