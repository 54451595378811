import { type TelegramAPI } from '@types';
import { TG_AUTH_URL } from '@constants/root';

import { ScriptLoader } from '../scriptLoader';

declare global {
  var Telegram: TelegramAPI;
}

export const telegramScriptLoader = new ScriptLoader(
  TG_AUTH_URL,
  () => globalThis.Telegram,
);
