/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from '@common/data/api-core/BaseHttpRequest';
import type { OpenAPIConfig } from '@common/data/api-core/OpenAPI';
import { AxiosHttpRequest } from '@common/data/api-core/AxiosHttpRequest';

import { AddTenantAdminSagasService } from './services/AddTenantAdminSagasService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class SagasClient {
  public readonly addTenantAdminSagas: AddTenantAdminSagasService;

  public readonly request: BaseHttpRequest;

  constructor(
    config?: Partial<OpenAPIConfig>,
    HttpRequest: HttpRequestConstructor = AxiosHttpRequest,
  ) {
    this.request = new HttpRequest({
      BASE: config?.BASE ?? '',
      VERSION: config?.VERSION ?? '2.0.0',
      WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
      CREDENTIALS: config?.CREDENTIALS ?? 'include',
      TOKEN: config?.TOKEN,
      USERNAME: config?.USERNAME,
      PASSWORD: config?.PASSWORD,
      HEADERS: config?.HEADERS,
      ENCODE_PATH: config?.ENCODE_PATH,
    });

    this.addTenantAdminSagas = new AddTenantAdminSagasService(this.request);
  }
}
