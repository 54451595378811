export const PHONE = '+7 (000) 000-00-00';

export const RUSSIAN_PHONE = '+{7} ({9}00) 000-00-00';

export const PASSPORT_SERIAL = '0000';

export const PASSPORT_NUMBER = '000000';

export const PASSPORT_ISSUER_SUBDIVISION_CODE = '000-000';

export const SNILS = '000-000-000 00';

export const DAY_FORMAT = '000';

export const LICENSE_FORMAT = '0000000';

export const INN = '000000000000';

export const INN_U = '0000000000';
