import { LazyDestroyer } from '@services/LazyDestroyer';

type TableContainerStoreParams = {
  node: HTMLDivElement;
};

/**
 * вспомогательный стор таблицы, необходимый для уменьшения количества рендеров
 */
export class TableContainerStore {
  private readonly params: TableContainerStoreParams;

  private destroyer = new LazyDestroyer();

  private resizeObserver: ResizeObserver;

  constructor(params: TableContainerStoreParams) {
    this.params = params;
    this.resizeObserver = new ResizeObserver(this.setSize);
    this.destroyer.push(this.unobserve);
    this.init();
  }

  private unobserve = () => {
    this.resizeObserver.unobserve(this.params.node);
  };

  private observe = () => {
    this.resizeObserver.observe(this.params.node);
  };

  private setSize = () => {
    this.params.node.style.setProperty(
      '--table-width',
      `${this.params.node.clientWidth}px`,
    );

    this.params.node.style.setProperty(
      '--table-resizer-height',
      `${this.params.node.clientHeight}`,
    );
  };

  public init = () => {
    this.destroyer.init();
    this.observe();
  };

  public destroy = () => {
    this.destroyer.destroy();
  };
}
