import { useCallback, useEffect, useMemo, useState } from 'react';

import { toggleInSet } from '../../../utils/toggleInSet';
import {
  type GroupedMultipleAutocompleteOption,
  type MultipleAutocompleteOptionId,
  OptionType,
} from '../../../types';

type UseSelectedGroupedItemsOptions = {
  groups: {
    [key: string]: GroupedMultipleAutocompleteOption[];
  };
  options: GroupedMultipleAutocompleteOption[];
  value?: GroupedMultipleAutocompleteOption['id'][];
  onChange: (item: GroupedMultipleAutocompleteOption['id'][]) => void;
};

const getSelectedOptions = (
  prev: GroupedMultipleAutocompleteOption[],
  selectedIds: Set<MultipleAutocompleteOptionId>,
  options: GroupedMultipleAutocompleteOption[],
) => {
  // создаем сет выбранных элементов, для уменьшения сложности алогоритма
  const defenetlyInSelectedIds = new Set<MultipleAutocompleteOptionId>();
  // отфильтровываем выбранные, параллельно наполняя сет
  const defenetlyInSelected = prev.filter((item) => {
    const hasId = selectedIds.has(item.id);

    if (hasId) {
      defenetlyInSelectedIds.add(item.id);
    }

    return hasId;
  });

  // если получили одинаковую длину, значит была процедура уменьшения выборки,
  // и нам не нужно искать дополнительные, сразу устанавливаем
  if (defenetlyInSelected.length === selectedIds.size) {
    return defenetlyInSelected;
  }

  // иначе ищем доп элементы,
  const extra = options?.filter(
    (item) => selectedIds.has(item.id) && !defenetlyInSelectedIds.has(item.id),
  );

  return [...defenetlyInSelected, ...extra];
};

export const useSelectedGroupedItems = ({
  groups,
  value,
  onChange,
  options,
}: UseSelectedGroupedItemsOptions) => {
  const [selectedIds, setSelectedIds] = useState(new Set(value));
  const [
    /**
     * набор выбранных элементов, позволяет избежать проблемы,
     * когда набор входных данных(options) меняется (при поиске на сервере),
     * а у нас из запомненного, только id
     */
    selectedOptions,
    setSelectedOptions,
  ] = useState(options?.filter((item) => selectedIds.has(item.id)));

  const [selectedGroups, setSelectedGroups] = useState(new Set<string>());
  const isSelectedAllItems = useMemo(
    () => options.length === selectedIds.size,
    [options, selectedIds],
  );
  const handleClearAll = useCallback(() => {
    setSelectedOptions([]);
    setSelectedGroups(new Set());
    setSelectedIds(new Set([]));
    onChange([]);
  }, []);
  const handleSelectAll = useCallback(() => {
    const groupsKeys = Object.keys(groups);
    const itemsKey = options.map((e) => e.id);

    onChange(itemsKey);
    setSelectedIds(new Set(itemsKey));
    setSelectedGroups(new Set(groupsKeys));
    setSelectedOptions(options);
  }, [groups]);

  const handleChange = useCallback(
    ({
      id: selectedItemId,
      groupId: selectedItemGroupId,
      type,
      parent,
    }: GroupedMultipleAutocompleteOption) => {
      // Определяемся с типом элемента, по которому кликнули
      // т.к. их всего 2, и параметр типа не обязательный,
      // значит при отсутствии типа, клик прошел по element
      if (type !== OptionType.group) {
        // запускаем переключалку для элемента
        toggleInSet(
          // если элемент уже добавлен, то исключаем, и наоборот
          selectedIds.has(selectedItemId),
          selectedIds,
          selectedItemId,
        );

        // метод проверяющий, что все дочерние вглубь элементы выбраны
        const checkGroup = (_groupId: string): Boolean =>
          Array.isArray(groups[_groupId]) &&
          // чекаем всех дочерних для этой группы
          groups[_groupId as string].every(
            ({ id, children }) =>
              // список выбранных элементов должен содержать итерируемый,
              // и итерируемый должен не содержать дочерних,
              // либо если есть дочерние, запускаем рекурсию проверки группы
              selectedIds.has(id) && (!Boolean(children) || checkGroup(id)),
          );
        // запускаем рекурсивную проверку, на то что все группы с дочерними выбраны
        const isAllInGroupChecked = checkGroup(selectedItemGroupId!);

        // запускаем переключалку
        toggleInSet(
          // если не выбраны, отключаем, и наоборот
          !isAllInGroupChecked,
          selectedGroups,
          selectedItemGroupId,
        );
      } else {
        // флаг того, что группа уже выбрана
        const isGroupAlreadySelected = selectedGroups.has(selectedItemId);

        // метод проверки групп вглубь
        const toggleGroup = (_groupId: string) => {
          // запускаем переключалку
          toggleInSet(
            // если группа уже выбрана, выключаем, и наоборот
            isGroupAlreadySelected,
            selectedGroups,
            _groupId,
          );

          // чекаем всех дочерних для этой группы
          groups[_groupId].forEach(({ id, children }) => {
            // если элемент имеет свои дочерние элементы,
            if (children) {
              // значит запускаем рекурсию для этого элемента как родительского
              toggleGroup(id);
            }

            // запускаем переключалку
            toggleInSet(
              // если группа уже выбрана, выключаем, и наоборот
              isGroupAlreadySelected,
              selectedIds,
              id,
            );
          });
        };

        toggleGroup(selectedItemId);
      }

      // метод проверки вверх, чтобы родители реагировали бы, на то что дочерние поменялись
      const toggleParent = (_parent?: GroupedMultipleAutocompleteOption) => {
        if (_parent) {
          // флаг, того, что все дочерние элементы выбраны
          const isAllChildrenSelected = _parent.children!.every(
            (child) =>
              // элемент должен быть в списке выбранных
              selectedIds.has(child.id) &&
              // и либо не должно быть дочерних элементов,
              // либо список выбранных групп должен содержать элемент
              (!Boolean(child.children) || selectedGroups.has(child.id)),
          );

          // запускаем переключалку родителя
          toggleInSet(
            // если не все выбраны, выключаем, и наоборот
            !isAllChildrenSelected,
            selectedGroups,
            _parent.id,
          );

          toggleParent(_parent.parent);
        }
      };

      setSelectedOptions((prev) =>
        getSelectedOptions(prev, selectedIds, options),
      );

      // вне зависимости от типа переключенного элемента, запускаем проверку для родителя
      toggleParent(parent);
      onChange([...selectedIds]);
      setSelectedIds(new Set(selectedIds));
      setSelectedGroups(new Set(selectedGroups));
    },
    [selectedIds, selectedGroups, groups, options],
  );
  const toggleAll = () => {
    if (isSelectedAllItems) {
      handleClearAll();
    } else {
      handleSelectAll();
    }
  };

  // эффект для синхронизации
  useEffect(() => {
    if (options.length && selectedIds.size !== selectedOptions.length) {
      setSelectedOptions((current) => {
        const res = getSelectedOptions(current, selectedIds, options);

        // Делаем проверку на длину внутри установки значения, чтобы защититься об бесконечного рендера,
        // когда в значение попадает mobx элемент.
        // Потенциально узкое место - длина, разные массивы могут иметь одинаковую длину,
        // в случае возникновения проблем, потребуется рассчитывать хэш
        if (res.length === current?.length) {
          return current;
        }

        return res;
      });
    }
  }, [options, selectedIds, selectedOptions]);

  useEffect(() => {
    // если нет инишал значений либо уже есть в коллекции - выходим
    if (!value || Boolean(selectedIds.size)) {
      return;
    }

    const itemsKeys = Object.keys(groups).flatMap((e) => groups[e]);

    // если значений столько же сколько всего то проставляем все галки
    if (itemsKeys.length === value?.length) {
      handleSelectAll();

      return;
    }

    if (!selectedIds.size) {
      setSelectedIds(new Set(value));
    }
  }, [value]);

  return {
    handleChange,
    selectedIds,
    selectedOptions,
    selectedGroups,
    handleClearAll,
    isSelectedAllItems,
    toggleAll,
  };
};
