import { styled } from '../styled';

export const Container = styled.div<{ $color?: 'grey' | 'error' }>`
  display: flex;
  align-items: center;
  height: max-content;
  color: ${({ theme, $color }) =>
    $color === 'error' ? theme.palette.error[800] : theme.palette.text.primary};

  & svg {
    fill: currentColor;
  }
`;
