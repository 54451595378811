import { styled } from '../styled';
import { CloseFillSm } from '../Icons';
import { ActionButton } from '../ActionButton';

export const ActionCrossBtnWrapper = styled(ActionButton)`
  min-width: 30px;
  height: 30px;
`;

export const CloseIcon = styled(CloseFillSm)`
  width: 16px;
  height: 16px;
  color: ${({ theme }) => theme.palette.grey[600]};
`;
