import { observer } from 'mobx-react-lite';
import { MAP_OF_SORT } from '@constants/sort';
import { type ChangeEvent } from 'react';

import { Checkbox } from '../../Checkbox';
import { OverflowTypography } from '../../OverflowTypography';
import {
  type ActionTableCell,
  type OnRequestSortCb,
  type TableColumns,
  type TableDataRow,
} from '../types';
import { TableCellInner, TableCellWrapper, TableCheckBoxCell } from '../Cell';
import { TableSortLabel } from '../TableSortLabel';
import { ACTION_CELL_NAME } from '../constants';

import { TableHeadWrapper } from './styles';

export type TableHeadProps<
  TData extends {} = TableDataRow,
  TOrderBy extends string | number | symbol = keyof TData,
> = {
  numSelected: number;
  rowSelected?: boolean;
  isSelectable?: boolean;
  columns: TableColumns<TData, TOrderBy>[];
  onRequestSort: OnRequestSortCb;
  onSelectAllClick: (event: ChangeEvent<HTMLInputElement>) => void;
  isDesc?: boolean;
  orderBy?: TOrderBy | ActionTableCell;
  rowCount: number;
};

export const TableHead = observer(
  <TData extends {}, TOrderBy extends string | number | symbol = keyof TData>(
    props: TableHeadProps<TData, TOrderBy>,
  ) => {
    const {
      onSelectAllClick,
      numSelected,
      columns,
      rowCount,
      rowSelected = true,
      onRequestSort,
      orderBy,
      isSelectable = false,
      isDesc,
    } = props;

    const isChecked = rowCount > 0 && numSelected === rowCount;
    const isIndeterminate = numSelected > 0 && numSelected < rowCount;

    return (
      <TableHeadWrapper>
        {rowSelected && (
          <TableCheckBoxCell>
            {isSelectable && (
              <Checkbox
                indeterminate={isIndeterminate}
                checked={isChecked}
                onChange={onSelectAllClick}
              />
            )}
          </TableCheckBoxCell>
        )}
        {columns.map((headCell, index) => (
          <TableCellWrapper key={`${headCell.field}_${index}`} index={index}>
            <TableCellInner
              isAlignRight={
                index === columns.length - 1 &&
                headCell.field === ACTION_CELL_NAME
              }
            >
              <OverflowTypography
                variant="pointer"
                component="div"
                color="grey"
                colorIntensity="700"
              >
                {headCell.label}
              </OverflowTypography>
              {headCell.sortable && (
                <TableSortLabel
                  onRequestSort={onRequestSort}
                  sortableFieldName={headCell.sortableFieldName}
                  orderBy={
                    orderBy === headCell.sortableFieldName
                      ? headCell.sortableFieldName
                      : orderBy
                  }
                  order={isDesc ? MAP_OF_SORT.desc : MAP_OF_SORT.asc}
                  field={headCell.field as string}
                />
              )}
            </TableCellInner>
          </TableCellWrapper>
        ))}
      </TableHeadWrapper>
    );
  },
);
