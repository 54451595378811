import { styled } from '../../styled';
import { Pagination } from '../../Pagination';

export const PaginationWrapper = styled(Pagination)`
  display: flex;
  justify-content: center;

  padding: ${({ theme }) => theme.spacing(4)};

  .MuiButtonBase-root.Mui-selected {
    color: ${({ theme }) => theme.palette.background.element};

    background: ${({ theme }) => theme.palette.grey[900]};
  }
`;
