import { styled } from '../../styled';
import { Virtuoso } from '../../Virtuoso';
import { type GroupedMultipleAutocompleteOption } from '../types';

const getVirtuosoWrapperHeight = (itemsLength: number, isAllGroup: boolean) => {
  const currentItemsLength = isAllGroup ? itemsLength + 2 : itemsLength;

  if (currentItemsLength * 54 > 340) {
    return '340px';
  }

  return `${currentItemsLength * 54}px`;
};

export const ListWrapper = styled.ul`
  display: block;

  width: 100%;
  height: 100%;
  min-height: ${({ theme }) => theme.spacing(10)};
  margin: 0;
  padding: 0;

  list-style: none;
`;

const TypedVirtuoso = Virtuoso<GroupedMultipleAutocompleteOption>;

export const VirtuosoWrapper = styled(TypedVirtuoso, {
  shouldForwardProp: (prop) => prop !== 'itemsLength' && prop !== 'isAllGroup',
})<{ itemsLength: number; isAllGroup: boolean }>`
  width: 100%;
  height: ${({ itemsLength, isAllGroup }) =>
    getVirtuosoWrapperHeight(itemsLength, isAllGroup)};
  min-height: ${({ itemsLength, isAllGroup }) =>
    getVirtuosoWrapperHeight(itemsLength, isAllGroup)};
`;
