import { AvatarGroup as MuiAvatarGroup } from '@mui/material';

import { styled } from '../styled';
import { Avatar } from '../Avatar';
import { RouterLink } from '../RouterLink';

import { type ItemStatuses } from './constants';

const getAvatarColorByStatus = (status?: ItemStatuses) => {
  switch (status) {
    case 'signed': {
      return 'green';
    }
    case 'rejected': {
      return 'red';
    }
    default:
      return 'primary';
  }
};

export const AvatarGroupWrapper = styled(MuiAvatarGroup)`
  justify-content: start;
`;

export const AvatarGroupElement = styled(Avatar, {
  shouldForwardProp: (prop) => prop !== 'status',
})<{ status?: ItemStatuses }>`
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};

  background-color: ${({ theme, status }) =>
    theme.palette[getAvatarColorByStatus(status)][600]};

  &.MuiAvatar-root:hover {
    background-color: ${({ theme, status }) =>
      theme.palette[getAvatarColorByStatus(status)][800]};
  }
`;

export const AvatarGroupLink = styled(RouterLink)`
  display: flex;
  align-items: center;

  color: white;
  text-decoration: none;

  background-color: transparent !important;
`;
