import { useCallback, useEffect, useId, useMemo } from 'react';
import {
  type DefaultValues,
  type Path,
  type PathValue,
  type SubmitHandler,
  type UseFormHandleSubmit,
  type UseFormProps,
  type UseFormReset,
  type UseFormReturn,
  useForm as useRHF,
} from 'react-hook-form';
import { type FieldValues } from '@types';
import { lodashDebounce } from '@utils/lodash/debounce';
import { type createSchema, type objectAsync } from '@schemas/schema';
import { resolver } from '@astral/validations-react-hook-form-resolver';

import { useCustomEventData } from './hooks';

type ExternalError = {
  name: string;
  message: string;
};

export type UseFormParams<
  TValues extends FieldValues,
  TContext = object,
> = Omit<UseFormProps<TValues, TContext>, 'defaultValues' | 'resolver'> & {
  resetWithDefaultValuesChanged?: boolean;
  resetAfterSubmit?: boolean;
  defaultValues?: DefaultValues<TValues>;
  submitDelay?: number;
  submitDataFormat?: (data: TValues) => TValues;
  validateSchema?: ReturnType<
    typeof createSchema<TValues, TValues> | typeof objectAsync<TValues, TValues>
  >;
  onSubmit: (data: TValues) => void;
  /**
   * внешняя ошибка, которая может содержать валидационные ошибки от бэка
   */
  externalErrors?: ExternalError[];
};

export type UseFormReturnParams<
  TValues extends FieldValues,
  TContext = {},
> = Omit<UseFormReturn<TValues, TContext>, 'handleSubmit'> & {
  handleSubmit: () => void;
  reset: (() => void) | UseFormReset<TValues>;
  onSubmit: (data: TValues) => void;
  updateFormState: (data: {}) => void;
  handleSubmitData: UseFormHandleSubmit<TValues>;
  formId: string;
};

export function useForm<TValues extends FieldValues, TContext = {}>({
  resetWithDefaultValuesChanged,
  defaultValues,
  validateSchema,
  resetAfterSubmit,
  submitDataFormat,
  onSubmit,
  submitDelay = 0,
  externalErrors,
  ...formProps
}: UseFormParams<TValues, TContext>): UseFormReturnParams<TValues, TContext> {
  const id = useId();
  const formId = useMemo(() => `f${id}`, [id]);

  const { handleSubmit, reset, ...returnFormParams } = useRHF<TValues>({
    ...formProps,
    defaultValues,
    resolver: validateSchema && resolver<TValues>(validateSchema),
  } as UseFormProps<TValues, TContext>);

  useEffect(() => {
    if (!resetWithDefaultValuesChanged) {
      return;
    }

    reset(defaultValues);
  }, [defaultValues]);

  const updateFormState = useCallback(
    (submitData: {}) => {
      for (const key of Object.keys(submitData)) {
        const submitDataKey = key as PathValue<TValues, Path<TValues>>;

        returnFormParams.setValue(
          submitDataKey as Path<TValues>,
          submitData[submitDataKey],
        );
      }
    },
    [returnFormParams],
  );

  const onSubmitWithDelay = lodashDebounce(
    (data: TValues) => {
      let result = submitDataFormat ? submitDataFormat(data) : data;

      onSubmit?.(result);

      if (resetAfterSubmit) {
        reset();
      }
    },
    { waitMs: submitDelay },
  ).call as SubmitHandler<TValues>;

  useEffect(() => {
    if (externalErrors) {
      externalErrors.forEach(({ name, message }) => {
        if (defaultValues?.hasOwnProperty?.(name)) {
          returnFormParams.setError(name as Path<TValues>, { message });
        }
      });
    }
  }, [externalErrors, defaultValues]);

  useCustomEventData(defaultValues, returnFormParams.setValue);

  return {
    handleSubmit: handleSubmit(onSubmitWithDelay),
    reset,
    onSubmit,
    updateFormState,
    handleSubmitData: handleSubmit,
    formId,
    ...returnFormParams,
  };
}
