type MakeName = (
  last?: string | null,
  first?: string | null,
  middle?: string | null,
) => string;

const makeName: MakeName = (last, first, middle) =>
  [last, first, middle].filter(Boolean).join(' ');

const makeShortName: MakeName = (last, first, middle) => {
  const innerFirst = first ? `${first[0]}.` : '';
  const innerMiddle = middle ? `${middle[0]}.` : '';

  return makeName(last, `${innerFirst}${innerMiddle}`);
};

type EmployeeNames = {
  MiddleName?: string | null;
  FirstName?: string | null;
  LastName?: string | null;
};

type GetName = (
  first?: string | null | EmployeeNames,
  middle?: string | null,
  last?: string | null,
) => string;

export const getFullName: GetName = (firstOrData, middle, last) => {
  if (typeof firstOrData === 'object') {
    return makeName(
      firstOrData?.LastName,
      firstOrData?.FirstName,
      firstOrData?.MiddleName,
    );
  }

  return makeName(last, firstOrData, middle);
};

export const getShortFullName: GetName = (firstOrData, middle, last) => {
  if (typeof firstOrData === 'object') {
    return makeShortName(
      firstOrData?.LastName,
      firstOrData?.FirstName,
      firstOrData?.MiddleName,
    );
  }

  return makeShortName(last, firstOrData as string, middle);
};
