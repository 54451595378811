import { useForm } from '@hooks/useForm';
import type { ConfirmStore } from '@stores';
import { useRef } from 'react';
import { MAP_OF_TEXT_LENGTH } from '@constants';

import { type ButtonProps } from '../../Button';

import { schema } from './validation';
import { type SubmitData } from './types';

export type UseLogicParams = {
  store: ConfirmStore<SubmitData>;
  submitButtonColor: ButtonProps['color'];
  /**
   * @default 128
   */
  maxCommentLength?: number;
};

export const useLogic = ({
  store,
  submitButtonColor,
  maxCommentLength = MAP_OF_TEXT_LENGTH.sm,
}: UseLogicParams) => {
  const { handleSubmit, control } = useForm<SubmitData>({
    validateSchema: schema(maxCommentLength),
    defaultValues: {
      comment: '',
    },
    onSubmit: store.submit,
  });

  const inputRef = useRef<HTMLButtonElement>(null);

  const handleTransitionEnd = () => {
    if (store.isActive && inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleCancelClick = () => {
    store.cancel();
  };

  return {
    handleSubmit,
    handleTransitionEnd,
    inputRef,
    handleCancelClick,
    control,
    cancelText: store.cancelText,
    title: store.title,
    description: store.description,
    submitText: store.submitText,
    isActive: store.isActive,
    submitButtonColor,
  };
};
