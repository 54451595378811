import { forwardRef } from 'react';

import { Typography, type TypographyProps } from '../Typography';
import { Skeleton } from '../Skeleton';

type LoadingTypographyProps = {
  isLoading?: boolean;
  loadingWidth?: number;
  isFullWidthLoading?: boolean;
} & TypographyProps;

export const LoadingTypography = forwardRef<
  HTMLSpanElement,
  LoadingTypographyProps
>(
  (
    { isLoading, loadingWidth, isFullWidthLoading, children, ...props },
    ref,
  ) => (
    <Typography {...props} ref={ref}>
      {isLoading ? (
        <Skeleton width={loadingWidth} isFullWidth={isFullWidthLoading} />
      ) : (
        children
      )}
    </Typography>
  ),
);
