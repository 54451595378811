export const COMMON_ACCEPT = {
  pdf: 'application/pdf',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  doc: 'application/msword',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  odt: 'application/vnd.oasis.opendocument.text',
  ods: 'application/vnd.oasis.opendocument.spreadsheet',
  odp: 'application/vnd.oasis.opendocument.presentation',
  odg: 'application/vnd.oasis.opendocument.graphics',
  heic: 'image/heic',
  tiff: 'image/tiff',
  jfif: 'image/jpeg',
  jpeg: 'image/jpeg',
  jpg: 'image/jpg',
  jpe: 'image/jpe',
  bmp: 'image/bmp',
  png: 'image/png',
  zip: 'application/zip',
  zipCompressed: 'application/x-zip-compressed',
  rar: 'application/x-rar-compressed',
  rarVnd: 'application/vnd.rar',
  textCsv: 'text/csv',
  csv: '.csv',
};
