import { styled } from '@astral/ui';

export const ProfileRootButton = styled('button')`
  display: flex;
  align-items: center;

  height: auto;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  text-align: right;
  transition: background-color 0.3s linear;
  border-radius: ${({ theme }) => theme.shape.small};

  &:hover {
    background-color: ${({ theme }) => theme.palette.grey[200]};
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: ${({ theme }) => theme.spacing(0, 1, 0, 2)};
  }
`;

export const ProfileRoot = styled.div`
  display: flex;
  align-items: center;

  height: auto;
  padding: ${({ theme }) => theme.spacing(0.5, 0.5, 0.5, 2)};

  text-align: right;
`;

export const ProfileUser = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  align-items: center;
`;
