export const MAP_OF_TEXT_LENGTH = {
  sx: 32,
  sm: 128,
  md: 255,
  md2: 256,
  lg: 500,
  xl: 1000,
  xxl: 2000,
};

export const COMMENT_MAX_LENGTH = 255;

export const ROUTE_NAME_MAX_LENGTH = 125;
