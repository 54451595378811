import { type MenuListProps } from './types';
import { MenuListItem } from './MenuListItem';

export const MenuList = ({ menu, isOpen }: MenuListProps) => (
  <>
    {menu.map((menuItem) => (
      <MenuListItem
        key={menuItem.id}
        menuItem={menuItem}
        isOpen={isOpen}
        indent={0}
      />
    ))}
  </>
);
