import { lazy } from 'react';

import { getWorkerSrc } from '../getWorkerSrc';

export const PDFPage = lazy(() =>
  import('react-pdf').then(({ Page, pdfjs }) => {
    pdfjs.GlobalWorkerOptions.workerSrc = getWorkerSrc();

    return { default: Page };
  }),
);
