import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { metaTitleService } from '@dashboard/shared';
import {
  configService,
  errorMonitoringService,
} from '@dashboard/modules/service';

import { App } from './App';

configService.init(() => location.reload());
errorMonitoringService.init();
metaTitleService.addSubtitle('Admin | IКЭДО');

export const renderApp = (): void => {
  const root = document.getElementById('root');

  if (!root) {
    console.warn('Рут не найден');

    return;
  }

  createRoot(root).render(
    <StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </StrictMode>,
  );
};

renderApp();
