import { observer } from 'mobx-react-lite';

import {
  AppLogo,
  DashboardLayout,
  type WithJSXChildren,
  routerService,
} from '@dashboard/shared';

import { MENU } from './constants';

export const CommonLayout = observer(({ children }: WithJSXChildren) => (
  <DashboardLayout>
    <DashboardLayout.Header
      product={{ name: 'Панель администратора', logo: () => <AppLogo /> }}
    />
    <DashboardLayout.Sidebar menu={MENU(routerService.pathname)} />
    <DashboardLayout.Main>{children}</DashboardLayout.Main>
  </DashboardLayout>
));
