import { type FileRejection } from 'react-dropzone';

export enum ErrorCode {
  FileInvalidType = 'file-invalid-type',
  FileTooLarge = 'file-too-large',
  FileTooSmall = 'file-too-small',
  TooManyFiles = 'too-many-files',
}

export type FileMeta = {
  loading?: boolean;
  error?: null | { message: string };
  percentComplete?: number;
};

export type FileViewForm = {
  name: string;
  size: number;
  extension: string;
  id: string;
};

export type ErrorMap = {
  [item in ErrorCode]: string;
};

export type { FileRejection };

export type UploadFile = FileViewForm & { meta?: FileMeta };

export type FileUploaderOptions = {
  isLoading: boolean;
  files: File[];
  onDrop: (files: File[]) => void;
  onRemoveFile: (file: File) => void;
  isMultiple: boolean;
  maxSize: number;
  minSize: number;
  accept: string[];
  showFileList: boolean;
  maxFiles?: number;
  placeholder?: string;
  onDropRejected: (rejections: FileRejection[]) => void;
};
