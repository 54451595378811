import { type GridProps as UiGridProps } from '@astral/ui';
import { forwardRef } from 'react';

import { GridWrapper, type GridWrapperProps } from './styles';

export type GridProps = Omit<UiGridProps, 'columns' | 'rows' | 'ref'> &
  GridWrapperProps;

export const Grid = forwardRef<HTMLDivElement, GridProps>((props, ref) => (
  <GridWrapper
    ref={ref}
    {...props}
    columns={props.columns as number}
    rows={props.rows as number}
  />
));
