import { ConfigFetcher as CommonConfigFetcher } from '@common/api';
import { type CacheService } from '@shared';

import { cacheService } from '../../services';

/**
 * Фетчер для получения конфига
 */
export class ConfigFetcher<TConfig> extends CommonConfigFetcher<TConfig> {
  constructor(_cacheService: CacheService = cacheService) {
    super(_cacheService);
  }
}
