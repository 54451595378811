import {
  type FormCellCommonProps,
  type FormCellToggleProps,
} from '../../types';

type CreateSubmitHandlerParams<TValue> = Pick<
  FormCellCommonProps<TValue>,
  'onSubmit'
> &
  Pick<FormCellToggleProps, 'onSuccess'>;

/**
 * фабричный метод создания обработчика сабмита
 */
export const createSubmitHandler =
  <TValue>(params: CreateSubmitHandlerParams<TValue>) =>
  (data: { value: TValue }) =>
    params.onSubmit(data).then((res) => {
      if (res) {
        params.onSuccess();
      }
    });
