import { type GetBaseUrlQuery } from '@common/data/api-core';
import { MicroserviceRepository } from '@common/data';

import {
  type ApiError,
  type CacheService,
  type DocumentTemplate,
  type DocumentTemplateTag,
  type TemplateStatusHistoryEntry,
  cacheService,
} from '@dashboard/shared';

import { TemplatesClient } from '../../sources/client/templates';
import { type BaseApiUrlConfig, configFetcher } from '../configFetcher';

import {
  type DeleteDocumentTemplateExecutorParams,
  type EditDocumentTemplateActivityExecutorParams,
  type EditDocumentTemplateExecutorParams,
  type GetDocumentTemplatesFilterParams,
  type GetDocumentTemplatesHistoryParams,
  type GetDocumentTypesQueryParams,
  type UploadDocumentTemplateExecutorParams,
} from './types';
import {
  DOCUMENT_TEMPLATES_HISTORY_KEY,
  DOCUMENT_TEMPLATES_KEY,
  DOCUMENT_TEMPLATE_TAGS_KEY,
  documentTemplateDocumentTypesKey,
} from './constants';
import { type DocumentTemplatesRepositoryDTO } from './dto';

type DocumentTemplatesRepositoryParams = {
  cacheService: CacheService<ApiError>;
  getConfigQuery: GetBaseUrlQuery<BaseApiUrlConfig>;
};

export class DocumentTemplatesRepository extends MicroserviceRepository<
  TemplatesClient,
  BaseApiUrlConfig
> {
  cacheService: CacheService<ApiError>;

  constructor(params: DocumentTemplatesRepositoryParams) {
    super({
      getConfigQuery: params.getConfigQuery,
      microserviceClient: TemplatesClient,
    });

    this.cacheService = params.cacheService;
  }

  getUploadDocumentTemplateQuery = () =>
    this.cacheService.createMutation(
      (params: UploadDocumentTemplateExecutorParams) =>
        this.client.templates
          .templatesAddTemplate({
            documentTypeId: params.documentTypeId,
            formData: {
              uploadedFile: params.file,
            },
            isActive: params.isActive,
            tenantId: params.tenantId,
            name: params.name,
          })
          .then((res) => {
            this.cacheService.invalidate([
              DOCUMENT_TEMPLATES_KEY(params.tenantId),
            ]);

            return res;
          }),
    );

  getEditDocumentTemplateQuery = () =>
    this.cacheService.createMutation(
      (params: EditDocumentTemplateExecutorParams): Promise<unknown> =>
        this.client.templates
          .templatesUpdateTemplateIndexTagsTemplate({
            templateId: params.id,
            requestBody: [
              {
                IndexOrder: params.index as number,
                DisplayName: params.displayName,
                InTextName: params.tagName,
                ValueType: params.type,
                TagHint: params.tagHint,
                IndexTagTemplateDateTimeValidationRules:
                  params.IndexTagTemplateDateTimeValidationRules,
                IndexTagTemplateNumberValidationRules:
                  params.IndexTagTemplateNumberValidationRules,
              },
            ],
          })
          .then(() => {
            this.cacheService.invalidate([
              DOCUMENT_TEMPLATES_HISTORY_KEY(params.id),
              DOCUMENT_TEMPLATE_TAGS_KEY(params.id),
            ]);
          }),
    );

  getEditDocumentTemplateActivityQuery = () =>
    this.cacheService.createMutation(
      (params: EditDocumentTemplateActivityExecutorParams) =>
        this.client.templates.templatesUpdateTemplateActivityStatus({
          isActive: params.isActive,
          templateId: params.id,
        }),
    );

  getDocumentTemplatesListQuery = (params: GetDocumentTemplatesFilterParams) =>
    this.cacheService.createInfiniteQuery(
      [DOCUMENT_TEMPLATES_KEY(params.tenantId), params],
      (infinityParams): Promise<Array<DocumentTemplate>> =>
        this.client.templates.templatesGetTemplates({
          tenantId: params.tenantId,
          documentTypeId: params.documentTypeId,
          count: infinityParams.count,
          offset: infinityParams.offset,
        }),
      { isBackground: true },
    );

  getDeleteDocumentTemplateQuery = () =>
    this.cacheService.createMutation(
      (params: DeleteDocumentTemplateExecutorParams) =>
        this.client.templates
          .templatesDeleteTemplate({
            templateId: params.id,
          })
          .then((res) => {
            this.cacheService.invalidate([
              DOCUMENT_TEMPLATES_KEY(params.tenantId),
              DOCUMENT_TEMPLATES_HISTORY_KEY(params.id),
            ]);

            return res;
          }),
    );

  getDocumentTemplatesHistoryQuery = (
    params: GetDocumentTemplatesHistoryParams,
  ) =>
    this.cacheService.createInfiniteQuery(
      [DOCUMENT_TEMPLATES_HISTORY_KEY(params.templateId)],
      (infinityParams): Promise<TemplateStatusHistoryEntry[]> =>
        params.templateId
          ? this.client.templates.templatesGetTemplateHistory({
              ...infinityParams,
              templateId: params.templateId as string,
            })
          : Promise.resolve([]),
      { isBackground: true },
    );

  getDocumentTemplateTagsQuery = (templateId?: string) =>
    this.cacheService.createQuery(
      [DOCUMENT_TEMPLATE_TAGS_KEY(templateId)],
      (): Promise<DocumentTemplateTag[]> =>
        templateId
          ? this.client.templates.templatesGetTemplateIndexTagsTemplate({
              templateId,
            })
          : Promise.resolve([]),
    );

  getDocumentTypesQuery = ({
    tenantId,
    findText,
  }: GetDocumentTypesQueryParams) =>
    this.cacheService.createInfiniteQuery(
      [documentTemplateDocumentTypesKey(tenantId), findText],
      (infinityParams) =>
        this.client.documentTypes.documentTypesGetDocumentTypes({
          ...infinityParams,
          tenantId,
          findText,
        }),
      { isBackground: true },
    );

  getUploadSystemDocumentTemplateQuery = () =>
    this.cacheService.createMutation(
      (
        params: DocumentTemplatesRepositoryDTO.UploadSystemDocumentTemplateQueryExecutorParams,
      ) =>
        this.client.templates.templatesAddSystemTemplate({
          isActive: params.isActive,
          formData: {
            uploadedFile: params.file,
          },
          systemTemplateType: params.type,
          name: params.name,
        }),
    );
}

export const documentTemplatesRepository = new DocumentTemplatesRepository({
  getConfigQuery: configFetcher.config.create,
  cacheService,
});
