import { MAP_OF_OPENABLE_EXCEPTIONS } from '@constants';

import { getWebFormatByFileName } from '../getWebFormatByFileName';
import { downloadFileByLink } from '../downloadFileByLink';
import { generateBlobUrl } from '../generateBlobUrl';

export const openFileInNewTab = (blob: Blob, filename: string) => {
  const { url, revoke } = generateBlobUrl(blob);

  if (getWebFormatByFileName(filename, MAP_OF_OPENABLE_EXCEPTIONS)) {
    window.open(url, filename);
  } else {
    downloadFileByLink(url, filename);
  }

  revoke();
};
