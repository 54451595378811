import { type HTMLAttributes, Suspense, lazy, useEffect } from 'react';
import { useController } from 'react-hook-form';
import { observer } from 'mobx-react-lite';
import { classnames } from '@utils/string/classnames';
import { METRIKA_CLASSES } from '@constants/metrikaClasses';
import { type Certificate, cryptoService } from '@services/CryptoService';
import {
  ErrorIllustration,
  MonitorIllustration,
  WarnIllustration,
} from '@illustrations';

import { notify } from '../../Notification';
import { type EmployeeInfoForVerifications } from '../../../types';
import { Spinner } from '../../Spinner';
import { PersonalData } from '../../PersonalData';
import {
  BaseFormAutocomplete,
  type BaseFormAutocompleteProps,
} from '../FormAutocomplete';

import { sortDisabledUQESCertificates, transformCertificate } from './utils';
import { AutocompleteListItem } from './AutocompleteListItem';
import { checkIsCertificateDisabled } from './checkIsCertificateDisabled';

const CryptoProvider = lazy(() =>
  import('@astral-private/crypto').then((data) => ({
    default: data.CryptoProvider,
  })),
);

export type FormCryptoAutocompleteProps = {
  infoForVerification?: EmployeeInfoForVerifications;
} & Omit<
  BaseFormAutocompleteProps<Certificate, Certificate, false, false, false>,
  'multiple' | 'options'
>;

const checkIsOptionEqualToValue = (option: Certificate, value: Certificate) =>
  JSON.stringify(value) === JSON.stringify(option);

type AutocompleteRenderOptionState = {
  inputValue: string;
  selected: boolean;
};

const CertificatesOptions =
  (
    options: Certificate[],
    returnValueFormat: FormCryptoAutocompleteProps['returnValueFormat'],
    infoForVerification?: EmployeeInfoForVerifications,
  ) =>
  (
    { className, ...restAttrs }: HTMLAttributes<HTMLLIElement>,
    certificate: Certificate,
    meta: AutocompleteRenderOptionState,
  ) => {
    const current = !returnValueFormat
      ? certificate
      : options.find(
          (option) =>
            returnValueFormat(option) === (certificate as unknown as string),
        );

    const transformedCertificate = transformCertificate(current as Certificate);
    const disabled = checkIsCertificateDisabled(
      current as Certificate,
      infoForVerification,
    );

    return (
      <PersonalData>
        <AutocompleteListItem
          {...restAttrs}
          key={transformedCertificate.subjectKeyId}
          title={transformedCertificate.name}
          sidetitle={transformedCertificate.ownerName}
          type={transformedCertificate.type}
          checked={meta.selected}
          inn={`ИНН: ${transformedCertificate.inn} `}
          notAfter={
            transformedCertificate.notAfter
              ? ` Действует до: ${transformedCertificate.notAfter}`
              : ''
          }
          aria-disabled={disabled}
          disabled={disabled}
        />
      </PersonalData>
    );
  };

export const FormCryptoAutocomplete = observer(
  ({ loading, infoForVerification, ...props }: FormCryptoAutocompleteProps) => {
    const cryptoStore = cryptoService.store;
    const isLoading = loading || cryptoService.isLoading;
    const options = sortDisabledUQESCertificates(
      cryptoStore?.certificateList || [],
      infoForVerification,
    );

    const {
      field: { onChange },
    } = useController({ control: props.control, name: props.name });

    useEffect(() => {
      if (infoForVerification) {
        cryptoService.init
          .then((certificates) => {
            const firstAvailableCertificate = certificates.find(
              (cert) => !checkIsCertificateDisabled(cert, infoForVerification),
            );

            if (firstAvailableCertificate) {
              onChange?.(
                props.returnValueFormat
                  ? props.returnValueFormat(firstAvailableCertificate)
                  : firstAvailableCertificate,
              );
            }
          })
          .catch((e) => {
            notify.warning(e.message);
          });
      }
    }, [infoForVerification]);

    return (
      <>
        <BaseFormAutocomplete<Certificate, Certificate, false, false, false>
          {...props}
          options={options}
          loading={isLoading}
          data-test={props.name}
          className={classnames(
            METRIKA_CLASSES.hideInputValue,
            props.className,
          )}
          loadingText={<Spinner size="xm" type="pale" />}
          noOptionsText="Нет данных"
          isOptionEqualToValue={checkIsOptionEqualToValue}
          renderOption={CertificatesOptions(
            options,
            props.returnValueFormat,
            infoForVerification,
          )}
          getOptionLabel={(option) => {
            const certificate = props.returnValueFormat
              ? options.find(
                  (_option) =>
                    props.returnValueFormat?.(_option) ===
                    (option as unknown as string),
                )
              : option;

            return certificate?.subject?.commonName ?? '';
          }}
        />
        {cryptoStore && (
          <Suspense fallback={null}>
            <CryptoProvider
              cryptoStore={cryptoStore}
              imagesMap={{
                connectionFailedSrc: MonitorIllustration,
                incompatibleOperationSrc: ErrorIllustration,
                searchCertSrc: WarnIllustration,
              }}
            />
          </Suspense>
        )}
      </>
    );
  },
);
