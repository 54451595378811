import {
  ErrorIllustration,
  GoalIllustration,
  WarnIllustration,
} from '@illustrations';

import { type FullSizeNotificationService } from './types';
import { fullSizeNotificationsQueue } from './queue';
import { getOverloadedNotificationOptions } from './utils';

export const fullSizeNotify: FullSizeNotificationService = {
  /**
   * стандартная нотификация,
   * с предустановленной картинкой инфо
   */
  info: (props) => {
    const options = getOverloadedNotificationOptions(props);

    fullSizeNotificationsQueue.add({ imgSrc: GoalIllustration, ...options });
  },
  /**
   * стандартная нотификация,
   * с предустановленной картинкой ошибки, и заголовком "Ошибка"
   */
  error: (props) => {
    const { title = 'Ошибка', ...options } =
      getOverloadedNotificationOptions(props);

    fullSizeNotificationsQueue.add({
      title,
      imgSrc: ErrorIllustration,
      ...options,
    });
  },
  /**
   * стандартная нотификация,
   * с предустановленной картинкой успеха
   */
  success: (props) => {
    const options = getOverloadedNotificationOptions(props);

    fullSizeNotificationsQueue.add({ imgSrc: GoalIllustration, ...options });
  },
  /**
   * стандартная нотификация,
   * с предустановленной картинкой ошибки, и заголовком "Внимание"
   */
  warning: (props) => {
    const { title = 'Внимание', ...options } =
      getOverloadedNotificationOptions(props);

    fullSizeNotificationsQueue.add({
      title,
      imgSrc: WarnIllustration,
      ...options,
    });
  },
  /**
   * кастомная нотификация
   */
  custom: fullSizeNotificationsQueue.add,
};
