import {
  type DateType,
  FormatVariants,
  buildUTCDate,
  checkIsDateInRange,
  dateFormat,
  dateToISOString,
  dayOfYear,
  getCurrentFormattedTime,
  getCurrentTimezoneOffset,
  getDifferenceInDays,
  monthLength,
  resetHoursToEnd,
  resetHoursToStart,
  resetHoursToTimezoneOffset,
  resetUTCHoursToStart,
  setCurrentHoursToDate,
  smartLocalDateFormat,
  subDays,
  subMinutes,
  subYears,
} from './utils';

type IDateToString = (date: DateType) => string;

type IDateService = {
  toShortTimeReadableByLocal: IDateToString;
  toShortTimeReadableByUTC: IDateToString;
  toTimeReadableByLocal: IDateToString;
  toTimeReadableByUTC: IDateToString;
  toShortReadableByUTC: IDateToString;
  toShortReadableByLocal: IDateToString;
  toFullReadableByUTC: IDateToString;
  toFullReadableByLocal: IDateToString;
  toFullReadableByLocalWithSeconds: IDateToString;
  /**
   * полная текстовая дата со штампом времени относительно локальной даты пользователя, в формате ДД:ММ:ГГГГ чч:мм
   */
  toFullReadableByLocalWithTimeStamp: IDateToString;
  /**
   * текстовая дата в формате ДД:ММ чч:мм
   */
  toMonthDayTimeViewByLocal: IDateToString;
  /**
   * утилита получения даты, меняющей свой вид в зависимости от текущей даты.
   * @enum если у переданной даты не будет совпадать ни год, ни месяц, ни день, то формат ДД:ММ:ГГГГ чч:мм
   * @enum если у переданной даты будет совпадать год с текущей датой, то формат ДД:ММ, чч:мм
   * @enum если у переданный даты будет совпадать и год и месяц и день, то формат чч:мм
   */
  toSmartValueByLocal: IDateToString;
  /**
   * текстовая дата в формате ГГГГ месяц ДД, чч:мм:сс
   */
  toConvenientByLocal: IDateToString;
  /**
   * метод конвертации Date в короткий ISO формат без штампа времени
   * @example ГГГГ-ММ-ДД
   */
  toShortISO: IDateToString;
  /**
   * метод конвертирующий Date в ISO формат, со штампом времени и наличием Z в конце
   * отличие от Date.toISOString() в том, что тут игнорируется точность миллисекунд
   * @example ГГГГ-ММ-ДДTчч:мм:ссZ
   */
  toFullISO: IDateToString;
  toShortISOWithTimezoneOffset: IDateToString;
  toFullISOWithTimezoneOffset: IDateToString;
  toMonthDayByUTC: IDateToString;
  differenceInDays: (dateA: DateType, dateB: DateType) => number;
  subYears: (date: DateType, yearsAgo: number) => Date;
  addYears: (date: DateType, years: number) => Date;
  addDays: (date: DateType, days: number) => Date;
  subDays: (date: DateType, days: number) => Date;
  subMinutes: (date: DateType, minutes: number) => Date;
  addMinutes: (date: DateType, minutes: number) => Date;
  resetHoursToStart: (date: DateType) => Date;
  resetUTCHoursToStart: (date: DateType) => Date;
  resetHoursToEnd: (date: DateType) => Date;
  resetHoursToTimezoneOffset: (date: DateType) => Date;
  setCurrentHoursToDate: (date: Date) => Date;
  currentFormattedTime: () => string;
  getCurrentTimezoneOffset: () => string;
  /**
   * Получение номера дня в году
   */
  dayOfYear: (date: DateType) => number;
  monthLength: typeof monthLength;
  buildUTCDate: typeof buildUTCDate;
  checkIsDateInRange: typeof checkIsDateInRange;
};

export const DateService: IDateService = {
  toShortTimeReadableByLocal: (date) =>
    dateFormat(date, FormatVariants.shortTimeLocal),
  toShortTimeReadableByUTC: (date) =>
    dateFormat(date, FormatVariants.shortTimeUTC),
  toTimeReadableByLocal: (date) => dateFormat(date, FormatVariants.timeLocal),
  toTimeReadableByUTC: (date) => dateFormat(date, FormatVariants.timeUtc),
  toShortReadableByUTC: (date) => dateFormat(date, FormatVariants.shortUTC),
  toShortReadableByLocal: (date) => dateFormat(date, FormatVariants.shortLocal),
  toFullReadableByUTC: (date) => dateFormat(date, FormatVariants.fullUTC),
  toFullReadableByLocal: (date) => dateFormat(date, FormatVariants.fullLocal),
  toMonthDayTimeViewByLocal: (date) =>
    dateFormat(date, FormatVariants.monthDayTimeViewByLocal),
  toFullReadableByLocalWithSeconds: (date) =>
    dateFormat(date, FormatVariants.fullLocalWithSeconds),
  toFullReadableByLocalWithTimeStamp: (date) =>
    dateFormat(date, FormatVariants.fullLocalWithTimeStamp),
  toConvenientByLocal: (date) =>
    dateFormat(date, FormatVariants.convenientByLocal),
  toMonthDayByUTC: (date) => dateFormat(date, FormatVariants.monthDayByUTC),
  toSmartValueByLocal: smartLocalDateFormat,
  toShortISO: (date) => dateToISOString(date),
  toFullISO: (date) => dateToISOString(date, { withTime: true }),
  toShortISOWithTimezoneOffset: (date) =>
    dateToISOString(date, { withLocalOffset: true }),
  toFullISOWithTimezoneOffset: (date) =>
    dateToISOString(date, { withTime: true, withLocalOffset: true }),
  differenceInDays: getDifferenceInDays,
  currentFormattedTime: getCurrentFormattedTime,
  addDays: (date, days) => subDays(date, -days),
  addYears: (date, years) => subYears(date, -years),
  addMinutes: (date, minutes) => subMinutes(date, -minutes),
  subMinutes,
  subYears,
  subDays,
  resetHoursToStart,
  resetUTCHoursToStart,
  resetHoursToEnd,
  setCurrentHoursToDate,
  getCurrentTimezoneOffset,
  resetHoursToTimezoneOffset,
  dayOfYear,
  monthLength,
  buildUTCDate,
  checkIsDateInRange,
};
