import { type PropsWithChildren, useCallback } from 'react';
import { useControllableBackdropStack } from '@hooks/useControllableBackdropStack';

import { type DialogProps } from '../Dialog';
import { DialogContent } from '../DialogContent';
import { ErrorBoundary } from '../ErrorBoundary';

import {
  AlertModalDialogFooter,
  type AlertModalFooterProps,
} from './AlertModalDialogFooter';
import { DialogWrapper, FormWrapper } from './styles';

export type AlertModalProps = Omit<DialogProps, 'onClose'> &
  AlertModalFooterProps & {
    onSubmit?: () => void;
    onClose?: () => void;
  } & {
    unmountOnExit?: boolean;
    onExited?: () => void;
    onEntered?: () => void;
  };

const WithForm = ({
  children,
  onSubmit,
}: PropsWithChildren<{ onSubmit?: () => void }>) => {
  if (onSubmit) {
    return (
      <FormWrapper
        onSubmit={onSubmit}
        data-test="AlertModalForm"
        onKeyDown={(e) => {
          e.stopPropagation();
        }}
      >
        {children}
      </FormWrapper>
    );
  }

  return children as JSX.Element;
};

export const AlertModal = (props: PropsWithChildren<AlertModalProps>) => {
  const {
    children,
    confirmButtonProps,
    cancelButtonProps,
    footerTemplate,
    onSubmit,
    onClose,
    unmountOnExit = true,
    keepMounted = false,
    onExited,
    size = 'sm',
    ...restProps
  } = props;

  const { handleClose } = useControllableBackdropStack({
    isOpened: restProps.open,
    onClose,
  });

  const DialogFooter = useCallback(() => {
    if (!footerTemplate) {
      return null;
    }

    return (
      <AlertModalDialogFooter
        footerTemplate={footerTemplate}
        confirmButtonProps={confirmButtonProps}
        cancelButtonProps={cancelButtonProps}
      />
    );
  }, [cancelButtonProps, confirmButtonProps, footerTemplate]);

  return (
    <DialogWrapper
      {...restProps}
      keepMounted={keepMounted}
      size={size}
      TransitionProps={{
        unmountOnExit,
        onExited: () => onExited?.(),
        onEntered: restProps.onEntered,
        ...restProps.TransitionProps,
      }}
      onClose={handleClose}
      fullWidth={restProps.fullWidth ?? true}
      $hasForm={Boolean(onSubmit)}
    >
      <WithForm onSubmit={onSubmit}>
        <DialogContent>
          <ErrorBoundary>{children}</ErrorBoundary>
        </DialogContent>
        <DialogFooter />
      </WithForm>
    </DialogWrapper>
  );
};
