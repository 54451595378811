import { styled } from '../../../styled';

export const TextCellWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & button {
    margin-left: ${({ theme }) => theme.spacing(1)};

    transition:
      opacity 0.3s linear,
      visibility 0.3s linear;
  }

  &:not(:hover) button {
    visibility: hidden;
    opacity: 0;
  }

  & > * {
    width: 100%;
  }
`;
