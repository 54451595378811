import { type ReactNode } from 'react';
import { observer } from 'mobx-react-lite';

import { Fade } from '../../Fade';

import { Wrapper } from './styles';

type NotificationItemWrapperProps = {
  isActive: boolean;
  onEntered: () => void;
  onExited: () => void;
  onClick: () => void;
  children: ReactNode;
};

export const NotificationItemWrapper = observer(
  ({
    isActive,
    onEntered,
    onExited,
    onClick,
    children,
  }: NotificationItemWrapperProps) => (
    <Fade in={isActive} timeout={500} onEntered={onEntered} onExited={onExited}>
      <Wrapper onClick={onClick}>{children}</Wrapper>
    </Fade>
  ),
);
