import { type MouseEvent } from 'react';

import { ToggleArrowWrapper } from './styles';

export type ToggleArrowProps = {
  collapsed: boolean;
  onClick?: () => void;
};

export const ToggleArrow = ({ collapsed, onClick }: ToggleArrowProps) => {
  const handleClick = (e: MouseEvent) => {
    if (!onClick) {
      return;
    }

    e.stopPropagation();
    e.preventDefault();
    onClick();
  };

  return <ToggleArrowWrapper collapsed={collapsed} onClick={handleClick} />;
};
