import { memo } from 'react';

import { Tooltip } from '../../Tooltip';
import { MultipleAutocompleteTag } from '../MultipleAutocompleteTag';
import {
  type MultipleAutocompleteOption,
  type MultipleAutocompleteOptionId,
} from '../types';
import { MULTIPLE_AUTOCOMPLETE_TAGS_COUNT } from '../constants';
import { Typography } from '../../Typography';

type MultipleAutocompleteEllipsisTagProps = {
  options: MultipleAutocompleteOption[];
  selected: MultipleAutocompleteOptionId[];
  size: number;
  hidePersonalData?: boolean;
};

export const MultipleAutocompleteEllipsisTag = memo(
  ({
    selected,
    options,
    size,
    hidePersonalData,
  }: MultipleAutocompleteEllipsisTagProps) => (
    <MultipleAutocompleteTag
      label={
        <Tooltip
          placement="bottom-start"
          title={
            <>
              {selected.map((_id) => (
                <Typography key={_id} hidePersonalData={hidePersonalData}>
                  {options.find(({ id }) => id === _id)?.title}
                </Typography>
              ))}
            </>
          }
        >
          <div>+{size - MULTIPLE_AUTOCOMPLETE_TAGS_COUNT}</div>
        </Tooltip>
      }
    />
  ),
);
