import { observer } from 'mobx-react-lite';
import { useFilterContext } from '@hooks/useFilterContext';
import { type Filters, type TypicalFilterParams } from '@stores/Filters';

import { SearchField } from '../SearchField';

type FilterFindTextSearchFieldProps = {
  hideHelperText?: boolean;
  placeholder?: string;
};

export const FilterFindTextSearchField = observer(
  ({ hideHelperText, placeholder }: FilterFindTextSearchFieldProps) => {
    const filters = useFilterContext<Filters<TypicalFilterParams>>();

    return (
      <SearchField
        onSetValue={filters.setFindText}
        value={filters.findText}
        hideHelperText={hideHelperText}
        placeholder={placeholder}
      />
    );
  },
);
