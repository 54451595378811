import { forwardRef, useContext } from 'react';
import { Chevron } from '@astral/ui';

import { Avatar } from '../Avatar';
import { Skeleton } from '../Skeleton';
import { Typography } from '../Typography';
import { Grid } from '../Grid';
import { MediaQueryContext } from '../MediaQueryContext';

import { ProfileRoot, ProfileRootButton, ProfileUser } from './styles';
import { type ProfileProps } from './types';

const ProfileContent = ({
  displayName,
  annotation,
  avatar,
  isLoading,
  isUnavailable,
}: ProfileProps) => {
  const { isMobile } = useContext(MediaQueryContext);

  return (
    <ProfileUser>
      {!isMobile ? (
        <Grid spacing={1}>
          <Typography variant="h6" hidePersonalData>
            {isLoading || isUnavailable ? (
              <Skeleton width={100} isUnavailable={isUnavailable} />
            ) : (
              displayName
            )}
          </Typography>
          <Typography
            variant="small"
            component="span"
            hidePersonalData
            color="grey"
            colorIntensity="500"
          >
            {isLoading || isUnavailable ? (
              <Skeleton isUnavailable={isUnavailable} />
            ) : (
              annotation
            )}
          </Typography>
        </Grid>
      ) : null}
      <Avatar {...avatar} />
    </ProfileUser>
  );
};

export const Profile = forwardRef<HTMLButtonElement, ProfileProps>(
  (props, ref) => {
    if (props.isLoading || props.isUnavailable) {
      return (
        <ProfileRoot>
          <ProfileContent {...props} />
        </ProfileRoot>
      );
    }

    return (
      <ProfileRootButton ref={ref} onClick={props.onClick}>
        <ProfileContent {...props} />
        <Chevron isActive={props.isChevronActive} />
      </ProfileRootButton>
    );
  },
);

export default Profile;
