import { type Certificate } from '@astral-private/crypto';

import { type EmployeeInfoForVerifications } from '../../../../../types';
import { checkIsCertificateDisabled } from '../../checkIsCertificateDisabled';

export const sortDisabledUQESCertificates = (
  certificates: Certificate[],
  infoForVerification: EmployeeInfoForVerifications | undefined,
): Certificate[] => {
  let resultArray = certificates.concat([]);

  // проходимся по сертам и сортируем активные вверх, неактивные вниз
  return resultArray.sort((certificate: Certificate) =>
    checkIsCertificateDisabled(certificate, infoForVerification) ? 1 : -1,
  );
};
