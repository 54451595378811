import { Suspense } from 'react';

import {
  MenuList,
  Page,
  type WithJSXChildren,
  appPaths,
  uuid,
} from '@dashboard/shared';

const menuItems = [
  {
    title: 'Ручное',
    id: uuid(),
    name: 'manual',
    path: appPaths.documentTemplates.manual,
  },
  {
    title: 'Шаблоны',
    id: uuid(),
    name: 'templates',
    path: appPaths.documentTemplates.templates,
  },
  {
    title: 'Теги',
    id: uuid(),
    name: 'tags',
    path: appPaths.documentTemplates.tags,
  },
  {
    title: 'История',
    id: uuid(),
    name: 'history',
    path: appPaths.documentTemplates.history,
  },
  {
    title: 'Системные',
    id: uuid(),
    name: 'system',
    path: appPaths.documentTemplates.system,
  },
];

export const DocumentTemplatesLayout = ({ children }: WithJSXChildren) => (
  <Page isSecondElementStretched>
    <Page.header title="Шаблоны документов" withSeparator />
    <Page.doubleSideContainer>
      <Page.left>
        <Suspense fallback={null}>{children}</Suspense>
      </Page.left>
      <Page.right>
        <MenuList menu={menuItems} isOpen />
      </Page.right>
    </Page.doubleSideContainer>
  </Page>
);
