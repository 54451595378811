import { type ReactNode, useId } from 'react';
import { getWebFormatByFileName, openFileInNewTab } from '@utils';
import { IMAGES_ACCEPT } from '@constants';
import { UnknownDocumentIllustration } from '@illustrations';

import { Tooltip } from '../../../Tooltip';
import { Typography } from '../../../Typography';

import {
  ButtonWrapper,
  EyeIconWrapper,
  FileContainer,
  FileImage,
  List,
  SimpleImage,
  SkeletonWrapper,
} from './styles';
import { i18n } from './constants';

type LinkProps = {
  file: File;
  fileName: string;
  children?: ReactNode;
};

const ButtonElement = ({ children, fileName, file }: LinkProps) => {
  const open = () => openFileInNewTab(file, fileName);

  return (
    <Tooltip title={fileName}>
      <ButtonWrapper type="button" onClick={open}>
        <FileContainer>{children}</FileContainer>
        <EyeIconWrapper />
      </ButtonWrapper>
    </Tooltip>
  );
};

export type FileItem = {
  file?: { data: File | null | undefined };
  fileName?: string;
  isLoading?: boolean;
  isDeleted?: boolean;
};

const FileRender = ({ file, isDeleted, isLoading, fileName }: FileItem) => {
  if (isLoading) {
    return (
      <FileContainer>
        <SkeletonWrapper />
      </FileContainer>
    );
  }

  if (isDeleted) {
    return (
      <FileContainer>
        <Typography variant="ui" color="grey" colorIntensity="700">
          {i18n.deleted}
        </Typography>
      </FileContainer>
    );
  }

  if (!fileName || !file?.data) {
    return null;
  }

  const isImage = Boolean(getWebFormatByFileName(fileName, IMAGES_ACCEPT));

  return (
    <ButtonElement fileName={fileName} file={file.data}>
      {isImage ? (
        <FileImage file={file.data} />
      ) : (
        <SimpleImage src={UnknownDocumentIllustration} />
      )}
    </ButtonElement>
  );
};

type FilesPreviewProps = {
  files?: FileItem[];
};

export const FilesPreview = ({ files }: FilesPreviewProps) => {
  const id = useId();

  if (!files) {
    return null;
  }

  if (files?.length === 1) {
    return <FileRender {...files[0]} />;
  }

  return (
    <List>
      {files.map((file, index) => (
        <li key={`${id}_${index}`}>
          <FileRender {...file} />
        </li>
      ))}
    </List>
  );
};
