import { useMemo } from 'react';

import { type PageHeaderProps, type PageHeaderTemplate } from '../../types';
import { PAGE_HEADER_TEMPLATES } from '../../constants';

type GetTemplateOptions = {
  initialTemplate?: PageHeaderTemplate;
  hasTitle: boolean;
  hasSearch: boolean;
  hasBackTo: boolean;
  hasChildren: boolean;
  tag: boolean;
};

const getTemplate = ({
  initialTemplate,
  hasTitle,
  hasSearch,
  hasBackTo,
  hasChildren,
  tag,
}: GetTemplateOptions): PageHeaderTemplate => {
  if (initialTemplate) {
    return initialTemplate;
  }

  if (hasChildren) {
    return PAGE_HEADER_TEMPLATES.backTitleActionsUnderChildren;
  }

  if (hasBackTo && hasTitle) {
    return PAGE_HEADER_TEMPLATES.backTitleActionsUnder;
  }

  if (!hasTitle && hasSearch) {
    return PAGE_HEADER_TEMPLATES.actionsBelowActionsUnder;
  }

  if (!hasSearch) {
    return PAGE_HEADER_TEMPLATES.titleActionsUnder;
  }

  if (tag) {
    return PAGE_HEADER_TEMPLATES.backTitleActionsBelowChildrenWithTag;
  }

  return PAGE_HEADER_TEMPLATES.titleActionsUnderActionsBelow;
};

type UseTemplateOptions = Pick<
  PageHeaderProps,
  'actionsBelow' | 'title' | 'template' | 'hasBack' | 'children' | 'tag'
>;

export const useTemplate = ({
  template,
  actionsBelow,
  title,
  hasBack,
  children,
  tag,
}: UseTemplateOptions) =>
  useMemo(
    () =>
      getTemplate({
        initialTemplate: template,
        hasSearch: Boolean(actionsBelow),
        hasTitle: Boolean(title),
        hasBackTo: Boolean(hasBack),
        hasChildren: Boolean(children),
        tag: Boolean(tag),
      }),
    [children],
  );
