/**
 * Утилита для проверки вхождения выбранной даты в указанный диапазон,
 * dateA может быть как больше dateB, так и меньше
 */
export const checkIsDateInRange = (
  date: Date,
  dateA?: Date | null,
  dateB?: Date | null,
): boolean => {
  if (!dateA || !dateB) {
    return false;
  }

  const isALessThanB = dateA <= dateB;

  const optimizedA = isALessThanB ? dateA : dateB;
  const optimizedB = isALessThanB ? dateB : dateA;

  const isMoreOrEqualToDateA = date >= optimizedA;
  const isLessOrEqualToDateB = date <= optimizedB;

  return isMoreOrEqualToDateA && isLessOrEqualToDateB;
};
