import { Switch as AstralSwitch } from '@astral/ui';

import { styled } from '../styled';

export const SwitchWrapper = styled(AstralSwitch, {
  shouldForwardProp: (prop) => !prop.match('isLoading'),
})<{ isLoading?: boolean }>`
  @keyframes rotate {
    0% {
      transform: rotate(0);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .MuiSwitch-thumb {
    border-width: 4px;
    border-style: solid;
    border-color: transparent;
    border-top-color: ${({ theme, isLoading }) =>
      isLoading ? theme.palette.primary[800] : ''};
    animation: ${({ isLoading }) =>
      isLoading ? 'rotate 1s linear infinite' : ''};
  }
`;
