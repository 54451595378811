/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CountryDto } from '../models/CountryDto';

import type { CancelablePromise } from '@common/data/api-core/CancelablePromise';
import type { BaseHttpRequest } from '@common/data/api-core/BaseHttpRequest';

export class CountriesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns CountryDto
   * @throws ApiError
   */
  public countriesGet({
    employeeworkplaceid,
    kedoGatewayTokenType,
  }: {
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении. Требуется только для Web приложения **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
  }): CancelablePromise<Array<CountryDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/staff/Countries',
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
    });
  }

  /**
   * @returns CountryDto
   * @throws ApiError
   */
  public countriesGetById({
    id,
    employeeworkplaceid,
    kedoGatewayTokenType,
  }: {
    id: string;
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении. Требуется только для Web приложения **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
  }): CancelablePromise<CountryDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/staff/Countries/{id}',
      path: {
        id: id,
      },
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
    });
  }
}
