import { backdropStackManager } from '@astral/ui';
import { type SyntheticEvent, useEffect, useId } from 'react';
import { type AutocompleteCloseReason } from '@components/external';

type Reason = AutocompleteCloseReason | 'escapeKeyDown' | 'backdropClick';

type UseControllableBackdropStackOptions = {
  isOpened: boolean;
  onClose?: (
    _?: SyntheticEvent<Element, Event> | {} | Event,
    reason?: Reason,
  ) => void;
};

export const useControllableBackdropStack = ({
  isOpened,
  onClose,
}: UseControllableBackdropStackOptions) => {
  const id = useId();
  const { push, remove, pop } = backdropStackManager;

  const handleClose = (
    _?: SyntheticEvent<Element, Event> | {},
    reason?: Reason,
  ) => {
    if (pop(id, reason)) {
      onClose?.(_, reason);
    }
  };

  useEffect(() => {
    if (isOpened) {
      push(id);
    }

    return () => {
      remove(id);
    };
  }, [isOpened]);

  return { handleClose };
};
