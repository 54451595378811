import { MSECONDS_PER_DAY } from '../../constants';
import { type DateType } from '../types';

export const dayOfYear = (date: DateType) => {
  const optimized = new Date(date);

  return Math.floor(
    (+optimized - +new Date(optimized.getFullYear(), 0, 0)) / MSECONDS_PER_DAY,
  );
};
