import { useEffect } from 'react';
import { checkTypeOnNumber, checkTypeOnString } from '@utils/filter/checkTypes';

type EventData = {
  name: string;
  value: string | number;
};

const isCustomData = (data: unknown): data is EventData =>
  (checkTypeOnString((data as EventData).value) ||
    checkTypeOnNumber((data as EventData).value)) &&
  checkTypeOnString((data as EventData).name as string);

/**
 * хук для адаптации возможности заполнять формы тестовыми данными
 */
export const useCustomEventData = (
  defaultValues: {} | null | undefined,

  setValue: (name: never, value: never) => void,
) => {
  useEffect(() => {
    const handleEvent = (event: MessageEvent<unknown>) => {
      if (isCustomData(event.data)) {
        if (defaultValues && Object.hasOwn(defaultValues, event.data.name)) {
          setValue(event.data.name as never, event.data.value as never);
        }
      }
    };

    window.addEventListener('message', handleEvent);

    return () => {
      window.removeEventListener('message', handleEvent);
    };
  }, []);
};
