import { computed, makeObservable } from 'mobx';
import { type CacheService } from '@common/shared';

import { DEFAULT_CONFIG_PATH } from './constants';

export class ConfigFetcher<TConfig> {
  constructor(private readonly _cacheService: CacheService) {
    makeObservable(this, { config: computed });
  }

  public get config() {
    return this._cacheService.createQuerySet<void[], TConfig>(() => ({
      execute: (): Promise<TConfig> =>
        fetch(DEFAULT_CONFIG_PATH).then((r) => r.json() as TConfig),
    }));
  }
}
