import axios, { type AxiosInstance, type AxiosRequestConfig } from 'axios';

import { HttpInterceptor } from '../utils/createHttpInterceptor';

const defaultConfig: AxiosRequestConfig = {
  headers: { 'Content-Type': 'application/json' },
};

export class AxiosClient {
  public readonly instance: AxiosInstance;

  constructor(
    baseURL: string,
    axiosConfig: AxiosRequestConfig = defaultConfig,
  ) {
    this.instance = axios.create({ baseURL, ...axiosConfig });
  }

  get interceptors() {
    return new HttpInterceptor(this.instance);
  }
}

export const axiosClient = new AxiosClient(globalThis.location?.origin);
