import { type RefObject, useEffect, useRef } from 'react';

export type UseFetchMoreParams = {
  scrollRef: RefObject<HTMLElement>;
  fetchMore: () => void;
  isLoading?: boolean;
};

export const useFetchMore = ({
  fetchMore,
  scrollRef,
  isLoading,
}: UseFetchMoreParams) => {
  const targetRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    const scrollItem = scrollRef.current;

    if (!scrollItem || !targetRef.current || isLoading) {
      return;
    }

    const target = targetRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          fetchMore();
        }
      },
      {
        threshold: 0.2,
        root: scrollItem,
      },
    );

    observer.observe(target);

    return () => {
      observer.disconnect();
    };
  }, [fetchMore, isLoading, scrollRef, targetRef]);

  return { ref: targetRef };
};
