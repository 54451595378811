/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddTenantDto } from '../models/AddTenantDto';
import type { AffiliateDto } from '../models/AffiliateDto';
import type { Stream } from '../models/Stream';
import type { TenantDto } from '../models/TenantDto';
import type { UpdateAffilateDto } from '../models/UpdateAffilateDto';
import type { UpdateTenantDocNotificationDto } from '../models/UpdateTenantDocNotificationDto';
import type { UpdateTenantIdentificationTypeDto } from '../models/UpdateTenantIdentificationTypeDto';

import type { CancelablePromise } from '@common/data/api-core/CancelablePromise';
import type { BaseHttpRequest } from '@common/data/api-core/BaseHttpRequest';

export class TenantsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Получение списка тенантов
   * @returns TenantDto
   * @throws ApiError
   */
  public tenantsGetTenantsList({
    findText,
    offset,
    count = 50,
    onPremOnly = false,
    employeeworkplaceid,
    kedoGatewayTokenType,
  }: {
    findText?: string | null;
    offset?: number;
    count?: number;
    /** получаем только on-premises организации **/
    onPremOnly?: boolean;
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении. Требуется только для Web приложения **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
  }): CancelablePromise<Array<TenantDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/administrative/Tenants',
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
      query: {
        findText: findText,
        offset: offset,
        count: count,
        onPremOnly: onPremOnly,
      },
    });
  }

  /**
   * Добавление нового тенанта
   * @returns string
   * @throws ApiError
   */
  public tenantsAddTenant({
    requestBody,
    employeeworkplaceid,
    kedoGatewayTokenType,
  }: {
    requestBody: AddTenantDto;
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении. Требуется только для Web приложения **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
  }): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/administrative/Tenants',
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Получение тенанта по идентификатору
   * @returns TenantDto
   * @throws ApiError
   */
  public tenantsGetTenantById({
    tenantId,
    employeeworkplaceid,
    kedoGatewayTokenType,
  }: {
    tenantId: string;
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении. Требуется только для Web приложения **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
  }): CancelablePromise<TenantDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/administrative/Tenants/{tenantId}',
      path: {
        tenantId: tenantId,
      },
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
    });
  }

  /**
   * Получение Списка тенантов в CSV
   * @returns string
   * @throws ApiError
   */
  public tenantsExportTenants({
    employeeworkplaceid,
    kedoGatewayTokenType,
  }: {
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении. Требуется только для Web приложения **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
  }): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/administrative/Tenants/Export',
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
    });
  }

  /**
   * Обновление Способа идентификации для тенанта
   * @returns boolean
   * @throws ApiError
   */
  public tenantsUpdateTenantIdentificationType({
    tenantId,
    requestBody,
    employeeworkplaceid,
    kedoGatewayTokenType,
  }: {
    tenantId: string;
    requestBody: UpdateTenantIdentificationTypeDto;
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении. Требуется только для Web приложения **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
  }): CancelablePromise<boolean> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v2/administrative/Tenants/{tenantId}/TenantIdentificationType',
      path: {
        tenantId: tenantId,
      },
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Переключение отправки оповещений об отправке документов на организацию для кадровиков
   * @returns boolean
   * @throws ApiError
   */
  public tenantsUpdateTenantDocNotification({
    tenantId,
    requestBody,
    employeeworkplaceid,
    kedoGatewayTokenType,
  }: {
    tenantId: string;
    requestBody: UpdateTenantDocNotificationDto;
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении. Требуется только для Web приложения **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
  }): CancelablePromise<boolean> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v2/administrative/Tenants/{tenantId}/TenantDocNotification',
      path: {
        tenantId: tenantId,
      },
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * выгрузку списка Партнерской программы
   * @returns AffiliateDto
   * @throws ApiError
   */
  public tenantsGetAffiliate({
    employeeworkplaceid,
    kedoGatewayTokenType,
  }: {
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении. Требуется только для Web приложения **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
  }): CancelablePromise<Array<AffiliateDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/administrative/Tenants/Affiliate',
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
    });
  }

  /**
   * выгрузку списка Партнерской программы в csv
   * @returns Stream
   * @throws ApiError
   */
  public tenantsGetAffiliateCsv({
    employeeworkplaceid,
    kedoGatewayTokenType,
  }: {
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении. Требуется только для Web приложения **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
  }): CancelablePromise<Stream> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/administrative/Tenants/AffiliateCsv',
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
    });
  }

  /**
   * Обновление информации о партнере
   * @returns boolean
   * @throws ApiError
   */
  public tenantsUpdateAffilate({
    tenantId,
    requestBody,
    employeeworkplaceid,
    kedoGatewayTokenType,
  }: {
    tenantId: string;
    requestBody: UpdateAffilateDto;
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении. Требуется только для Web приложения **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
  }): CancelablePromise<boolean> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v2/administrative/Tenants/{tenantId}/Affiliate',
      path: {
        tenantId: tenantId,
      },
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
