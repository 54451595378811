/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmployeeDto } from '../models/EmployeeDto';
import type { UpdateContactRequestDto } from '../models/UpdateContactRequestDto';

import type { CancelablePromise } from '@common/data/api-core/CancelablePromise';
import type { BaseHttpRequest } from '@common/data/api-core/BaseHttpRequest';

export class EmployeesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns EmployeeDto
   * @throws ApiError
   */
  public employeesGetEmployees({
    employeeName,
    phoneNumber,
    email,
    tenantId,
    offset,
    count,
    employeeworkplaceid,
    kedoGatewayTokenType,
  }: {
    employeeName?: string | null;
    phoneNumber?: string | null;
    email?: string | null;
    tenantId?: string | null;
    offset?: number;
    count?: number;
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении. Требуется только для Web приложения **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
  }): CancelablePromise<Array<EmployeeDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/staff/Employees',
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
      query: {
        EmployeeName: employeeName,
        PhoneNumber: phoneNumber,
        Email: email,
        TenantId: tenantId,
        Offset: offset,
        Count: count,
      },
    });
  }

  /**
   * Отменить запрос на выпуск УНЭП
   * @returns boolean
   * @throws ApiError
   */
  public employeesCancel({
    requestCertificateId,
    employeeworkplaceid,
    kedoGatewayTokenType,
  }: {
    requestCertificateId: string;
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении. Требуется только для Web приложения **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
  }): CancelablePromise<boolean> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/staff/Employees/RequestCertificates/{requestCertificateId}/Cancellations',
      path: {
        requestCertificateId: requestCertificateId,
      },
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
    });
  }

  /**
   * Обновить контактные данные работника
   * @returns any
   * @throws ApiError
   */
  public employeesUpdateContact({
    requestBody,
    employeeworkplaceid,
    kedoGatewayTokenType,
  }: {
    requestBody: UpdateContactRequestDto;
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении. Требуется только для Web приложения **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v2/staff/Employees/Contacts',
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
