import { RUSSIAN_PHONE } from '@constants/form';
import { useForm } from '@hooks/useForm';
import { createSchema, mobilePhone, string } from '@schemas/schema';

import { type FormCellCommonProps, type FormCellToggleProps } from '../types';
import { CellInner } from '../CellInner';
import { createSubmitHandler } from '../utils';
import { FormInputFormat } from '../../FormInputFormat';

type CellPhoneInnerProps = FormCellCommonProps & FormCellToggleProps;

const schema = createSchema({
  value: string(mobilePhone()),
});

/**
 * реализация внутренней части для номера телефона
 */
export const CellPhoneInner = ({
  onSubmit,
  value,
  isLoading,
  onSuccess,
  onCancel,
}: CellPhoneInnerProps) => {
  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm({
    defaultValues: { value: value! },
    validateSchema: schema,
    onSubmit: createSubmitHandler({ onSuccess, onSubmit }),
  });

  return (
    <CellInner
      onSubmit={handleSubmit}
      isLoading={isLoading}
      onCancel={onCancel}
      isDirty={isDirty}
    >
      <FormInputFormat
        inputProps={{
          inputMode: 'tel',
        }}
        control={control}
        name="value"
        mask={RUSSIAN_PHONE}
        placeholder="+7 (9XX) XXX-XX-XX"
        hidePersonalData
      />
    </CellInner>
  );
};
