import { useContext, useState } from 'react';
import { useLocation } from '@hooks/useLocation';

import { TooltipSwitcher } from '../../TooltipSwitcher';
import { Collapse } from '../../Collapse';
import { ToggleArrow } from '../../ToggleArrow';
import { type MenuListItemProps } from '../types';
import { Grid } from '../../Grid';
import { OverflowTypography } from '../../OverflowTypography';
import { PageRightContext } from '../../Page';

import { MenuItemWrapper, MenuLink, MenuLinkIcon } from './styles';

export const MenuListItem = ({
  menuItem,
  isOpen,
  indent,
}: MenuListItemProps) => {
  const closePageRight = useContext(PageRightContext);
  const { pathname } = useLocation();

  const isEndsWithPath = pathname?.endsWith(menuItem.path);

  const hasMentionInPath = Boolean(pathname?.match(menuItem.path));

  const [collapsed, setCollapsed] = useState(hasMentionInPath);

  const hasChildren = Boolean(menuItem.children?.length);

  // если выставлен флаг exactPath, то вне зависимости от остальных параметров,
  // мы проверяем только на isEndsWithPath, иначе на несколько условий
  const active = menuItem.exactPath
    ? isEndsWithPath
    : ((isEndsWithPath || hasMentionInPath) && !hasChildren) ||
      Boolean(
        menuItem.children?.some((item) => pathname?.endsWith(item.path)) &&
          !collapsed,
      );

  const handleClick = () => {
    setCollapsed((prev) => !prev);
    closePageRight();
  };

  return (
    <>
      <TooltipSwitcher
        isActive={!isOpen}
        title={menuItem.title}
        disableInteractive
        placement="top-start"
      >
        <MenuItemWrapper onClick={handleClick}>
          <MenuLink
            active={active}
            isOpen={isOpen}
            href={menuItem.path}
            replace={menuItem.replace}
          >
            {!isOpen ? (
              <MenuLinkIcon>{menuItem.icon}</MenuLinkIcon>
            ) : (
              <Grid spacing={3} columns="auto 1fr" alignItems="center">
                <MenuLinkIcon>{menuItem.icon}</MenuLinkIcon>
                <OverflowTypography rowsCount={2} component="span">
                  {menuItem.title}
                </OverflowTypography>
              </Grid>
            )}
            {isOpen && menuItem?.children?.length && (
              <ToggleArrow collapsed={collapsed} onClick={handleClick} />
            )}
          </MenuLink>
        </MenuItemWrapper>
      </TooltipSwitcher>
      {menuItem.children && menuItem.children?.length > 0 && (
        <Collapse in={collapsed} mountOnEnter unmountOnExit>
          {menuItem.children?.map((child) => (
            <MenuListItem
              indent={indent + 1}
              isOpen={isOpen}
              menuItem={child}
              key={child.id}
            />
          ))}
        </Collapse>
      )}
    </>
  );
};
