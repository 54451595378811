import { type HTMLAttributes, useEffect, useMemo } from 'react';

import { ImageWrapper } from './styles';
import { createFileImageViewerStore } from './store';

type FileImageViewerProps = {
  file: File;
} & HTMLAttributes<HTMLImageElement>;

export const FileImageViewer = ({ file, ...props }: FileImageViewerProps) => {
  const store = useMemo(() => createFileImageViewerStore(file), [file]);

  useEffect(() => store.init(), [store]);

  return <ImageWrapper {...props} src={store.blobUrl} />;
};
