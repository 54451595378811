import { type CacheableQuery } from '@common/shared';

import { type OpenAPIConfig } from './OpenAPI';

export type BaseUrlData = { BASE_API_URL: string };

type BaseUrlQuery<TConfigData extends BaseUrlData> =
  CacheableQuery<TConfigData>;

export type GetBaseUrlQuery<TConfigData extends BaseUrlData> =
  () => BaseUrlQuery<TConfigData>;

export type MicroserviceClient<TServices> = new (
  config: Partial<OpenAPIConfig>,
) => TServices;

type ClientProviderParams<TServices, TConfigData extends BaseUrlData> = {
  getBaseApiUrlQuery: GetBaseUrlQuery<TConfigData>;
  MicroserviceClient: MicroserviceClient<TServices>;
};

export class ClientProvider<TServices, TConfigData extends BaseUrlData> {
  private params: ClientProviderParams<TServices, TConfigData>;

  constructor(params: ClientProviderParams<TServices, TConfigData>) {
    this.params = params;
  }

  private get query() {
    return this.params.getBaseApiUrlQuery();
  }

  private get url(): Promise<string> {
    const promise = this.query.data
      ? Promise.resolve(this.query.data)
      : this.query.async();

    return promise.then(({ BASE_API_URL }) => BASE_API_URL);
  }

  private get config(): Partial<OpenAPIConfig> {
    return {
      BASE: this.url,
    };
  }

  public get client() {
    return new this.params.MicroserviceClient(this.config);
  }
}
