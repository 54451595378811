import { Tabs } from '@mui/material';

import { styled } from '../styled';

export const TabsWrapper = styled(Tabs)`
  position: relative;

  height: auto;
  min-height: auto;

  &::after {
    content: '';

    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0;

    width: 100%;

    border-bottom: 2px solid ${({ theme }) => theme.palette.grey[300]};
  }

  & .MuiTabs-flexContainer {
    border-radius: 1px;
  }

  & .MuiTabs-indicator {
    bottom: ${({ theme }) => theme.spacing(0)};

    height: 2px;

    border-right: 1px;
  }

  & .Mui-selected {
    font-weight: ${({ theme }) => theme.typography.fontWeightMedium} !important;
    color: ${({ theme }) => theme.palette.primary[800]} !important;
  }

  & .MuiButtonBase-root {
    align-items: start;

    min-width: auto;
    min-height: auto;
    padding: 8px 0;

    text-align: left;
    text-transform: none;
  }

  & .MuiButtonBase-root:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing(4)};
  }
`;
