import {
  type BaseUrlData,
  ClientProvider,
  type GetBaseUrlQuery,
  type MicroserviceClient,
} from '../../api-core';

type MicroserviceRepositoryParams<TServices, TCofigData extends BaseUrlData> = {
  getConfigQuery: GetBaseUrlQuery<TCofigData>;
  microserviceClient: MicroserviceClient<TServices>;
};

export abstract class MicroserviceRepository<
  TMicroserviceClient,
  TCofigData extends BaseUrlData,
> {
  protected clientProvider: ClientProvider<TMicroserviceClient, TCofigData>;

  protected constructor({
    getConfigQuery,
    microserviceClient,
  }: MicroserviceRepositoryParams<TMicroserviceClient, TCofigData>) {
    this.clientProvider = new ClientProvider<TMicroserviceClient, TCofigData>({
      getBaseApiUrlQuery: getConfigQuery,
      MicroserviceClient: microserviceClient,
    });
  }

  protected get client() {
    return this.clientProvider.client;
  }
}
