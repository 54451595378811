import { useForm } from '@hooks/useForm';

import { arraySchema, createSchema } from '../../../../schemas/schema';
import { type FormCellCommonProps, type FormCellToggleProps } from '../types';
import { CellInner } from '../CellInner';
import { createSubmitHandler } from '../utils';
import {
  FormGroupedMultipleAutocomplete,
  type FormGroupedMultipleAutocompleteProps,
} from '../../FormGroupedMultipleAutocomplete';

export type CellGroupedMultipleAutocompleteInnerProps = FormCellCommonProps<
  string[]
> &
  FormCellToggleProps & {
    autocompleteProps: Pick<
      FormGroupedMultipleAutocompleteProps<{ value: string[] }>,
      | 'options'
      | 'isLoading'
      | 'isEndReached'
      | 'batchSize'
      | 'onSearch'
      | 'onEndReached'
      | 'isCleanAllDisabled'
    >;
  };

const schema = createSchema({
  value: arraySchema(),
});

/**
 * внутренняя часть для реализации варианта с селектом
 */
export const CellGroupedMultipleAutocompleteInner = ({
  onSubmit,
  value,
  isLoading,
  onSuccess,
  onCancel,
  autocompleteProps,
}: CellGroupedMultipleAutocompleteInnerProps) => {
  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm({
    defaultValues: { value: value! },
    validateSchema: schema,
    onSubmit: createSubmitHandler({ onSuccess, onSubmit }),
  });

  return (
    <CellInner
      onSubmit={handleSubmit}
      isLoading={isLoading}
      onCancel={onCancel}
      isDirty={isDirty}
    >
      <FormGroupedMultipleAutocomplete
        {...autocompleteProps}
        control={control}
        name="value"
      />
    </CellInner>
  );
};
