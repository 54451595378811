/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmployeeWorkplaceDto } from '../models/EmployeeWorkplaceDto';

import type { CancelablePromise } from '@common/data/api-core/CancelablePromise';
import type { BaseHttpRequest } from '@common/data/api-core/BaseHttpRequest';

export class EmployeeWorkplacesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Получить рабочие места по ID работника
   * @returns EmployeeWorkplaceDto
   * @throws ApiError
   */
  public employeeWorkplacesGetByEmployeeId({
    employeeId,
    offset,
    count = 50,
    employeeworkplaceid,
    kedoGatewayTokenType,
  }: {
    employeeId: string;
    offset?: number;
    count?: number;
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении. Требуется только для Web приложения **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
  }): CancelablePromise<Array<EmployeeWorkplaceDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/identity/Employees/{employeeId}/EmployeeWorkplaces',
      path: {
        employeeId: employeeId,
      },
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
      query: {
        offset: offset,
        count: count,
      },
    });
  }

  /**
   * Обновить группы разрешений для рабочего места
   * @returns any
   * @throws ApiError
   */
  public employeeWorkplacesUpdatePermissionPolicies({
    workplaceId,
    requestBody,
    employeeworkplaceid,
    kedoGatewayTokenType,
  }: {
    workplaceId: string;
    requestBody: Array<string>;
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении. Требуется только для Web приложения **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v2/identity/EmployeeWorkplaces/{workplaceId}/PermissionPolicies',
      path: {
        workplaceId: workplaceId,
      },
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
