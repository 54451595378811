import { useForwardedRef } from '@astral/ui';
import { type PropsWithChildren, forwardRef, useEffect } from 'react';

import { type TableResizerCols } from '../TableResizer';

import { TableContainerWrapper } from './styles';
import { TableContainerStore } from './store';

export type TableContainerProps = PropsWithChildren<{
  columns: TableResizerCols;
  hasCheckBox: boolean;
  isCheckboxGapSmall?: boolean;
}>;

export const TableContainer = forwardRef<HTMLDivElement, TableContainerProps>(
  (props, ref) => {
    const nodeRef = useForwardedRef(ref);

    useEffect(() => {
      if (nodeRef.current) {
        const store = new TableContainerStore({ node: nodeRef.current });

        return store.destroy;
      }
    }, []);

    return <TableContainerWrapper component="div" ref={nodeRef} {...props} />;
  },
);
