import { styled } from '../styled';
import { MuiFormControlLabel } from '../external';

import { type FormControlLabelProps } from './types';

export const StyledFormControlLabel = styled(
  MuiFormControlLabel,
)<FormControlLabelProps>`
  margin-right: 0;
  margin-left: -4px;

  & .MuiFormControlLabel-label {
    margin-left: ${({ theme }) => theme.spacing(1)};
  }

  & path {
    fill: currentColor;
  }
`;
