/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddTenantAdminSagaDto } from '../models/AddTenantAdminSagaDto';
import type { AddTenantAdminSagaListElementDto } from '../models/AddTenantAdminSagaListElementDto';
import type { CreateTenantAdminSagaDto } from '../models/CreateTenantAdminSagaDto';

import type { CancelablePromise } from '@common/data/api-core/CancelablePromise';
import type { BaseHttpRequest } from '@common/data/api-core/BaseHttpRequest';

export class AddTenantAdminSagasService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns AddTenantAdminSagaListElementDto
   * @throws ApiError
   */
  public addTenantAdminSagasGetCreateTenantAdminSagas({
    offset,
    count = 50,
    employeeworkplaceid,
    kedoGatewayTokenType,
  }: {
    offset?: number;
    count?: number;
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
  }): CancelablePromise<Array<AddTenantAdminSagaListElementDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/sagas/AddTenantAdminSagas',
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
      query: {
        offset: offset,
        count: count,
      },
    });
  }

  /**
   * @returns string
   * @throws ApiError
   */
  public addTenantAdminSagasAddTenantAdmin({
    requestBody,
    employeeworkplaceid,
    kedoGatewayTokenType,
  }: {
    requestBody: CreateTenantAdminSagaDto;
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
  }): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/sagas/AddTenantAdminSagas',
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns AddTenantAdminSagaDto
   * @throws ApiError
   */
  public addTenantAdminSagasGetCreateTenantAdminSaga({
    sagaId,
    employeeworkplaceid,
    kedoGatewayTokenType,
  }: {
    sagaId: string;
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
  }): CancelablePromise<AddTenantAdminSagaDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/sagas/AddTenantAdminSagas/{sagaId}',
      path: {
        sagaId: sagaId,
      },
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
    });
  }
}
