import { styled } from '../styled';
import { Typography } from '../Typography';

export const DragZoneContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DragZone = styled('div')<{
  $isActive: boolean;
  $isError?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: auto;
  padding: ${({ theme }) => theme.spacing(4)};

  background-color: ${({ theme }) => theme.palette.background.element};
  border: 1px dashed
    ${({ theme, $isActive, $isError }) =>
      ($isActive && theme.palette.primary[700]) ||
      ($isError && theme.palette.error.dark) ||
      theme.palette.grey[400]};
  border-radius: 3px;

  transition: border 0.24s ease-in-out;
`;

export const FileListWrapper = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing(1)};
  width: 100%;
`;

export const UploadZoneWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;

export const UploadZoneLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const UploadZoneText = styled(Typography)`
  flex: 1;

  margin-right: ${({ theme }) => theme.spacing(1)};

  color: ${({ theme }) => theme.palette.grey[600]};
  text-align: center;
`;
