import { type TenantId } from '@dashboard/shared';

export const tenantsKey = 'tenants';

export const tenantDataKey = (tenantId: TenantId) => ['tenant_data', tenantId];

export const tenantsCsv = 'tenants_csv';

export const organizationDetailsKey = (inn: string) => [
  'organization_details',
  inn,
];

export const tenantSettingsKey = (tenantId: TenantId) => [
  'tenantSettingsKey',
  tenantId,
];
