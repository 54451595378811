import { COMMON_ACCEPT } from './commonAccept';

export const MAP_OF_OPENABLE_EXCEPTIONS = {
  pdf: COMMON_ACCEPT.pdf,
  jpeg: COMMON_ACCEPT.jpeg,
  jpg: COMMON_ACCEPT.jpg,
  jpe: COMMON_ACCEPT.jpe,
  bmp: COMMON_ACCEPT.bmp,
  png: COMMON_ACCEPT.png,
};
