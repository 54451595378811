import { makeAutoObservable, runInAction } from 'mobx';

export class ClipboardStore {
  public isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  private finishLoad = () => {
    this.isLoading = false;
  };

  public copy = (text: string) => {
    this.isLoading = true;

    return globalThis.navigator?.clipboard
      .writeText(text)
      .finally(() => runInAction(this.finishLoad));
  };
}

export const createClipboardStore = () => new ClipboardStore();
