/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PermissionPolicyDto } from '../models/PermissionPolicyDto';

import type { CancelablePromise } from '@common/data/api-core/CancelablePromise';
import type { BaseHttpRequest } from '@common/data/api-core/BaseHttpRequest';

export class PermissionPoliciesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Получить системные группы разрешений
   * @returns PermissionPolicyDto
   * @throws ApiError
   */
  public permissionPoliciesGetAll({
    findText,
    offset,
    count,
    employeeworkplaceid,
    kedoGatewayTokenType,
  }: {
    findText?: string | null;
    offset?: number;
    count?: number;
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении. Требуется только для Web приложения **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
  }): CancelablePromise<Array<PermissionPolicyDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/identity/PermissionPolicies/System',
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
      query: {
        FindText: findText,
        Offset: offset,
        Count: count,
      },
    });
  }
}
