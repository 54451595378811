import { styled } from '../styled';

export const CodeFieldWrapper = styled('div')`
  ${({ theme }) => theme.breakpoints.up('sm')} {
    min-width: 432px;
    max-width: 432px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    max-width: calc(100vw - ${({ theme }) => theme.spacing(12)});
  }

  ul {
    width: max-content;
    max-width: 100%;
    margin: 0 auto;

    ${({ theme }) => theme.breakpoints.down('sm')} {
      gap: ${({ theme }) => theme.spacing(1)};
    }
  }

  input {
    text-align: center;

    ${({ theme }) => theme.breakpoints.down('sm')} {
      padding: ${({ theme }) => theme.spacing(4, 3)};
      width: 100%;
    }
  }
`;
