import { type SpinnerSizes } from './types';

export const SPINNER_ELEMENTS_COUNT = 8;

export const SPINNER_SIZES: Record<SpinnerSizes, string> = {
  xs: '12px',
  sm: '16px',
  md: '24px',
  xm: '120px',
  l: '160px',
  xl: '320px',
  xxl: '796px',
  fullVW: '100vw',
};
