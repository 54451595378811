/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RecruitmentFormTemplateLinkDto } from '../models/RecruitmentFormTemplateLinkDto';
import type { SendLinkDto } from '../models/SendLinkDto';
import type { SentLinkDto } from '../models/SentLinkDto';

import type { CancelablePromise } from '@common/data/api-core/CancelablePromise';
import type { BaseHttpRequest } from '@common/data/api-core/BaseHttpRequest';

export class RecruitmentService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns SentLinkDto
   * @throws ApiError
   */
  public recruitmentSendLink({
    tenantId,
    requestBody,
    employeeworkplaceid,
    kedoGatewayTokenType,
  }: {
    tenantId: string;
    requestBody: SendLinkDto;
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
  }): CancelablePromise<SentLinkDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/recruitment/Recruitment/{tenantId}/SendLink',
      path: {
        tenantId: tenantId,
      },
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns RecruitmentFormTemplateLinkDto
   * @throws ApiError
   */
  public recruitmentGetActiveLinkForAutoApprovedForm({
    tenantId,
    employeeworkplaceid,
    kedoGatewayTokenType,
  }: {
    tenantId: string;
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
  }): CancelablePromise<RecruitmentFormTemplateLinkDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/recruitment/Recruitment/{tenantId}/ActiveLink',
      path: {
        tenantId: tenantId,
      },
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
    });
  }
}
