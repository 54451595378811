import { observer, useLocalObservable } from 'mobx-react-lite';
import { type FieldValues } from 'react-hook-form';
import { useEffect } from 'react';
import { createPhoneMask } from '@utils/string/createPhoneMask';
import { type FormInputProps } from '@types';

import { Button } from '../../Button';
import { Typography } from '../../Typography';
import { ActionsStack } from '../../ActionsStack';
import { FormInput } from '../FormInput';

import { createPhoneCodeInputStore } from './store';

type PhoneCodeSubmitProps<TFieldValues extends FieldValues> = {
  phone: string | number;
  onResend: () => void;
  id?: string;
} & FormInputProps<TFieldValues>;

/**
 * форм инпут предоставляющий базовый функционал для работы с подтверждением кода из смс,
 * запоминает оставшееся время в куках
 */
export const PhoneCodeInput = observer(
  <TFieldValues extends FieldValues>({
    phone,
    id = '',
    onResend,
    ...props
  }: PhoneCodeSubmitProps<TFieldValues>) => {
    const store = useLocalObservable(() =>
      createPhoneCodeInputStore({ id, phone, onResend }),
    );

    useEffect(() => {
      return () => store.destroy();
    }, [store]);

    return (
      <>
        <Typography marginBottom={4}>
          Введите код из СМС отправленного на номер{' '}
          {createPhoneMask(phone.toString())}
        </Typography>
        <FormInput fullWidth label="Код из СМС" type="text" {...props} />
        <ActionsStack gap={2}>
          <Button
            onClick={store.handleResendClick}
            variant="link"
            disabled={store.isRealTimerActive}
          >
            Отправить код повторно
          </Button>
          {store.isRealTimerActive && (
            <Typography>{store.secondsTimer.textTime}</Typography>
          )}
        </ActionsStack>
      </>
    );
  },
);
